@import 'assets/styles/_variable.scss';

.infoContainer {
  padding: 1rem 1.5rem;
  background-color: $background_lightgrey;

  .titleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    gap: 0.75rem;

    .titleContainerText {
      display: flex;
      align-items: center;
      gap: 1.5rem;
    }
  }

  .description {
    margin-bottom: 0.75rem;
  }

  .title {
    color: #193448;
    font-weight: 600;
    font-size: 17px;
    margin-bottom: 0.5rem;
    display: flex;
    gap: 0.75rem;
    align-items: center;
    flex-wrap: wrap;
  }

  .buttons {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 1.25rem;
    .buttonsEdit {
      //styleName: Content14/semibold;
      font-size: 14px;
      font-weight: 600;
      color: #0494fd;
      cursor: pointer;
    }
  }
}
.contentContainer {
  padding: 1.5rem;
  padding-top: 0.75rem;
}
.listContainer {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-bottom: 0.75rem;
  > ul {
    list-style: none;
  }
  .listElement {
    list-style: none;
  }
}

.trainingCard {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-radius: 0.5rem;
  gap: 0.5rem;
  flex-wrap: wrap;
  &:hover{
    box-shadow: 0px 8px 16px 0px #0000000a;
  }
}

.updateContainer {
  display: flex;
  justify-content: end;
  column-gap: 1rem;
  row-gap: 0.5rem;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1rem;

  h1 {
    font-size: 1.15rem;
    font-weight: 500;
    margin-right: auto;
  }

  .revert {
    color: $gray3;
    cursor: pointer;
    &:hover {
      color: $blue;
    }
    img {
      opacity: 0.4;
      width: 12px;
      height: 12px;
      margin-right: 0.25rem;
    }
  }

  & :global(.secondaryBtn) {
    background: none;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  .headerText {
    display: flex;
    justify-self: center;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 0.5rem;
    .headerTextTitle {
      font-size: 16px;
      font-weight: 600;
    }
    .headerTextAuthor {
      font-size: 8px;
      font-weight: 600;
      color: #6a8294;
      text-transform: uppercase;
    }
  }
  .delete {
    cursor: pointer;
  }
}
.description {
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  padding-left: 0.25rem;
  margin-bottom: 1rem;
}
.bubblesIcon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .bubblesContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1.5rem;
    text-wrap: nowrap;
    flex-wrap: wrap;
    > div {
      display: flex;
      align-items: center;
      gap: 0.25rem;
      background-color: #f9fafc;
      padding: 6.44px 12.88px 6.44px 9.66px;
      border-radius: 80px;
      font-family: Open Sans;
      font-size: 0.75rem;
      font-weight: 400;
    }
  }
}
