@import '/src/assets/styles/variable';

.card {
  background: $white;
  border-radius: 6px;
  padding: 1rem;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  .header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .img {
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      object-fit: cover;
    }
    .headerLabel {
      display: flex;
      align-items: center;
      gap: 0.25rem;
    }
  }

  .names {
    margin-bottom: 0.5rem;
    h3 {
      margin-top: 0.5rem;
      font-weight: 600;
      font-size: 0.875rem;
      color: $gray2;
    }
    p {
      color: $gray2;
      margin-bottom: 0.4rem;
    }
    .deadline {
      color: $gray3;
      font-size: 0.8rem;
    }
  }
}

.addBtn {
  border: 1px solid $blue;
  color: $blue;
  border-radius: 0.25rem;
  display: grid;
  place-items: center;
  align-content: center;
  height: 100%;
  box-sizing: border-box;
  cursor: pointer;
  row-gap: 10px;
  min-height: 170px;

  & span:first-child {
    font-size: 1rem;
    font-weight: 700;
  }

  &:hover {
    background: #0494fd1a;
  }
}
.addBtnFilled {
  margin-top: 1rem !important;
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
  @media screen and (min-width: 520px) {
    position: absolute !important;
    margin-top: 0 !important;
    top: 1rem;
    right: 3rem;
  }
}

.remindBtn {
  background-color: #ef544a !important;
  color: #ffffff !important;
  text-transform: none !important;
  &:hover {
    background-color: #e33d31 !important;
  }
}

.remindBtnDisabled {
  background-color: #fac8c4 !important;
  color: #ffffff !important;
  text-transform: none !important;
}

.carouselItem {
  & :global(.slick-track) {
    display: flex;
    gap: 1rem;
    align-items: center;
    margin: 0;
  }
  & :global(.slick-slide) {
    align-self: stretch;
    height: unset;
    display: grid;
  }
  & :global(.slick-next) {
    width: 32px;
    height: 32px;
    z-index: 2;
    right: -10px;
    position: absolute;
    filter: drop-shadow(1px 1px 1px #0000002d);
  }
  & :global(.slick-prev) {
    position: absolute;
    width: 32px;
    height: 32px;
    z-index: 2;
    transform: scaleX(-1) translateY(-12px);
    left: -5px;
    filter: drop-shadow(1px 1px 1px #0000002d);
  }
}

.carouselContainer {
  margin: 1rem 0;
  :global(.slick-next) {
    z-index: 0;
  }

  .sliderWrapper,
  :global(.slick-slider),
  :global(.slick-track),
  :global(.slick-list) {
    height: 100%;
  }

  .carouselHeader {
    display: flex;
    gap: 1rem;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;

    :global(.inputGroup) {
      margin-bottom: 0;
      max-width: 150px;
    }
    :global(.inputGroup .reactSelect > div) {
      min-height: 36px;
    }
    h1 {
      font-size: 1.1875rem;
      font-weight: 600;
      display: inline-block;
    }
    h2 {
      font-size: 1rem;
      font-weight: 500;
      display: inline-block;
      color: $gray5;
      margin-left: 1rem;
      font-style: italic;
    }
    .carouselSelector {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      span:first-child {
        color: $gray5;
      }
    }
    .filters {
      display: flex;
      gap: 1rem;
    }
  }
  .empty {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1rem;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-sizing: border-box;
    span {
      color: $gray3;
      font-size: 1rem;
    }
  }
}

.filter {
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  margin-top: 1.5rem;
  span:first-child {
    color: $gray5;
    margin-top: 15px;
    text-wrap: nowrap;
  }
}

.filterDashboard {
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  span:first-child {
    color: $gray5;
    margin-top: 15px;
    text-wrap: nowrap;
  }
  @media screen and (min-width: 520px) {
    position: absolute !important;
    margin-top: 0 !important;
    top: 1rem;
    right: 3rem;
  }
}
