.contentChat {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  background-color: #f4fbff;
  .scrollbar {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 0.5rem;
    gap: 0.75rem;
    .own {
      padding: 0.5rem;
      max-width: 85%;
      background-color: #d2eeff;
      border: #91d5ff 1px solid;
      border-radius: 5px;
      align-self: flex-end;
      text-align: right;
    }
    .chat {
      max-width: 85%;
      padding: 0.5rem;
      border-radius: 5px;
      align-self: flex-start;
      background-color: #b2e2ff;
      border: #5fc2ff 1px solid;
    }
    .loading {
      max-width: 85%;
      padding: 0.5rem;
      border-radius: 5px;
      align-self: flex-start;
      background-color: #cce7f8;
      border: #87c4eb 1px solid;
    }
  }
}
