.headerContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;

  .headerActions {
    display: flex;
    align-items: center;
    gap: 0.75rem;
  }
  .name {
    font-weight: 400;
    font-size: 1rem;
    color: #6a8294;
    font-family: 'Verdana';
  }

  .category {
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    border-radius: 99px;
    font-size: 0.625rem;
    font-weight: 600;
    border: 1px solid;
    padding: 0.125rem 0.75rem;
  }
}

.postTextArea {
  background: #f7f7f7;
  border-radius: 4px;
  border: 1px solid #ffffff00;
  .textArea {
    & :global(.ql-container) {
      margin: 0 !important;
      border: none !important;
      background: #f7f7f7e4;
      padding: 0;

      & :global(.ql-editor) {
        padding: 1rem;
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.7;
        img {
          transform: translateY(4px);
        }
      }
    }
  }
}

.postMediaArea {
  & :global(.filePreviewComponent) {
    max-width: unset;
    background-color: #eaf3f7;
  }
  & :global(.fileWrapper) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 1.5rem;
    justify-content: center;
    width: 100%;
    border: none !important;
    & > div:first-of-type {
      height: fit-content !important;
      min-height: fit-content !important;
    }
    video {
      width: 100%;
    }
    & :global(.imagePreview) {
      @media (min-width: 720px) {
        min-height: 420px;
      }
      width: 100% !important;
      object-fit: contain !important;
    }
  }
  & :global(.contentWrapper) {
    width: fit-content;
    padding: 0 1rem !important;
    h6 {
      width: fit-content;
    }
  }
}
