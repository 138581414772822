$font_OpenSans: 'Open Sans', sans-serif;
$imageBasePath: 'https://assets.staging.salestable.ai/static/assets/img/';

$lightFont: 300;
$regularFont: 400;
$mediumFont: 500;
$semiBoldFont: 600;
$boldFont: 700;

$black: #000000;
$white: #ffffff;

$blue: #0494fd;
$blue2: #0081de;
$blue3: #cfebff;
$blue4: #ecf7ff;
$blue5: #dcf0ff;
$blue6: #f2faff;

$orange: #eb9b42;
$yellow: #f6cb5c;
$green: #2dc38d;
$purple: #9086ff;
$red: #e83a3a;
$lightRed: #fff7f7;
$primaryRed: #f79e9e;

$background_grey: #f3f5f8;
$background_lightgrey: #f9fafc;
$background_orange: #fff0de;
$background_green: #def9ef;
$background_blue: #e0f2ff;
$background_lightblue: #f2f7fa;
$background_green: #eaf9f4;
$background_yellow: #fff6dd;

// $gray1: #1B2E3C;
// $gray2: #465560;
// $gray3: #78838B;
// $gray4: #919BA1;
// $gray5: #A5B4BC;
// $gray6: #C2CDD3;
// $gray7: #E0E9EE;

$gray1: #193448;
$gray2: #415766;
$gray3: #6a8294;
$gray4: #87a0b4;
$gray5: #a0b7c6;
$gray6: #bfd4df;
$gray7: #e7f0f5;
$gray8: #5d8399;

$color_info_text: #0494fd;
$color_success_text: #2dc38d;
$color_error_text: #e83a3a;
$color_warning_text: #eb9b42;
$color_disabled_text: #485a67;

$color_info_bg: #ecf7ff;
$color_success_bg: #e7fff6;
$color_error_bg: #ffe4e4;
$color_warning_bg: #fff1ce;
$color_disabled_bg: #6a8294;

$color_7168E8: #7168e8;
$color_9B95EF: #9b95ef;
$color_F9FCFF: #f9fcff;
$color_DBEAF4: #dbeaf4;
$color_AED888: #aed888;
$color_FFB235: #ffb235;
$color_13AC75: #13ac75;
$color_E1AE2A: #e1ae2a;
$color_F25B5B: #f25b5b;
$color_FFCE97: #ffce97;
$color_FFECBC: #ffecbc;
$color_F8F9FB: #f8f9fb;
$color_EEF1F5: #eef1f5;

$font32: 32px;
$font24: 24px;
$font20: 20px;
$font18: 18px;
$font16: 16px;
$font14: 14px;
$font12: 12px;
$font10: 10px;
$font8: 8px;

$lineHeight48: 48px;
$lineHeight36: 36px;
$lineHeight30: 30px;
$lineHeight27: 27px;
$lineHeight24: 24px;
$lineHeight21: 21px;
$lineHeight20: 20px;
$lineHeight18: 18px;
$lineHeight16: 16px;
$lineHeight14: 14px;

@mixin fontStyle(
  $fontFamily: null,
  $size: null,
  $weight: null,
  $color: null,
  $lineHeight: null,
  $margin: null,
  $padding: null
) {
  @if $fontFamily != null {
    font-family: $fontFamily;
  }
  @if $size != null {
    font-size: $size;
  }
  @if $weight != null {
    font-weight: $weight;
  }
  @if $color != null {
    color: $color;
  }
  @if $lineHeight != null {
    line-height: $lineHeight;
  }
  @if $margin != null {
    margin: $margin;
  }
  @if $padding != null {
    padding: $padding;
  }
}

@mixin transitionForAll($time: 0.3s, $transProp: ease) {
  -webkit-transition: all $time $transProp;
  -moz-transition: all $time $transProp;
  -o-transition: all $time $transProp;
  transition: all $time $transProp;
  transition: all $time $transProp;
}

$xs: 600px;
$sm: 720px;
$md: 960px;
$lg: 1280px;
$xl: 1920px;

@mixin xsmall {
  @media (min-width: 0px) and (max-width: 599px) {
    @content;
  }
}

@mixin small {
  @media (min-width: 600px) and (max-width: 959px) {
    @content;
  }
}

@mixin medium {
  @media (min-width: 960px) and (max-width: 1279px) {
    @content;
  }
}

@mixin large {
  @media (min-width: 1280px) {
    @content;
  }

  @media (min-width: 1350px) {
    @content;
  }

  @media (min-width: 1700px) {
    @content;
  }
  @media (min-width: 2000px) {
    @content;
  }

  @media (min-width: 2800px) {
    @content;
  }
}
