@import 'variable';
body {
  .socialLoginWrapper {
    text-align: center;
    margin: 0.75rem 0;
    ul {
      display: flex;
      text-align: center;
      gap: 1rem;
      padding-bottom: 0;
      li {
        margin: 0;
        padding: 8px 0;
        flex: 1;
        a {
          border: 1px solid $gray6;
          border-radius: 4px;
          padding: 12px 16px;
          margin: 0 auto;
          color: $gray2;
          width: 100%;
          display: block;
          text-align: center;
          img {
            vertical-align: middle;
            margin-right: 8px;
            position: relative;
            top: -2px;
          }
        }
      }
    }
  }
  .optionHeading {
    position: relative;
    z-index: 1;
    overflow: hidden;
    text-align: center;
    margin-bottom: 0.75rem;
    &:before,
    &:after {
      position: absolute;
      top: 51%;
      overflow: hidden;
      width: 50%;
      height: 1px;
      content: '\a0';
      background-color: $gray7;
    }
    &:before {
      margin-left: -50%;
      text-align: right;
    }
    span {
      padding: 0 8px;
      color: $gray4;
      font-weight: 100 !important;
    }
  }
  & :global(.passwordGroup) {
    & :global(.MuiTooltip-popper) {
      right: 25% !important;
      position: absolute;
    }
    & :global(.MuiTooltip-tooltip) {
      padding: 0;
      margin: 0;
    }
    .tooltipWrapper {
      background: $gray2;
      border-radius: 4px;
      padding: 16px 20px 16px 16px;
      position: relative;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.25);
      p {
        font-weight: $semiBoldFont;
        color: $blue6;
        margin-bottom: 4px !important;
        font-size: $font14;
      }
      ul {
        text-align: left;
        li {
          padding: 4px 0;
          img {
            width: 12px;
            vertical-align: middle;
            margin-right: 5px;
          }
          span {
            color: $blue6;
            font-size: $font14;
            b {
              font-weight: $semiBoldFont;
              color: $blue6;
              font-size: $font14;
              padding-right: 4px;
              position: relative;
              top: 1px;
            }
          }
          &:last-child {
            padding-bottom: 0;
          }
        }
      }
      &:after,
      &:before {
        bottom: auto;
        top: 48%;
        left: -8px;
        border: solid transparent;
        content: ' ';
        height: 0;
        width: 0;
        position: absolute;
        // pointer-events: none;
        transform: rotate(270deg);
      }
      &:after {
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: $gray2;
        border-width: 10px;
        margin-left: -10px;
      }
      &:before {
        border-color: rgba(196, 196, 196, 0);
        border-bottom-color: $gray2;
        border-width: 10px;
        margin-left: -10px;
      }
    }
  }
  .loaderBlk {
    min-height: 100vh;
    width: 100%;
    background-color: white;
    opacity: 0.6;
    position: absolute;
    z-index: 99;
    img,
    & :global(.MuiCircularProgress-root) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    svg {
      color: $blue;
    }
  }
  // new styles
  .pagination {
    width: 100%;
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    z-index: 3;
    span {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #fff;
      transition: width 0.8s;
      cursor: pointer;
      &.active {
        width: 48px;
        border-radius: 0.25rem;
      }
    }
  }
  .faded {
    animation: fade 1.25s ease;
    @keyframes fade {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
  // Sections styles
  .signInPage {
    display: flex;
    flex-wrap: wrap-reverse;

    @media (min-width: 1280px) {
      min-height: 100vh;
      flex-direction: row;
      flex-wrap: nowrap;
    }

    & * {
      box-sizing: border-box;
    }

    // Carousel
    .signInCarousel {
      padding: 0.8rem;
      width: 100%;
      min-height: 100vh;
      min-height: 100dvh;

      @media (min-width: 1280px) {
        max-width: 47vw;
      }

      .carouselContainer {
        height: 100%;
        padding: 1.5rem;
        background-color: #0277ca;
        border-radius: 30px;
        position: relative;
        overflow: hidden;
        .backImage {
          position: absolute;
          bottom: -3%;
          right: -9%;
          width: 290px;
          filter: grayscale(0.3);
          opacity: 0.7;
        }
        .backgroundTestimony {
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          filter: grayscale(1);
          opacity: 0.15;
          width: 100%;
          object-fit: cover;
        }
        .page {
          color: #fff;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          height: 100%;
          gap: 2rem;
          h1 {
            margin-top: 1rem;
            font-weight: 400;
            text-align: center;
            line-height: 2.3rem;
            font-size: 1rem;
            @media (min-width: 720px) {
              font-size: 1.35rem;
              padding: 0 2rem;
            }
            @media (min-width: 1920px) {
              font-size: 1.6rem;
              padding: 0 3rem;
            }
          }
        }
        .grid {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-column-gap: 32px;
          grid-row-gap: 32px;
          z-index: 2;
          place-items: center;

          @media (min-width: 720px) {
            grid-template-columns: repeat(3, 1fr);
          }

          div {
            width: 100%;
            background: #fff;
            border-radius: 22px;
            aspect-ratio: 2 / 1;
            display: grid;
            color: #000;
            max-height: 120px;
            place-items: center;
            max-width: 240px;

            img {
              width: 80%;
              padding: 0.25rem 0;
              aspect-ratio: 2 / 1;
              object-fit: contain;
              max-height: 100%;
            }
          }
        }
        .testimonials {
          flex:0.8;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          text-align: center;
          margin: 0 auto;
          gap: 2.5rem;

          .testimony {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            max-width: 520px;
            position: relative;

            .quote {
              font-size: 16px;
              @media (min-width: 1920px) {
                font-size: 18px;
              }
              font-weight: 600;
              line-height: 1.8rem;
            }
            .quoteSymbol {
              position: absolute;
              top: -2.2rem;
              left: -1rem;
              width: 2.75rem;
              @media (min-width: 1920px) {
                width: 3.4rem;
              }
            }
            .stars {
              color: #ffd630;
              font-size: 1.5rem;
              display: flex;
              gap: 0.5rem;
              margin: 0.5rem auto;
            }
            .name {
              font-size: 1.3rem;
            }
          }
        }
        .previewImage {
          z-index: 2;
          height: 55vh;
          min-height: 300px;
          object-fit: contain;
          padding: 1rem 0.5rem;
          @media (min-width: 1280px) {
            padding: 0;
          }
        }
      }
    }

    // Form section
    .signInSection {
      width: 100%;
      padding: 1rem;
      display: flex;
      justify-content: center;
      margin: 2rem 0;

      @media (min-width: 1280px) {
        margin: 0;
      }

      .signInContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        max-width: 524px;

        .logo {
          height: 46px;
          margin: 0 auto 1.75rem auto;
        }
        .phraseText {
          font-weight: 600;
          text-align: start;
          font-size: 1.1rem;
          line-height: 1.6rem;
          @media (min-width: 1920px) {
            font-size: 1.3rem;
          }
        }
        .signInText {
          font-weight: 600;
          font-size: 1.1rem;
          text-align: start;
          margin-top: 1.6rem;
          margin-bottom: 1rem;
        }
        .signupText {
          text-align: start;
        }
        .copyRight {
          margin-top: 1rem;
        }
      }
      .agreeBlk {
        position: relative;
        margin-bottom: 0;
        & :global(.MuiButtonBase-root) {
          position: absolute;
          left: 4px;
          top: -2px;
        }
        & :global(.MuiTypography-root) {
          display: block;
          margin-left: 30px;
        }
        a {
          font-size: $font12;
        }
      }
    }
  }
}
