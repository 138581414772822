@import 'assets/styles/_variable.scss';

.title {
  color: $gray1;
  font-size: 1.25rem;
  font-weight: 600;
}
.text {
  color: $gray3;
  font-size: 0.875rem;
  font-weight: 400;
  margin: 0.75rem 0;
}
.infoContainer {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  img {
    width: 16px;
    height: 16px;
    transform: rotate(180deg);
  }
  p {
    font-size: 0.625rem;
    color: #1d416b;
    transition: opacity 0.25s;
    opacity: 0;
  }
}
// Card styles
.success,
.error,
.loading {
  border: 2px solid $blue;
  border-radius: 0.75rem;
  padding: 1rem;
  margin: 1.5rem 0;
  box-shadow: 0px 16px 18px 0px #0000000a;
  background: #f3f5f880;
}

.loading {
  box-shadow: none;
}

.error {
  border: none;
  box-shadow: none;
  .text {
    margin-bottom: 0;
  }
}

.loadingContainer {
  display: flex;
  flex-wrap: wrap;
  column-gap: 6px;
  row-gap: 0.75rem;
  margin: 1rem 0;
}

// Card styles
.success1,
.loading1 {
  border: 1px solid $blue;
  border-radius: 0.75rem;
  padding: 1rem;
  box-shadow: 0px 16px 18px 0px #0000000a;
  background: #ecf7ff;
}

.textFeedback {
  color: $gray3;
  font-size: 0.875rem;
  font-weight: 400;
  color: $blue;
}

.loadingFeedback {
  display: flex;
  gap: 0.4rem;
  align-items: center;
  flex-wrap: nowrap;
}

.loadingText {
  font-size: 0.57rem;
  color: #272d37;
}

.titleError {
  color: $gray1;
  font-weight: 400;
}

.textError {
  color: #607d8b;
}
