.contentInut {
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .commentInput {
    width: 80%;
    height: 2.4rem;
    outline: none;
    border-radius: 0.25rem;
    border: 1px solid #e7f0f5;
    background: #fff;
    padding: 0 1rem;
  }
  .iconSend{
    width: 20px;
    height: 20px;
    margin-right: 0.5rem;
    &:hover{
      cursor: pointer;
      width: 22px;
      height: 22px;
    }
  }
}
