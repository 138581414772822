.container {
  display: flex;
  gap: 1rem;
  margin-bottom: 1.5rem;
  margin-top: 0.75rem;
  width: 100%;

  > div {
    border-radius: 12px;
    background-color: #ffffff;
    min-height: 10rem;
    padding: 0.75rem 1rem;
  }

  @media (max-width: 640px) {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    .main {
      flex-wrap: wrap;
      justify-content: center;
    }
    .mainText {
      width: 90% !important;
    }
    .bubblesContainer {
      display: flex !important;
      flex-wrap: wrap !important;
    }
  }
  @media (min-width: 640px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    .main {
      grid-area: 1 / 1 / 2 / 4;
    }
    .deadline {
      grid-area: 2 / 1 / 3 / 2;
    }
    .completion {
      grid-area: 2 / 2 / 3 / 3;
    }
  }
  @media (min-width: 1020px) {
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(1, 1fr);
    .main {
      grid-area: 1 / 1 / 2 / 4;
    }
    .deadline {
      grid-area: 1 / 4 / 2 / 5;
    }
    .completion {
      grid-area: 1 / 5 / 2 / 6;
    }
  }

  .main {
    display: flex;
    cursor: pointer;
    &:hover {
      box-shadow: 0px 16px 18px 0px #0000000a;
    }

    .mainGraph {
      width: 30%;
    }
    .mainText {
      width: 70%;
      .headerText {
        display: flex;
        justify-self: center;
        align-items: center;
        flex-wrap: wrap;
        column-gap: 0.5rem;
        .headerTextTitle {
          font-size: 16px;
          font-weight: 600;
        }
        .headerTextAuthor {
          font-size: 8px;
          font-weight: 600;
          color: #6a8294;
        }
      }
      .description {
        font-size: 10px;
        font-weight: 400;
        line-height: 16px;
        margin-bottom: 0.5rem;
        min-height: 5rem;
        margin-top: 0.5rem;
      }
      .bubblesContainer {
        display: grid;
        grid-template-columns: repeat(2, auto);
        grid-row: auto auto;
        grid-column-gap: 10px;
        grid-row-gap: 10px;
        margin-bottom: 1rem;
        text-wrap: nowrap;
        > div {
          display: flex;
          align-items: center;
          gap: 0.25rem;
          background-color: #f9fafc;
          padding: 6.44px 12.88px 6.44px 9.66px;
          border-radius: 80px;
          font-family: Open Sans;
          font-size: 0.75rem;
          font-weight: 400;
        }
      }
    }
  }
  .deadline {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .number {
      font-size: 2rem;
      font-weight: 600;
      margin: 0 0 1rem 1rem;
    }
    .numberRed {
      font-size: 2rem;
      font-weight: 600;
      margin: 0 0 1rem 1rem;
      color: #E83A3A;
    }
    .numberText {
      font-size: 1rem;
      font-weight: 600;
      margin: 0 0 1rem 1rem;
    }
    .icon {
      margin: 1rem 0 0 1rem;
      width: 30px;
    }
  }
  .completion {
    background-color: #0494fd;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #fff;
    .number {
      font-size: 2rem;
      font-weight: 600;
      margin: 0 0 1rem 1rem;
    }
    .numberText {
      font-size: 1rem;
      font-weight: 600;
      margin: 0 0 1rem 1rem;
    }
    .icon {
      margin: 1rem 0 0 1rem;
      width: 30px;
    }
  }
}

.totalContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%) scale(0.9);
  text-align: center;
}
