@import 'assets/styles/_variable.scss';

.newCommentContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: start;
  gap: 0.5rem;
  border-radius: 0.25rem;

  img {
    margin-top: 0.25rem;
    margin-left: 0.25rem;
  }

  .commentInput {
    min-width: 200px;
    flex: 1;
    height: 2.4rem;
    outline: none;
    border-radius: 0.25rem;
    border: 1px solid #e7f0f5;
    background: #fff;
    padding: 0 1rem;
  }
}
