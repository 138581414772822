@import 'variable';
body {
  .usersListingShimmer {
    margin-bottom: 24px;
    .usersListingShimmerRow {
      position: relative;
      .imgPlaceholder {
        position: absolute;
        top: 2px;
      }
      .imgText {
        margin-left: 45px;
      }
    }
  }
  .usersPage {
    padding: 24px;
    .usersSection {
      .titleRow {
        margin-bottom: 24px;
        .usersFeatureIcon {
          float: left;
          margin-top: 2px;
        }
        .usersSectionTitle {
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 0px 15px;
        }

        .usersRoleFilter {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          .inviteBtn {
            margin-right: 18px;
            line-height: $lineHeight14;
            position: relative;
            padding: 10px 24px;
            padding-left: 38px;
            & img {
              position: absolute;
              left: 15px;
              top: 7px;
              width: 20px;
              height: 20px;
              object-fit: cover;
            }
          }
          .filterSelectWrapper {
            margin-right: 6px;
            width: 30%;
            svg {
              color: $gray4;
            }
            :global(.inputGroup) {
              margin-bottom: 0;
            }
            :global(.inputGroup .reactSelect > div) {
              min-height: 36px;
            }
          }
        }
      }
      .usersTableTitleWrapper {
        padding: 0px 20px;
        margin: 15px 0px;
        padding-bottom: 12px;
        .tableRow {
          .usersTableTitle {
            color: $gray4;
            font-size: $font12;
            line-height: $lineHeight20;
          }
          .usersTableTitleSort {
            position: relative;
            display: inline-block;
          }
          .usersTableSortIcon {
            vertical-align: middle;
            padding: 0px 15px;
          }
        }
      }
      .usersTableContentWrapper {
        padding: 20px 24px;
        background: $white;
        border-radius: 4px;
        margin-bottom: 24px;
        :global(.noResultFound) {
          min-height: unset;
        }
        .usersRow {
          margin-bottom: 16px;
          &.disabledUsers {
            .usersCustomParagraph {
              color: $gray5; //disabled colors
            }
          }
          &:last-child {
            margin-bottom: 0px;
          }
          .avatarFallback {
            width: 34px;
            height: 34px;
            line-height: 34px;
            float: left;
            margin-right: 10px;
          }
          .usersImg {
            width: 34px;
            height: 34px;
            border-radius: 50%;
            float: left;
            object-fit: cover;
            margin-right: 10px;
          }
          .nameParagraph {
            color: $gray1;
            padding: 0px 10px;
            display: flex;
            margin-top: 6px;
          }
          .usersCustomParagraph {
            margin-top: 6px;
            color: $gray1;
            .userSettingsBtn {
              opacity: 0.6;
              height: 12px;
              cursor: pointer;
              &:hover {
                opacity: 1;
              }
            }
          }
          .usersMoreActionWrap {
            position: relative;
            .userStatusWrap {
              width: 75%;
            }
            .usersStatus {
              font-size: $font12;
              border-radius: 4px;
              padding: 2px 16px;
              line-height: $lineHeight20;
              display: inline-block;
              margin-top: 5px;
              font-weight: $semiBoldFont;
              &.Active {
                background: #e3f4ef;
                color: $green;
              }
              &.Deactivated {
                background: #eff3f5;
                color: $gray5;
              }
              &.Invited {
                background: #f8f5ea;
                color: $orange;
              }
            }
            .usersMoreAction {
              position: absolute;
              right: 0px;
              top: 10px;
              &:hover {
                cursor: pointer;
              }
            }
          }
        }
      }
      .usersTablePaginationWrapper {
        .customPagination {
        }
      }
    }
  }
}

//mobile portrait view //
@media (min-width: 0px) and (max-width: 599px) {
  body {
  }
}

@media (min-width: 600px) and (max-width: 959px) {
  body {
  }
}

@media (min-width: 960px) and (max-width: 1279px) {
  body {
  }
}

@media (min-width: 1280px) {
  body {
  }
}
