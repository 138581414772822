.dialog {
  :global(.MuiDialog-paper) {
    max-width: 1090px;
    width: 60rem;
    min-height: 70vh;
  }
  :global(.noResultFound){
    min-height: 1rem;
  }
  .customTitle {
    h3 {
      img,
      span {
        vertical-align: middle;
      }
      img {
        margin-left: 8px;
        margin-top: 2px;
      }
    }
  }
  .dialogBody {
    .leftContent,
    .rightContent {
      padding: 24px;
      width: 100%;
    }
    .rightContent {
      border-left: 1px solid #f3f5f8;
      h2 {
        margin-bottom: 16px;
      }
      .userPreference {
        color: #f3f5f8;
        margin-bottom: 8px;
      }
      .radioBtnList {
        // padding: 0;
        li {
          padding: 0 8px;
          &:first-child {
            padding-left: 0;
          }
        }
      }
    }
    .leftContent {
      padding-top: 16px;
      :global(.dzu-dropzone) {
        margin: 20px 0 0;
      }
      :global(.dzu-inputLabel) {
        position: relative;
      }
    }
  }
  :globla(.tertiaryBtn),
  :global(.primaryBtn) {
    padding: 10px 16px;
  }
  :global(.primaryBtn) {
    margin-left: 20px;
  }
}
.trainingModuleCard {
  &:hover{
    box-shadow: 0px 8px 16px 0px #0000000a;
  }
  border: 1px solid #EEEEEE;
  background-color: #FFF;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-radius: 0.5rem;
  flex-wrap: wrap;
  cursor: pointer;
  margin-bottom: 1rem;
  margin-left: 0.5rem;
  .circle {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background:#EEEEEE;
    color: #EEEEEE;
    display: grid;
    place-items: center;
  }

  .selected {
    color: white;
    background: #0494FD;
  }
}


.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  .headerText {
    display: flex;
    justify-self: center;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 0.5rem;
    .headerTextTitle {
      font-size: 16px;
      font-weight: 600;
    }
    .headerTextAuthor {
      font-size: 8px;
      font-weight: 600;
      color: #6a8294;
    }
  }
}
.description {
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  padding-left: 0.25rem;
  margin-bottom: 1rem;
}

.bubblesContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1.5rem;
  text-wrap: nowrap;
  flex-wrap: wrap;
  > div {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    background-color: #f9fafc;
    padding: 6.44px 12.88px 6.44px 9.66px;
    border-radius: 80px;
    font-family: Open Sans;
    font-size: 0.75rem;
    font-weight: 400;
  }
}

.titleModules{
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 0.5rem 0 1rem 0.5rem;
}

