.container {
  border-radius: 0.75rem !important;
}
.header {
  background: #eceff1;
  padding: 0.875rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  flex-wrap: nowrap;

  .title {
    font-size: 18px;
    font-weight: 600;
    color: #272d37;
    line-height: 1.5rem;
    margin-top: 0;
    margin-bottom: 0.25rem;
  }
  .subtitle {
    font-size: 14px;
    font-weight: 400;
    color: #546e7a;
  }

  button {
    height: 1.5rem;
    width: 1.5rem;
    padding: 0.25rem;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
  }
}

.content {
  display: grid;
  grid-template-columns: repeat(1, 1fr);

  .left,
  .right {
    box-sizing: border-box;
    padding: 1rem;
  }

  .left {
    border-right: none;
    border-bottom: 1px solid #eceff1;
  }
  .right {
    border-bottom: none;
  }

  @media (min-width: 840px) {
    grid-template-columns: repeat(2, 1fr);
    .left {
      border-right: 1px solid #eceff1;
      padding: 1.5rem;
    }
    .right {
      border-bottom: 1px solid #eceff1;
      padding: 1.5rem 0.75rem 0.5rem 0.75rem;
    }
  }
}

.footer {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 0.75rem;
  padding: 1.5rem;

  .hint {
    color: #607d8b;
  }
}
