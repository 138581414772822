.closeIcon {
  position: absolute;
  right: 24px;
  top: 12px;
  background: white;
  border-radius: 50%;
  opacity: 0.6;
  cursor: pointer;
  font-size: 18px;
  padding: 5px;
  &:hover {
    opacity: 0.9;
  }
  &:active {
    opacity: 1;
  }
}
