.background {
  background: #ffffff;
  border-radius: 8px;
  padding: 30px;
  display: flex;
  flex-direction: column;
}

.roleplayHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.25rem;
  flex-wrap: wrap;
  gap: 2rem;
}

.description_header {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;

  @media screen and (min-width: 1280px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(1, 1fr);
  }
}

.cardContainer {
  padding: 1rem;
  border: solid 1px #e8eaed;
  border-radius: 12px;
  margin-bottom: 0.75rem;
  .cardTitle {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1.5rem;
    margin-bottom: 0.75rem;
  }
  .cardIndex {
    width: 1.5rem;
    height: 1.5rem;
    background-color: #2dc38d;
    border-radius: 50px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cardIndexUncompleted {
    background-color: #8e9995;
  }

  .cardIcon {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.submitButton {
  display: flex;
  justify-content: flex-end;
}
