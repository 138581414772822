.postContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;
  padding: 12px;
  gap: 8px;
  background: #ffffff;
  border: 0.5px solid rgba(135, 147, 154, 0.48);
  box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.15), 0px 5px 10px rgba(0, 0, 0, 0.05);
  border-radius: 8px;

  .textContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;
    position: relative;

    .textInput {
      resize: none;
    }

    .postBottom {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 1rem;
    }
  }
}

.imageWrapper {
  position: absolute;
  left: 24px;
  top: 24px;
  background: transparent;
  border-radius: 4px;
}
