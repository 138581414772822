.stream-filter-container {
  .section-selector {
    display: flex;
    flex-wrap: wrap;
    column-gap: 1rem;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    align-items: center;
    position: relative;
    row-gap: 0.5rem;

    .stream-filters {
      display: flex;
      align-items: center;
      justify-content: end;
      flex-wrap: wrap;
      column-gap: 1rem;
      margin-left: auto;
      padding-left: 1rem;
    }

    span {
      cursor: pointer;
      background: none;
      border-radius: 1rem;
      padding: 0.35rem 1.1rem;
      font-weight: 600;
      color: #0494fd;

      &.selected {
        background: #0494fd;
        color: #fff;
      }
    }
  }
}
