.icon {
  cursor: pointer;
  width: 19px;
  height: 18px;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
}

.iconActive {
  cursor: pointer;
  width: 19px;
  height: 18px;
}
