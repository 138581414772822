.recording-options-container {
  background: none;
  border: none;
  cursor: pointer;
  font: inherit;

  option {
    font: inherit;
  }
}
