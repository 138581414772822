@import 'assets/styles/_variable.scss';

.postTextAreaContainer {
  position: relative;
  flex: 1;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  margin-bottom: 0.5rem;
  &.isPost {
    min-height: 5rem;
  }
}

.postTextArea {
  .delete {
    margin-left: 0.2rem;
    margin-right: 0.4rem;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
  }

  img {
    border-radius: 50%;
    width: 16px;
    height: 16px;
    object-fit: cover;
    position: absolute;
    transform: translateY(1.4px);
    background-color: #ffffff;
  }

  & :global(.ql-editor) {
    padding-bottom: 0;
  }

  &.postContent {
    & :global(.ql-editor) {
      padding-bottom: 1rem !important;
      img {
        transform: translateY(4px) translateX(3.5px) !important;
      }
      & :global(.thumbnail_v1) {
        img {
          transform: translate(0) !important;
        }
      }
    }
  }

  & :global(.ql-toolbar) {
    display: none !important;
    height: 0 !important;
    margin: 0 !important;
  }

  & :global(.ql-container) {
    &:global(.ql-snow) {
      border: none !important;
    }
  }

  &.commentArea {
    & :global(.ql-container) {
      min-height: 1rem;
      font-family: Open Sans;
      font-size: 0.8rem;
      font-weight: 300;
      color: #474d52;
      margin-top: 0.4rem;
      margin-bottom: 0;
      border: none !important;

      img {
        width: 13px;
        height: 13px;
        transform: translateY(2px) translateX(3.5px) !important;
      }

      & :global(.ql-editor) {
        padding: 0;
      }
    }
  }
  &.commentInput {
    & :global(.ql-container) {
      min-height: 1rem;
      & :global(.ql-editor) {
        padding: 0.5rem 1rem 0.75rem 1rem;
        min-width: 200px;
        img {
          transform: translateY(-1px) translateX(-5px) !important;
        }
      }
    }
  }
}

.userTagList {
  background: #f4f4f4;
  z-index: 999;
  display: flex;
  flex-direction: row;
  position: absolute;
  width: fit-content !important;
  max-width: 340px;
  border-radius: 0.25rem;
  border: 1px solid #dfdfdf;
  white-space: nowrap;
  word-break: normal;
  max-height: 420px;
  overflow-y: auto;
  overflow-x: hidden;

  &.commentList {
    max-height: 220px;
  }

  .userItem {
    padding: 0.5rem 1rem;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    width: 100%;

    &.active {
      color: $blue;
      background: #fff;
    }

    .image {
      width: 16px;
      height: 16px;
      object-fit: cover;
      border-radius: 50%;
    }
  }
}
