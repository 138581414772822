.deleteButton {
    border-radius: 6px;
    font-size: 14px;
    font-weight: 600;
    color: #A50A0A;
    font-family: 'Open Sans', sans-serif;
    &:hover{
      color: #740707;
    }
  }

  .deleteTitle {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 1rem;
    .deleteCloseIcon {
      &:hover {
        cursor: pointer;
      }
    }
    .titleText {
      font-size: 18px;
      font-weight: 600;
      line-height: 25px;
    }
  }
  
  .deleteBody {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    height: 10rem;
    .bodyText {
      padding-top: 1rem;
      font-size: 14px;
      font-weight: 400;
    }
    .bodyButtons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8rem;
      .delete {
        color: #be0a0a;
        font-size: 14px;
        font-weight: 600;
        border: 1px solid #be0a0a;
        min-width: 10rem;
        height: 2.2rem;
        padding: 5px 1rem;
        border-radius: 6px;
        &:hover {
          color: #da5d5d;
          border: 1px solid #da5d5d;
        }
      }
    }
  }
  