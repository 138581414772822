@import 'assets/styles/_variable.scss';

.providerCard {
  background: $white;
  border-radius: 4px;
  padding: 8px;
  width: 100%;

  &.integrated {
    &:hover {
      -webkit-box-shadow: -14px -11px 16px -15px rgba(0, 0, 0, 0.33);
      -moz-box-shadow: -14px -11px 16px -15px rgba(0, 0, 0, 0.33);
      box-shadow: -14px -11px 16px -15px rgba(0, 0, 0, 0.33);
      cursor: pointer;
    }
  }

  .imgBlk {
    min-height: 145px;
    img {
      width: 100%;
      margin-bottom: 16px;
    }
  }
  .contentBlk {
    padding: 0 22px 24px;
    position: relative;
    h2 {
      margin-bottom: 6px;
      padding-right: 96px;
    }
    p {
      margin-bottom: 24px;
    }
  }

  .connected {
    color: $blue;
    font-size: 1rem;
    font-weight: 500;
  }
}
