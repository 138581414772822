@import 'assets/styles/_variable.scss';
.container {
  background: $white;
  border-radius: 4px;
  margin: 1rem 0;
}

.header {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  row-gap: 0.5rem;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 1rem 1rem 0 1rem;

  h1 {
    font-size: 16px;
    font-weight: 600;
    color: $gray1;
    margin-left: 0.5rem;
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .rightContainer {
    display: flex;
    align-items: center;
    column-gap: 0.75rem;
    flex-wrap: nowrap;

    & > :global(.inputGroup) {
      margin-bottom: 0;
      * {
        min-height: unset;
      }
    }
  }

  .button {
    background: none;
    border-radius: 50%;
    outline: none;
    border: none;
    display: grid;
    place-items: center;
    opacity: 0.7;
    &:hover {
      opacity: 1;
    }
  }
}
