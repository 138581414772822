.reactionContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px 0;
  .icon {
    cursor: pointer;
    color: rgba(0, 0, 0, 0.5);
    width: 18px;
    height: 18px;
    margin: 0px 6px 0px 20px;
    &:hover {
      -webkit-transform: scale(1.2);
      -moz-transform: scale(1.2);
      -ms-transform: scale(1.2);
      -o-transform: scale(1.2);
      transform: scale(1.2);
    }
  }

  .iconOwn {
    color: rgba(0, 0, 0, 0.5);
    width: 18px;
    height: 18px;
    margin: 0px 6px 0px 20px;
  }
  .number {
    font-size: small;
  }
}
