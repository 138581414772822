.dateRangeFilterWrapper {
  width: fit-content;
  display: inline-block;
  margin: 0.5rem;
  .dateValues {
    padding: 0 !important;
    border-radius: 20px !important;
    border: 1px solid #0494fd !important;
    p {
      text-align: center !important;
      padding: 0;
      span {
        color: #0494fd !important;
      }
      img {
        display: none !important;
      }
    }
  }
}
