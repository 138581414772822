@import 'variable';
body {
  .recruitmentOuterPageWrapper {
    .recruitmentOptionsPage {
      .setupTrainingIntroSection {
        background: $background_blue;
        padding: 16px 24px;
        position: relative;
        .titleWrapper {
          position: relative;
          .imgBlk {
            position: absolute;
            left: 0;
            top: 0;
            height: 50px;
            width: 50px;
            border-radius: 50%;
            background: $white;
            img {
              position: relative;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              max-height: 40px;
              max-width: 40px;
            }
          }
          .contentBlk {
            margin-left: 74px;
            h1 {
              margin-bottom: 4px;
            }
            p {
              color: $gray3;
            }
          }
        }
        .addContentBtn {
          margin-right: 48px;
        }
        .patternBg {
          position: absolute;
          right: 0;
          top: 0;
          height: 100%;
        }
      }
      .recruitmentOptionsSection {
        padding: 24px;
        .titleRow {
          margin-bottom: 24px;
        }
        .contentRow {
          .cardWrapper {
            background: $white;
            border-radius: 4px;
            padding: 8px;
            width: 100%;
            // min-height: 310px;
            .imgBlk {
              min-height: 145px;
              img {
                width: 100%;
                margin-bottom: 16px;
                &.addManuallyImg {
                  margin-bottom: 0;
                  // width: 100px;
                  // margin: 24px auto 38px;
                  // display: block;
                }
              }
            }
            .contentBlk {
              padding: 0 22px 24px;
              position: relative;
              h2 {
                margin-bottom: 6px;
                padding-right: 96px;
                .active {
                  color: $color_13AC75;
                  border-radius: 2px;
                  padding: 2px 8px;
                  display: inline-block;
                  background: $background_green;
                  border-radius: 2px;
                  margin-left: 10px;
                }
              }
              p {
                margin-bottom: 24px;
              }
              .setupIntegrationBtn {
                padding: 10px 36px;
              }
              .switchBlk {
                margin-bottom: 0;
                float: right;
              }
              .activeList {
                display: flex;
                justify-content: space-between;
                .setupIntegrationBtn {
                  padding: 10px 24px;
                }
              }
              .switchBlk {
                position: absolute;
                right: 24px;
                top: -7px;
              }
            }
          }
        }
      }
      .overviewSection {
        padding: 24px;
        .titleRowWrapper {
          border-bottom: 1px solid $color_DBEAF4;
          margin-bottom: 24px;
          ul {
            padding: 0;
            li {
              padding: 0 24px;
              &.title {
                padding-left: 0;
                padding-right: 40px;
                h1 {
                  img {
                    margin-right: 8px;
                    position: relative;
                    top: 1px;
                  }
                }
              }
              a {
                padding: 0 0 16px;
                display: inline-block;
                border-bottom: 2px solid transparent;
                &.active {
                  border-bottom: 2px solid $blue;
                  color: $gray1;
                }
                &:hover {
                  border-bottom: 2px solid $blue;
                  color: $gray1;
                }
              }
            }
          }
        }
        .recruitmentContentWrapper {
          .headingRow {
            margin-bottom: 28px;
            .titleBlk {
              position: relative;
              img {
                position: absolute;
                left: 0;
                top: 0;
                width: 38px;
              }
              .info {
                margin-left: 54px;
              }
            }
            .syncBlk {
              li {
                padding: 0 8px;
                &.invalidAuth {
                  span {
                    padding: 2px 8px;
                    color: $red;
                    border-radius: 2px;
                    background: rgba(232, 58, 58, 0.1);
                  }
                }
              }
              .arrowDown {
                margin-left: 32px;
              }
            }
          }
          .tableTitleRow {
            padding: 0 16px 16px;
            h4 {
              font-size: $font12;
              text-transform: uppercase;
              &.jobTitle {
                // padding-left: 16px;
              }
              img {
                margin-left: 13px;
                position: relative;
                top: 3px;
              }
            }
          }
          .contentWrapper {
            background: $white;
            border-radius: 4px;
            padding: 24px 16px;
            margin-bottom: 40px;
            .tableContentRow {
              margin-bottom: 24px;
              .jobTitleBlk {
                position: relative;
                .jobIcon {
                  position: absolute;
                  left: 0;
                  top: 2px;
                  width: 16px;
                }
                .info {
                  margin-left: 30px;
                  h3 {
                    color: $gray1;
                    margin-bottom: 4px;
                    img {
                      margin-left: 8px;
                      position: relative;
                      top: 1px;
                    }
                    .closedLabel {
                      background: rgba(191, 212, 223, 0.3);
                      padding: 2px 8px;
                      margin-left: 12px;
                      border-radius: 4px;
                      font-size: 13px;
                      font-weight: $semiBoldFont;
                    }
                  }
                  p {
                  }
                }
              }
              .candidateAcceptedBlk {
                // posi
                .count {
                }
                button {
                  font-size: $font12;
                  padding: 6px 8px;
                  margin: 0 32px 0 48px;
                }
                .more {
                  float: right;
                }
              }
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
          .recruitmentOptionsSection {
            padding: 24px 0;
          }
        }
      }
    }
  }
  .addNewJobDialog {
    :global(.MuiDialog-paper) {
      max-width: 575px;
      width: 575px;
    }
    .dialogTitle {
      .title {
        position: relative;
        min-height: 48px;
        img {
          position: absolute;
          left: 0;
          top: 0;
          height: 48px;
        }
        span {
          display: block;
          margin-left: 60px;
          position: relative;
          top: 10px;
        }
      }
    }
    .dialogBody {
      padding: 24px 32px;
      h2 {
        margin: 30px 0 24px;
      }
      & :global(.inputGroup) {
        margin-bottom: 16px;
      }
    }
    .dialogActions {
      & :global(.tertiaryBtn) {
        padding: 10px 16px;
        margin-right: 10px;
      }
    }
  }
  .deleteJobDialog {
    & :global(.confirmationFig) {
      height: 170px;
    }
    h4 {
      margin-bottom: 64px !important;
      p {
        font-size: $font20;
        line-height: $lineHeight27;
        color: $gray1;
        font-weight: $regularFont;
        span {
          font-weight: $semiBoldFont;
        }
      }
    }
  }
  .checkinDrawer {
    & :global(.MuiPaper-root) {
      min-width: 35%;
    }
    .checkinWrapper {
      .topWrapper {
        padding: 16px;
        background: $background_lightblue;
        h1 {
          img {
            margin-right: 20px;
            cursor: pointer;
            position: relative;
            top: 2px;
          }
        }
      }
      .bodyWrapper {
        padding: 30px 16px;
        .infoRow {
          p {
            margin-bottom: 12px;
          }
          h3 {
            color: $gray2;
          }
        }
        h2 {
          margin-bottom: 24px;
        }
        .fieldsRow {
        }
      }
      .bottomWrapper {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        border-top: 1px solid $gray7;
        ul {
          text-align: right;
          padding: 16px 28px;
          li {
            padding: 0 8px;
            &:last-child {
              padding-right: 0;
            }
          }
        }
      }
    }
  }
  .integrateWithGreenHouseDialog {
    :global(.MuiDialog-paper) {
      max-width: 575px;
      width: 575px;
    }
    .dialogTitle {
      text-align: center;
      ul {
        justify-content: center;
        li {
          img {
            height: 86px;
            &.integrateImg {
              height: auto;
            }
          }
        }
      }
    }
    .dialogBody {
      padding: 24px;
      h1 {
        margin-bottom: 16px;
      }
      p {
        margin-bottom: 40px;
      }
      h2 {
        background: $blue6;
        border-radius: 2px;
        text-align: left;
        padding: 4px 8px 6px;
        margin-bottom: 18px;
        img {
          position: relative;
          top: 2px;
          margin-right: 8px;
        }
      }
      button {
        width: 250px;
      }
    }
  }
  .greenHouseContinueDialog {
    :global(.MuiDialog-paper) {
      max-width: 575px;
      width: 575px;
    }
    .dialogTitle {
      ul {
        padding: 0;
        li {
          padding: 8px 16px 0;
          .logo {
            height: 86px;
          }
        }
      }
    }
    .dialogBody {
      padding: 24px;
      h1 {
        margin-bottom: 8px;
      }
      p {
        margin-bottom: 24px;
      }
      .jobsList {
        .jobItem {
          padding: 8px 16px;
          margin-bottom: 14px;
          cursor: pointer;
          position: relative;
          .jobTitleBlk {
            position: relative;
            margin-right: 34px;
            .jobIcon {
              position: absolute;
              left: 0;
              top: 4px;
              width: 16px;
            }
            .info {
              margin-left: 30px;
              h3 {
                color: $gray2;
                margin-bottom: 4px;
                img {
                  margin-left: 8px;
                  position: relative;
                  top: 1px;
                }
              }
              p {
                margin-bottom: 0;
              }
            }
          }
          .checkBoxBlk {
            position: absolute;
            right: 0;
            top: 15px;
          }
          &:hover {
            border-radius: 4px;
            background: $blue6;
          }
        }
      }
      :global(.search) {
        border: 1px solid $gray7 !important;
      }
      :global(.searchArea) {
        padding-right: 0 !important;
      }
      :global(.search input) {
        height: 30px !important;
        padding-left: 40px !important;
      }
      :global(.searchIcon img) {
        height: 16px !important;
        padding: 8px 10px !important;
      }
      :global(.searchIcon) {
        left: 0 !important;
        right: auto;
      }
    }
  }
}

//mobile portrait view //
@media (min-width: 0px) and (max-width: 599px) {
  body {
  }
}

@media (min-width: 600px) and (max-width: 959px) {
  body {
  }
}

@media (min-width: 960px) and (max-width: 1279px) {
  body {
    .recruitmentOuterPageWrapper {
      .recruitmentOptionsPage {
        .overviewSection {
          .recruitmentContentWrapper {
            .contentWrapper {
              .tableContentRow {
                .candidateAcceptedBlk {
                  button {
                    margin: 0 20px 0 18px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1280px) and (max-width: 1360px) {
  body {
    .recruitmentOuterPageWrapper {
      .recruitmentOptionsPage {
        .overviewSection {
          .recruitmentContentWrapper {
            .contentWrapper {
              .tableContentRow {
                .candidateAcceptedBlk {
                  button {
                    // margin: 0 12px 0 16px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
