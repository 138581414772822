@import 'variable';
body {
  .trainingPage {
    .setupTrainingIntroSection {
      background: $background_green;
      padding: 16px 24px;
      position: relative;
      .titleWrapper {
        position: relative;
        .imgBlk {
          position: absolute;
          left: 0;
          top: 0;
          height: 50px;
          width: 50px;
          border-radius: 50%;
          background: $white;
          img {
            position: relative;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-height: 40px;
            max-width: 40px;
          }
        }
        .contentBlk {
          margin-left: 74px;
          h1 {
            margin-bottom: 4px;
          }
          p {
            color: $gray3;
          }
        }
      }
      .stepsWrapper {
        .progressBlk {
          .stepProgress {
            width: 90%;
          }
          & :global(.progressBar .progressValue) {
            display: none;
          }
        }
        .stepsBlk {
          h2 {
            .steps {
              position: relative;
              top: 2px;
              color: $gray2;
            }
            .supportText {
              color: $gray3;
            }
          }
        }
      }
      .patternBg {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
      }
      &.userTrainingIntro {
        background: unset;
        padding: 16px 0;
        margin: 0 24px;
        border-bottom: 1px solid #dbeaf4;
        .titleWrapper {
          .imgBlk {
            background: transparent;
            width: unset;
            height: 40px;
          }
          .contentBlk {
            margin-left: 32px;
          }
        }
        .readinessInfoWrapper {
          p {
            justify-content: flex-end;
            .value {
              color: $gray2;
              font-size: $font14;
              font-weight: $semiBoldFont;
            }
            span {
              margin-left: 8px;
            }
          }
        }
        .completedCountWrapper {
          justify-content: flex-end;
          .progressBlk {
            // text-align: right;
            :global(.progressBar) {
              progress {
                max-width: 100px;
              }
              span {
                display: none;
              }
            }
          }
          .value {
            color: $gray2;
            font-size: $font14;
            font-weight: $semiBoldFont;
          }
          span {
            margin-left: 8px;
          }
        }
      }
    }
    .setupModuleSection {
      padding: 24px;
      .setupModuleWrapper {
        margin-bottom: 24px;
        .titleRow {
          position: sticky;
          position: -webkit-sticky;
          top: 64px;
          margin-bottom: 12px;
          z-index: 998;
          background: $background_grey;
          h2 {
            color: $gray3;
          }
          .addNewModuleBtn {
          }
        }
        .tabListWrapper {
          margin: 24px 0;
        }
        .contentRow {
          .modulesSliderOuterWrapper {
            width: 102%;
            margin-left: -12px;
            .cardWrapper {
              // margin: 12px;
              // min-height: auto;
            }
            & :global(.slick-slide) {
              > div {
                margin: 12px;
              }
            }
            & :global(.slick-track) {
              margin: 0;
            }
            & :global(.slick-prev) {
              left: 0;
              width: 32px;
              height: 32px;
              z-index: 99;
              transform: rotate(-180deg);
              top: 44%;
            }
            & :global(.slick-next) {
              right: 0;
              width: 32px;
              height: 32px;
              z-index: 99;
              top: 44%;
            }
            & :global(.slick-disabled) {
              opacity: 0;
            }
            /* the slides */
            // & :global(.slick-slide) {
            //     margin: 12px;
            // }
            // /* the parent */
            // & :global(.slick-list) {
            //     margin: -12px;
            // }
          }
          .cardWrapper {
            background: $white;
            border-radius: 4px;
            padding: 8px;
            display: block;
            width: 100%;
            box-sizing: border-box;
            position: relative;
            cursor: pointer;
            &:hover {
              .dragElement {
                position: absolute;
                cursor: move !important;
                top: 4px;
                left: calc(50% - 12px);
                right: calc(50% - 12px);
                &::after {
                  content: url($imageBasePath + 'drag_to_do.svg');
                  -webkit-transform: rotate(90deg);
                  -moz-transform: rotate(90deg);
                  -ms-transform: rotate(90deg);
                  -o-transform: rotate(90deg);
                  transform: rotate(90deg);
                  display: inline-block;
                }
              }
            }

            // min-height: 286px;
            // margin-right: 24px;
            .topBlk {
              background: $background_grey;
              border-radius: 4px;
              position: relative;
              min-height: 220px;
              .content {
                padding: 16px;
                .iconBlk {
                  position: relative;
                  .featuredImg {
                    height: 34px;
                    margin-bottom: 16px;
                  }
                  span {
                    position: absolute;
                    left: 40px;
                    top: 5px;
                    background: $white;
                    border-radius: 2px;
                    padding: 2px 8px;
                    font-size: 10px;
                    color: $gray3;
                  }
                }
                .contentCountWrapper {
                  position: relative;
                  z-index: 99;
                  img {
                    margin-right: 8px;
                    position: relative;
                    top: 2px;
                  }
                  span {
                    font-weight: $regularFont;
                    margin-left: 5px;
                  }
                }
                .contentCountWrapperNew {
                  position: relative;
                  z-index: 99;
                  background-color: #fff;
                  width: fit-content;
                  padding: 0.5rem 0.75rem 0.5rem 0.8rem;
                  border-radius: 50px;
                  img {
                    margin-right: 8px;
                    position: relative;
                    top: 2px;
                  }
                  span {
                    font-weight: $regularFont;
                    margin-left: 5px;
                  }
                }
                h3 {
                  margin-bottom: 8px;
                  padding-right: 16px;
                  color: $gray2;
                  z-index: 99;
                  position: relative;
                }
                p {
                  color: $gray2;
                  padding-right: 16px;
                  z-index: 99;
                  margin-bottom: 16px;
                  position: relative;
                  min-height: 54px;
                }
                .newAlert {
                  position: absolute;
                  top: 6px;
                  right: 6px;
                  background: $orange;
                  color: $white;
                  padding: 2px 8px;
                  border-radius: 25px;
                }
                .more {
                  position: absolute;
                  top: 6px;
                  right: 6px;
                  z-index: 99;
                  img {
                    padding: 10px;
                  }
                }

                .trainingPattern {
                  position: absolute;
                  left: 0;
                  bottom: 0;
                  z-index: 9;
                }
              }
            }
            .bottomBlk {
              padding: 16px 0 8px;
              .progressBlk {
                text-align: right;
                :global(.progressBar) {
                  progress {
                    max-width: 100%;
                    &::-webkit-progress-bar {
                      background-color: $gray7;
                    }
                  }
                  span {
                    display: none;
                  }
                }
              }
              p {
                .contentCount {
                }
                img {
                  margin-left: 6px;
                  vertical-align: middle;
                }
              }
              ul {
                padding: 0;
                text-align: right;
                li {
                  padding: 0 8px;
                  button {
                    &:global(.primaryBtn) {
                      border: 1px solid transparent;
                    }
                  }
                  &:last-child {
                    padding-right: 0;
                  }
                }
              }
            }

            &.adminCardWrapper {
              .topBlk {
                min-height: fit-content;
                .content {
                  p {
                    min-height: fit-content !important;
                  }
                }
              }
              & :global(.featuredImg) {
                margin-bottom: auto;
                min-width: 34px !important;
                margin-top: -2px;
              }
              & :global(.heading3),
              & :global(.byLabel) {
                padding: 0 !important;
                margin: 0 !important;
              }
              & :global(.byLabel) {
                padding-top: 0.15rem !important;
              }
            }
          }
        }
      }
    }
    .moduleTitleWrapper {
      background: $background_lightgrey;
      padding: 24px;
      .titleBlk {
        position: relative;
        .backBtn {
          position: absolute;
          left: 0;
          top: 6px;
        }
        .contentBlk {
          margin-left: 40px;
          h1 {
            margin-bottom: 6px;
            img {
              margin-left: 8px;
              vertical-align: middle;
            }
            // color: $gray3;
          }
        }
      }
    }
    .moduleDetailSection {
      min-height: calc(100vh - 64px);
    }
    .assesmentPendingWrapper {
      background: $white;
      box-shadow: 0px 2.02393px 8.09573px 5.05983px rgba(194, 199, 206, 0.53);
      border-radius: 8px;
      min-height: 142px;
      margin: 20px 0px;
      .titleRow {
        min-height: 142px;
        .titleBlk {
          position: relative;
          min-height: 142px;
          .imgBlk {
            position: absolute;
            left: 0;
            top: 0px;
            height: 142px;
            width: 342px;
            object-fit: cover;
            img {
              border-radius: 8px;
            }
          }
          .contentBlk {
            margin-left: 362px;
            min-height: 142px;
            h1 {
              margin-bottom: 6px;
              padding-top: 46px;
              img {
                margin-left: 8px;
                vertical-align: middle;
              }
              // color: $gray3;
            }
          }
        }
        .startBtn {
          // width: 178px;
          margin: 0 16px;
        }
      }
      &.viewFeedbackCardWrapper {
        min-height: 82px;
        box-shadow: none;
        .titleRow {
          min-height: 82px;
          .titleBlk {
            position: relative;
            min-height: 82px;
            .imgBlk {
              position: absolute;
              left: 0;
              top: 0px;
              height: 82px;
              width: 178px;
              object-fit: cover;
              padding: 4px;
            }
            .contentBlk {
              margin-left: 200px;
              min-height: 82px;
              h1 {
                padding-top: 14px;
              }
            }
          }
        }
      }
    }
    .assesmentStrip {
      padding: 12px 24px 0;
    }
    .assesmentViewWrapper {
      margin: 24px 0px;
      background: $white;
      padding: 24px;
      min-height: calc(100vh - 225px);

      .titleWrapper {
        margin-bottom: 32px;
        h2 {
        }
        .editBtn {
          padding: 6px 40px;
        }
      }
      .contentWrapper {
        .cardItem {
          border-bottom: 1px solid #dbeaf4;
          padding: 32px 0 48px;

          h3 {
            margin-bottom: 8px;
          }
          p {
            margin-bottom: 8px;
          }
          ul {
            li {
              padding: 4px 4px;
              .listTitle {
                font-weight: $semiBoldFont;
                color: $gray2;
              }
              .tag {
                color: $gray3;
                background: $gray7;
                border-radius: 14px;
                padding: 2px 16px;
              }
              &:first-child {
                padding-left: 0;
                // padding-right: 8px;
              }
            }
          }
          &:first-child {
            padding-top: 0;
          }
          &:last-child {
            border-bottom: none;
          }
          .moreBtn {
            position: relative;
            .more {
              position: absolute;
              top: 0;
              right: 0px;
              padding: 8px;
            }
          }
        }
      }
    }
    .viewMoreBtn {
      button {
        border-radius: 25px;
        padding: 6px 16px;
        font-size: $font12;
      }
    }
    //harsha scss
    .dashboard {
      padding: 24px;
      .moduleDetails {
        margin-bottom: 24px;
        .upvote,
        .downvote {
          position: relative;
          span {
            position: relative;
            left: 8px;
            bottom: 2px;
          }
        }
        .downvote {
          span {
            bottom: 4px;
          }
        }
        :global(.progressBar) {
          progress {
            max-width: 150px !important;
          }
        }
        :global(.comments) {
          left: 0 !important;
        }
      }
      .teamProgress {
        :global(.circularProgressbar) {
          margin-left: 12px;
        }
        .readinessIndex {
          img {
            position: relative;
            margin-right: 8px;
            top: 2px;
          }
        }
        .viewAssessment {
          position: relative;
          display: inline-block;
          button {
            font-size: $font12;
          }
          .pending {
            width: 7px;
            height: 7px;
            background: $red;
            border-radius: 50%;
            display: inline-block;
            position: absolute;
            right: -2px;
            top: -2px;
          }
        }
      }
      .setupModuleSection {
        padding: 24px 0;
      }
    }
  }
  .addNewModule {
    :global(.MuiDialog-paper) {
      max-width: 575px;
      width: 575px;
    }
    .dialogBody {
      padding: 24px 48px 40px;
    }
  }
  .publishDialog {
    :global(.MuiDialog-paper) {
      max-width: 480px;
      width: 480px;
    }
    :global(.dialogBody) {
      ul {
        li {
          button {
            padding: 12px 32px;
          }
        }
      }
    }
  }
  .assesmentInitDialog {
    .dialogTitle {
      // background: linear-gradient(175.26deg, #0494FD 11.38%, rgba(4, 148, 253, 0.03) 146.27%);
      padding: 0;
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
      .imgWrapper {
        img {
          min-height: 175px;
          height: 175px;
          width: 100%;
          min-width: 425px;
          object-fit: cover;
          border-bottom-left-radius: 16px;
          border-bottom-right-radius: 16px;
        }
      }
    }
    .dialogBody {
      padding: 24px;
      h1 {
        margin-bottom: 16px;
      }
      p {
        margin-bottom: 16px;
      }
    }
    .dialogActions {
      padding: 24px;
      justify-content: center;
      border-top: none;
      button {
        padding: 14px 32px;
        width: 184px;
      }
    }
  }
  .assesmentcreateDialog {
    & :global(.MuiPaper-root) {
      width: 850px;
      max-width: 850px;
    }
    .dialogTitle {
      .title {
        min-height: 44px;
        vertical-align: top;
        img {
          display: inline-block;
          vertical-align: top;
          background: $white;
          padding: 12px;
          border-radius: 50%;
          margin-right: 8px;
        }
        span {
          display: inline-block;
        }
      }
    }
    .dialogBody {
      padding: 32px 24px;
      min-height: 400px;
      .formWrapper {
        background: $background_grey;
        padding: 24px 12px 0;
        margin-bottom: 28px;
        position: relative;
        .selectType {
          margin-bottom: 24px;
          & :global(.inputGroup) {
            margin-bottom: 0px;
          }
        }
        .addMoreCreteria {
          margin-bottom: 24px;
          a {
            font-size: $font12;
          }
          // padding-left: 16px;
          // text-align: right;
        }
        .creteriaBlk {
          position: relative;
          & :global(.inputGroup) {
            margin-bottom: 8px;
          }
          .criteriaWrapper {
            position: relative;
            display: flex;
            gap: 0.5rem;
            max-width: 90%;
            & :global(.inputGroup) {
              flex: 1;
            }
          }
          .criteriaDelete {
            position: absolute;
            right: -22px;
            top: 18px;
            &.isMultiple {
              right: -3rem;
              top: 16px;
              display: flex;
              align-items: center;
              gap: 0.35rem;
            }
            button {
              height: 16px;
              opacity: 0.8;
            }
          }
        }
        .questionDelete {
          position: absolute;
          bottom: 8px;
          right: 12px;
        }
      }
      .addMore {
      }
    }
    .dialogActions {
    }
  }
  .assesmenteditDialog {
    .assessmentCircularProgress {
      margin-right: 32px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      .circularProgressBar {
        width: 32px;
        height: 32px;
      }
    }
    & :global(.MuiPaper-root) {
      width: 850px;
      max-width: 850px;
    }
    .dialogTitle {
      .title {
        min-height: 44px;
        vertical-align: top;
        img {
          display: inline-block;
          vertical-align: top;
          background: $white;
          padding: 12px;
          border-radius: 50%;
          margin-right: 8px;
        }
        span {
          display: inline-block;
        }
      }
    }
    .infoOuterWrapper {
      padding: 16px 24px 32px;
      background: $background_grey;
      .userInfoWrapper {
        position: relative;
        text-align: left;
        min-height: 80px;
        .userImgBlk {
          position: absolute;
          left: -4px;
          top: 0px;
          img {
            border: 4px solid $white;
            border-radius: 50%;
            width: 80px;
            height: 80px;
          }
          .userImg {
            border: 4px solid $white;
            width: 80px;
            height: 80px;
            font-size: $font32;
          }
        }
        .contentBlk {
          margin-left: 106px;
          h3 {
            // margin-top: 4px;
            margin-bottom: 16px;
          }
          p {
            margin-bottom: 8px;
            span {
              color: $gray2;
              &.email {
                color: $blue;
              }
            }
            &:last-child {
              margin: 0;
            }
            img,
            span {
              vertical-align: middle;
            }
            img {
              margin-right: 8px;
              margin-top: 2px;
            }
          }
        }
      }
    }
    .dialogBody {
      min-height: 340px;
      .averageRatingWrapper {
        pointer-events: none;
        padding-bottom: 8px;
        h4 {
          padding-bottom: 12px;
        }
      }
      .customRating {
        margin-bottom: 12px;
        .ratingScore {
          position: relative;
          top: -3px;
          padding-left: 8px;
        }
        label {
          width: 50px;
          height: 19px;
          & :global(.MuiRating-icon) {
            width: 50px;
            height: 18px;
            margin-right: 4px;
          }
          & :global(.MuiRating-iconFilled) {
            .ratingItem {
              background-color: $blue;
            }
          }
          & :global(.MuiRating-iconHover) {
            width: 50px;
            height: 18px;
            transition: none;
            .ratingItem {
              background-color: $blue;
            }
          }
        }
        & :global(.MuiRating-pristine) {
          display: none;
        }
        .ratingItem {
          display: inline-block;
          width: 50px;
          height: 18px;
          background: rgba(231, 240, 245, 0.35);
          border: 1px solid #a0b7c6;
          border-radius: 4px;
          margin-right: 4px;
          &:hover {
            width: 50px;
            height: 18px;
            span {
              width: 50px;
              height: 18px;
            }
          }
        }

        // & :global(.MuiRating-iconActive){
        //   width: 50px;
        //   height: 17px;
        //   background:$blue;
        // }
      }
      &.viewOnlyMode {
        pointer-events: none;
        z-index: 9;
        .viewBlk {
          pointer-events: visible;
          z-index: 99;
        }
      }
      .formWrapper {
        padding: 32px 40px 8px 40px;
        .formItem {
          border-bottom: 1px solid #dbeaf4;
          margin-bottom: 24px;
          &:last-child {
            border-bottom: none;
          }
        }

        .assesmentContent {
          padding-bottom: 8px;
          margin-bottom: 8px;
          h3 {
            margin-bottom: 8px;
          }
          p {
            margin-bottom: 0;
            &.description {
              font-size: $font14;
              margin: 8px 0 20px;
            }
          }
          .textarealink {
            max-height: 85px !important;
            min-height: 85px !important;
          }
          .uploadVedioBlk {
            margin-bottom: 30px;
          }
          .vedioBlk {
            margin-bottom: 30px;
            video {
              height: 125px;
              width: 250px;
              object-fit: cover;
              border-radius: 4px;
            }
          }
          .viewBlk {
            position: relative;
            margin-bottom: 30px;
            min-height: 85px;
            video {
              width: 225px;
              height: 125px;
              object-fit: cover;
              border-radius: 4px;
            }
            img {
              position: absolute;
              left: 0;
              top: 0;
              height: 85px;
              width: 175px;
              object-fit: cover;
              background: $gray7;
              min-height: 85px;
            }
            .viewBtn {
              margin-left: 200px;
              position: relative;
              top: 20px;
            }
          }
          h4 {
            margin-bottom: 16px;
          }
          .reviewContent {
            color: $gray1;
          }
        }
      }
    }
    &.userAssesmentDialog {
      .dialogBody {
        .contentRow {
          border-bottom: 1px solid #dbeaf4;
          margin-bottom: 40px;
          &:last-child {
            border-bottom: none;
          }
        }
        .assesmentContent {
          .description {
            font-size: $font14;
            margin: 8px 0 20px;
          }
          video {
            width: 225px;
            height: 125px;
            object-fit: cover;
            border-radius: 4px;
          }
          img {
            height: 85px;
            width: 175px;
            object-fit: cover;
            background: $gray7;
            min-height: 85px;
          }
          .uploadVedioBlk {
            margin-bottom: 16px;
            .uploadBtn {
              display: inline-block;
              position: relative;
              cursor: pointer;
              button {
                cursor: pointer;
              }
              .btnWrapper {
                position: relative;
                display: inline-block;
              }
              .hiddenUploadBtn {
                position: absolute;
                left: 0px;
                top: 0px;
                z-index: 99;
                opacity: 0;
                cursor: pointer;
                padding: 5px;
                width: 155px;
                input {
                  width: 160px;
                  cursor: pointer;
                  height: 32px;
                  position: relative;
                  top: -4px;
                }
              }
              .uploadedFileName {
                position: relative;
                top: 1px;
                color: $blue;
                margin: 16px 0;
                display: inline-block;
              }
              .insertLinkBtn {
                margin-left: 32px;
              }
            }
            .supportedFileFormate {
              margin-top: 8px;
              font-size: 10px;
            }
          }
        }
      }
    }
  }
  .recordingAssessmentButton {
    & :global(.dzu-dropzoneDisabled) {
      opacity: 1;
    }
    & :global(.dzu-dropzone) {
      min-height: 122px;
      overflow: hidden;
      align-items: start;
    }
    & :global(.dzu-inputLabel) {
      justify-content: start !important;
      align-items: start !important;
      width: fit-content;
    }
    & :global(.filePreviewComponent) {
      max-width: 360px !important;
    }
    & :global(video) {
      width: 100% !important;
      height: auto !important;
    }
    & :global(img) {
      background: none !important;
      object-fit: contain !important;
      min-height: fit-content !important;
      height: fit-content !important;
      width: fit-content !important;
    }
    & :global(.recording-button) {
      margin: 0 !important;
      height: 120px;
      max-width: 360px;
      gap: 2.5rem;
      cursor: pointer;
      padding-inline: 2rem;
      & :global(img) {
        width: 85px !important;
      }
    }
  }
}

//mobile portrait view //
@media (min-width: 0px) and (max-width: 599px) {
  body {
  }
}

@media (min-width: 600px) and (max-width: 959px) {
  body {
  }
}

@media (min-width: 960px) and (max-width: 1279px) {
  body {
  }
}

@media (min-width: 1280px) {
  body {
  }
}
