.label {
  font-size: 12px;
  margin-bottom: 6px;
}
.subtitle {
  color: #193448ad;
  font-size: 12px;
  margin-bottom: 0.5rem;
}

.number {
  width: 1rem;
  height: 1rem;
  border: 1px solid #1934483d;
  color: #193448;
  border-radius: 50%;
  display: grid;
  place-content: center;
  font-size: 12px;
}
