@import 'assets/styles/_variable.scss';

.thumbnailSection {
  padding: 0 0.75rem;
  padding-bottom: 0;

  & :global(.thumbnail_v1) {
    background: #0495fd05;
    margin-bottom: 0.75rem;
    margin-top: 1rem;
    border-radius: 0.5rem;
    border: 1px solid #0495fd87;
    height: auto;
    display: flex;
    position: relative;
    width: 95%;
    max-width: 720px;

    img {
      position: relative;
      border-radius: 0.25rem;
      width: 100%;
      height: 110px;
      object-fit: cover;
      transform: translate(0) !important;
    }

    a {
      text-decoration: none;
      color: $blue;
      text-decoration: underline;
      font-weight: 600;
      padding: 0.5rem;
      padding-bottom: 0;
      display: inline-block;
      transform: translateY(4px) !important;
    }

    p {
      padding: 0.5rem;
    }

    @media (min-width: 1220px) {
      flex-wrap: nowrap;
      align-items: center;
      gap: 0.5rem;
      img {
        height: 86px;
        width: 160px;
        margin: 0.5rem 0 0.5rem 0.6rem;
      }
      :global(.delete) {
        img {
          bottom: -6px;
        }
      }
    }

    :global(.delete) {
      position: absolute;
      right: -1.7rem;
      bottom: -2px;
      font-size: 1.1rem;
      cursor: pointer;
      font-weight: 500;
      opacity: 0.4;
      &:hover {
        opacity: 0.7;
      }
      img {
        height: 18px;
        width: 26px;
        object-fit: contain;
      }
    }
  }
}
