@import 'assets/styles/_variable.scss';

.integrationModalContainer {
  :global(.MuiDialog-paper) {
    min-width: 360px;
    width: 50%;
  }

  .dialogTitleContainer {
    background: none !important;

    .title {
      width: 100%;
      margin-top: 1rem;
      text-align: center;

      font-size: 1.5rem;
      font-weight: 400;
      color: #193448;
      b {
        font-weight: 600;
      }
    }

    .dialogTitle {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;

      img {
        height: 62px;
        display: grid;
        align-content: center;
      }

      .dots {
        display: flex;
        gap: 0.5rem;
        div {
          border-radius: 50%;
          width: 0.25rem;
          height: 0.25rem;
        }
      }
    }
  }
}
