@import 'variable';
body {
  .subscriptionsPage {
    padding: 1.5rem;
    padding-top: 0;
  }

  .paymentTitleWrapper {
    display: flex;
    gap: 20;
    align-items: center;
    margin: 0.8rem 0;
    img {
      width: 55px;
      height: 55px;
    }
    h4 {
      display: block;
    }
  }

  .card {
    padding: 1rem;
  }
  .muted {
    color: #6a8294;
    font-size: 14px;
    font-weight: 400;
  }
}
