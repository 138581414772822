@import 'variable';
body {
  .settingsPage {
    padding: 24px;
    .settingsSection {
      .settingsTitleRow {
        .settingsIcon {
          float: left;
          width: 18px;
          height: 18px;
          margin-top: 5px;
        }
        .settingsTitle {
          margin-left: 14px;
          display: inline-block;
        }
      }
      .settingsTabRow {
        margin: 22px -8px;
        .tabGroup {
          padding: 8px 0px;
          margin: 0px 8px;
          border-bottom: 1px solid #dbeaf4;
          .tabHeading {
            display: inline;
            margin-right: 24px;
            &:hover {
              background: none;
            }
            &.activeTab {
              border-bottom: 4px solid #0494fd;
            }
            &.disabledTab {
              color: $gray2;
              font-weight: $regularFont;
            }
          }
        }
      }
      .settingsCompanyDetailsRow {
        margin: 30px -8px;
        .companyDetailsTitle {
          color: $gray2;
        }
      }
      .settingsFormSection {
        width: 100%;
        .settingsCompanyLogoWrapper {
          display: inline-block;
          margin-bottom: 20px;
          .settingsCompanyBrandedLogo {
            height: 110px;
            .centerVertical {
              height: 100%;
              display: flex;
              align-items: flex-end;
            }
            .brandedLogo,
            .logo {
              float: left;
              object-fit: cover;
            }
            .logo {
              width: 110px;
              height: 110px;
            }
            .brandedLogo {
              max-width: 200px;
              height: 32px;
            }
          }
          > div {
            position: relative;
            display: inherit;
            overflow: hidden;
            a {
              position: absolute;
              height: 100%;
              width: 100%;
              margin-top: 70px;
              margin-left: 24px;
              top: 0;
              left: 0;
              opacity: 0;
              input {
                height: 48px;
                width: 106px;
                cursor: pointer;
              }
            }
          }
          .settingsLogoPicker {
            margin-top: 70px;
            margin-left: 24px;
            padding: 14px 26px;
          }
        }
        .settingsFormInputWrapper {
          margin-bottom: 140px;
          .settingsFormInputRow {
            .settingsFormInput {
              & label,
              .settingsCustomLabel {
                color: $gray1;
                font-size: $font14;
                font-weight: $regularFont;
                line-height: $lineHeight21;
                margin-bottom: 16px;
              }
              .customDisabledInput {
                margin-top: 16px;
                margin-bottom: 20px;
              }
              // & :global(.css-1w9j89e-menu > div) {
              //   background: $white;
              //   position: absolute;
              //   width: 100%;
              //   top: -105px;
              // }
              .settingsFormCustomLabel {
                & label {
                  color: $gray1;
                  font-size: $font14;
                  font-weight: $regularFont;
                  line-height: $lineHeight21;
                  margin-bottom: 16px;
                }
              }
              .settingsCustomLabel {
                display: block;
              }
              .settingsInputBtn {
                padding: 4px 24px;
                background: $white;
                color: $gray4;
                border: 1px solid $gray7;
                margin-right: 24px;
                margin-bottom: 8px;
                line-height: $lineHeight27;
                &.activeBtn {
                  border: 1px solid $blue;
                  color: $blue;
                }
              }
              .supportEmailLabel {
                color: $gray1;
                font-size: $font14;
                font-weight: $regularFont;
                line-height: $lineHeight21;
                margin-bottom: 10px;
                position: srelative;
                .supportEmailTootltip {
                  position: absolute;
                  margin-left: 8px;
                  top: 2px;
                }
              }
              .supportEmailWrapper {
              }
              .contactMailBtn {
                padding-top: 5px;
                :global(.tertiaryBtn) {
                  padding: 4px 8px;
                  border: 1px solid $blue;
                }
              }
              :global(.inputGroup) {
                position: relative;
              }
              .contactMailHiddenInput {
                opacity: 0;
                position: absolute;
                left: 0;
                z-index: -1;
              }
            }
          }
          .supportEmail {
            // font-style: italic;
          }
        }
        .settingsFormBtnRowWrapper {
          margin-top: 50px;
          .settingsFormBtnRow {
            padding-left: 0;
            margin-left: 8px;
            border-top: 1px solid #e7f0f5;
            padding-top: 22px;
            position: fixed;
            bottom: 0px;
            width: 100%;
            padding-bottom: 22px;
            background: #f3f5f8;
            .settingsFormBtn {
              margin-right: 20px;
            }
          }
        }
      }
    }
  }
}

//mobile portrait view //
@media (min-width: 0px) and (max-width: 599px) {
  body {
    .homePage {
    }
  }
}

@media (min-width: 600px) and (max-width: 959px) {
  body {
    .homePage {
    }
  }
}

@media (min-width: 960px) and (max-width: 1279px) {
  body {
  }
}

@media (min-width: 1280px) {
  body {
  }
}
