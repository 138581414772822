@import 'assets/styles/_variable.scss';

.newSettingsDialog {
  @media (min-width: $lg) {
    width: 80%;
    margin: 0 auto;
    & :global(.MuiDialog-paper) {
      width: 100%;
      border-radius: 0.75rem;
      height: 100%;
    }
  }
}

.newSettingsModal {
  display: flex;
  flex-direction: column;
  height: 100%;

  .settingsSection {
    background: $blue4;
  }

  @media (min-width: $lg) {
    flex-direction: row;
    .settingsSection {
      width: 45%;
    }
    .goalsSection {
      overflow-y: auto;
      width: 55%;
      margin-bottom: 4rem;
    }
  }
}

.settingsSection {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media (min-width: $lg) {
    padding-bottom: 7rem;
  }

  > img {
    width: 4rem;
    height: 4rem;
  }

  > span {
    font-weight: 600;
    font-size: 1.125rem;
  }

  .hubspotSwitchContainer {
    margin-top: auto;
    display: flex;
    gap: 0.75rem;
    align-items: center;
    border-radius: 0.25rem;
    border: 1px solid #6a82941f;
    padding: 0.5rem;
    p {
      font-size: 1rem;
    }
    .hubspotSwitch {
      margin-left: auto;
      & :global(.MuiFormControlLabel-label) {
        font-size: 0.75rem;
        color: $gray8;
      }
    }
  }
  .descriptionInput {
    resize: none;
    min-height: 90px;
  }
}

.goals {
  padding: 1rem;
  .customLabel {
    border-radius: 2px;
    color: $white;
    font-size: 10px;
    background-color: $gray6;
    padding: 0 0.5rem;
    position: absolute;
    top: 0;
  }
  .goal {
    background-color: $background_grey;
    padding: 1.5rem;
    h3 {
      color: #193448;
      font-size: 0.875rem;
      font-weight: 600;
    }
    & :global(.inputGroup) {
      margin-bottom: 0;
    }
    label {
      color: #193448;
    }
    .goalContent {
      align-items: center;
      margin-right: 10px;
      display: flex;
      gap: 2rem;
      justify-content: space-between;
      border-radius: 0.25rem;
      @media (max-width: $md) {
        flex-wrap: wrap;
      }

      & :global(.MuiFormControlLabel-label) {
        font-size: 0.75rem;
        color: $gray8;
      }
      .hubspotIcon {
        background-color: $white;
        border-radius: 50%;
        display: grid;
        place-items: center;
        width: 36px;
        height: 36px;
        img {
          width: 1rem;
          height: 1rem;
        }
      }
    }
  }
  .newGoal {
    padding-bottom: 1rem;
    & :global(.customNewGoal) {
      background-color: $background_grey;
      padding: 1.5rem;
      position: relative;
      h3 {
        color: #193448;
        font-size: 0.875rem;
        font-weight: 600;
      }
      & :global(.inputGroup) {
        margin-bottom: 0;
      }
      label {
        color: #193448;
      }
      img {
        position: absolute;
        cursor: pointer;
        right: 1rem;
        top: 1rem;
      }
      & :global(.unit) {
        background-color: $white;
        border: 1px solid $white;
        padding: 0.5rem 1rem;
        margin-right: 0.5rem;
        border-radius: 0.25rem;
        cursor: pointer;
      }
      & :global(.activeUnit) {
        border: 1px solid $blue;
        color: $blue;
      }
    }
  }
}

.saveContainer {
  position: sticky;
  bottom: 0;
  padding: 1.25rem;
  border-top: 1px solid $gray7;
  background: $white;
  display: flex;
  justify-content: end;
  width: 100%;
  box-sizing: border-box;
  @media (min-width: $lg) {
    position: absolute;
  }
}

.frequencyButtons {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;

  .btnFrequency {
    padding: 1rem;
    background-color: $white;
    border-radius: 0.25rem;
    border: 1px solid $gray7;
    color: $gray2;
    cursor: pointer;

    transition: background-color 0.3s, color 0.3s, opacity 0.3s;

    &.active {
      background-color: $white;
      border-color: $blue;
      color: $blue;
    }
    &.disabled {
      background-color: #b4bdc4;
      border-color: #b4bdc4;
      color: #fff;
      cursor: default;
    }
  }
}

.info {
  border-radius: 0.25rem;
  background: $blue6;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem;
  .freq {
    background: $gray6;
    color: $gray2;
    padding: 0 0.5rem;
    border-radius: 0.25rem;
  }
}
