.modalContent {
  display: flex;
  @media (max-width: 980px) {
    flex-wrap: wrap;
  }

  * {
    font-family: Open Sans;
  }

  & :global(.MuiFormControlLabel-label) {
    color: #5d8399;
    font-size: 0.75rem;
  }

  & :global(.MuiFormControlLabel-root) {
    display: flex;
    flex-wrap: wrap;
  }

  .leftSection {
    display: flex;
    flex-direction: column;
    background: #ffefeb;
    padding: 1.8rem;
    gap: 1.5rem;
    position: relative;

    @media (max-width: 980px) {
      width: 100%;
    }
    @media (min-width: 1200px) {
      padding: 1.8rem 4rem;
    }

    .backgroundLogo {
      position: absolute;
      bottom: 0;
      right: 0;
    }

    .circleImage {
      width: 48px;
      height: 48px;
      padding: 1rem;
      background: white;
      border-radius: 50%;
    }

    .integrationSwitch {
      display: flex;
      align-items: center;
      gap: 8px;

      flex-wrap: wrap;

      .heading {
        font-size: 1.5rem;
        font-weight: 600;
        color: #193448;
      }
    }

    .textarea {
      color: #6a8294;
      padding: 0.5rem;
      border: 1px solid #e7f0f5;
      border-radius: 0.25rem;
      background: #fff;
      z-index: 2;
      position: relative;

      @media (min-width: 980px) {
        max-width: 380px;
      }
    }
  }

  .rightSection {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1.75rem;
    min-width: 200px;

    .goals {
      @media (min-width: 900px) {
        height: 50vh;
        overflow-y: scroll;
      }

      .goal:nth-child(2n-1) {
        background-color: #f3f5f8;
      }
      .goal {
        h3 {
          color: #193448;
          font-size: 0.875rem;
          font-weight: 600;
        }
        & :global(.inputGroup) {
          margin-bottom: 0;
        }
        label {
          color: #193448;
        }
        .goalContent {
          align-items: center;
          margin-right: 10px;
          display: flex;
          gap: 2rem;
          justify-content: space-between;
          border-radius: 0.25rem;
          @media (max-width: 900px) {
            flex-wrap: wrap;
          }
        }
        padding: 1.5rem;
      }
    }
  }
}

.hubspotSettingsModal {
  :global(.MuiDialog-paper) {
    max-width: 80%;
    width: 100%;
  }
}
