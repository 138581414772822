@import '/src/assets/styles/variable';

body {
  .paymentContainer {
    min-height: 100vh;
    background-image: url($imageBasePath + 'singnin_background.png');
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .updateButton {
    font-family: 'Open Sans', sans-serif;
    padding: 0.75rem 2rem;

    min-width: 45%;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    background-color: #0494fd;
    &:hover {
      background-color: #027fd8;
    }
  }
  .paymentDialog {
    background-image: url($imageBasePath + 'singnin_background.png');
    padding: 4rem;
    padding-bottom: 2rem;
    margin: 0;
  }

  .flag {
    height: 16px;
    margin-right: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    object-fit: fill;
  }

  .bigFlag {
    height: 28px;
    max-width: 45px;
    margin-top: 4px;
    margin-left: 14px;
    border-radius: 4px;
    object-fit: cover;
  }

  .paymentForm {
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: space-between;
    align-items: center;
    width: 460px;
    padding: 1rem;
    padding: 0;
    margin: 25px 0;

    .paymentBody {
      display: block;
      width: 85%;
      padding-top: 15px;
    }
  }
  .cardInput {
    margin: 1rem 0px;
    padding: 1rem;
    background: #333;
    border-radius: 0.25rem;
  }
}

.formHeader {
  width: 100%;
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
  background-color: #f0f9ff;
  box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.18);
  color: #2196f3;
  padding: 15px 0;
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 10px;

  .logo {
    height: 30px;
  }
  .companyLogo {
    height: 21px;
    width: 21px;
    position: absolute;
    left: 20px;
  }
}

.pagination {
  width: 100%;
  background-color: #f0f9ff;
  box-shadow: 0 8px 6px 6px rgba(0, 0, 0, 0.18);
  padding: 8px 0;

  .container {
    display: flex;
    justify-content: center;
    gap: 16px;

    %circle {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #58b1fa;
    }

    .normal {
      @extend %circle;
      cursor: pointer;
    }
    .active {
      @extend %circle;
      background-color: #2196f3;
      transform: scale(1.3);
    }
    .disabled {
      @extend %circle;
      background-color: #bfbfbf;
    }
  }
}

.nextButtonContainer {
  margin-top: 1.2rem;
  display: flex;
  justify-content: center;
  button {
    font-weight: 400 !important;
  }
}

.billingForm {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
}

.summaryBody {
  .summaryCard {
    border-radius: 0.15rem;
    padding: 10px;
    background: #f0f9ff;

    hr {
      opacity: 0.3;
      margin: 6px 0;
    }
  }

  .summaryCard > div {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .summaryCard > div > span {
    font-weight: 600;
  }

  .summaryCard p {
    color: #2196f3;
    font-size: 0.8rem;
    text-align: start;
  }

  .summaryRow {
    border-left: 2px solid #cccccc;
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    margin: 16px 0;

    h4 {
      font-size: 0.85rem;
    }

    p {
      font-size: 0.8rem;
      line-height: 15px;
    }

    .summaryRowEdit {
      display: flex;
      align-items: center;
      color: #2196f3;
      font-size: 0.85rem;
      padding-right: 10px;
      cursor: pointer;
      margin-left: 10px;
    }

    .summaryRowEdit:hover {
      font-weight: 600;
    }
  }

  .termsAndConditions {
    a {
      color: #2196f3;
      padding: 0 8px;
    }
    a:hover {
      text-decoration: underline;
    }
  }
}

.productSelectorContainer {
  margin-bottom: 1rem;
  .priceName {
    color: #0494fd;
    font-weight: 500;
    margin-bottom: 1rem;
  }

  .selected {
    background-color: #e4f2fc;
  }

  .priceContainer {
    margin: 1rem 0;
    padding: 0.5rem;
    cursor: pointer;
    border-radius: 0.25rem;

    &:hover {
      background-color: #d4eaf8;
    }
  }
}

.changePlan,
.changePlanFree {
  margin-top: 1rem;
  color: #0495fd;
  cursor: pointer;
  display: inline-block;

  &:hover {
    color: #0077cb;
  }
}

.changePlanFree {
  font-size: 1.2rem;
  font-weight: 700;
}

// Cancel Subscription
.CancelDialog {
  background-color: #f3f5f8;
  // background-image: url($imageBasePath + 'singnin_background.png');
  margin: 0;
  padding-bottom: 2rem;
}

.cancelText {
  color: $blue;
  margin-top: 1rem;
  cursor: pointer;
  display: inline-block;
}
.cancelTextDisabled {
  color: $blue;
  margin-top: 1rem;
  cursor: default;
  opacity: 0.6;
  display: inline-block;
}

.cancelTitle {
  background-color: #f3f5f8;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 1rem;
  .cancelCloseIcon {
    &:hover {
      cursor: pointer;
    }
  }
  .titleText {
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
  }
}

.cancelBody {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  height: 11rem;
  .bodyText {
    padding-top: 1rem;
    font-size: 14px;
    font-weight: 400;
  }
  .bodyButtons {
    height: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    .cancel {
      color: #be0a0a;
      font-size: 14px;
      font-weight: 600;
      border: 1px solid #be0a0a;
      min-width: 10rem;
      height: 2.2rem;
      padding: 5px 1rem;
      border-radius: 6px;
      &:hover {
        color: #da5d5d;
        border: 1px solid #da5d5d;
      }
    }
  }
}

.strokedPrice {
  text-decoration: line-through;
  font-weight: 400;
  font-size: 1rem;
  margin: 0.25rem 0;
  display: inline-block;
  margin-right: 0.5rem;
}
.normalPrice {
  font-size: 1.25rem;
  margin-bottom: 0.25rem;
}
