.makeDefaultButton {
  font-size: 14px;
  color: #0494fd;
  background-color: #0494fd1f;
  height: 2rem;
  width: 8rem;
  border-radius: 6px;

  &:hover {
    color: #0487e4;
    background-color: #037cd31f;
  }
}

.default {
  font-size: 14px;
  color: #6a8294;
  background-color: #f3f5f8;
  height: 2rem;
  width: 8rem;
  border-radius: 6px;
  cursor: not-allowed;
}
