.dragButton {
  cursor: move !important;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
  z-index: 200;
  position: absolute;
  bottom: 5px;
  left: 15px;
}
