.teamPulseContainer {
  .sectionSelector {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    span {
      cursor: pointer;
      background: none;
      border-radius: 1rem;
      padding: 0.35rem 1.1rem;
      font-weight: 600;
      color: #0494fd;

      &.selected {
        background: #0494fd;
        color: #fff;
      }
    }
  }
  .title {
    font-weight: 600;
    font-size: 1rem;
    margin-bottom: 1rem;
  }
  .dataContainer {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    border-radius: 0.5rem;
    border: 0.5px solid #e7f0f5;
    background-color: #fff;

    > div {
      display: flex;
      padding: 1rem;
      gap: 1rem;
      align-items: center;

      .countSection {
        .number {
          font-size: 1.5rem;
          font-weight: 600;
          margin-bottom: 0.5rem;
        }
        .name {
          font-size: 0.875rem;
          font-weight: 400;
          color: #6a8294;
        }
      }
    }
  }
}
