@import '/src/assets/styles/variable';

.card {
  padding: 1rem;
  border-radius: 8px !important;

  .cardHeader {
    display: flex;
    align-items: end;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 1rem;
    gap: 1.2rem;

    .headerText {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      min-height: 3.5rem;
      .first {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;
        align-items: center;

        .badge {
          color: $blue;
        }

        .mainLabel {
          font-size: 30px;
          font-weight: 700;
        }
        .label {
          padding: 2px 12px;
          border-radius: 35px;
          background: #0494fd;
          color: #fff;
          font-size: 12px;
          max-width: 3rem;
          text-align: center;
        }
        .labelFre {
          padding: 2px 12px;
          border-radius: 35px;
          border: 1px solid #0494fd;
          color: #0494fd;
          font-size: 12px;
          max-width: 3rem;
          text-align: center;
          text-transform: capitalize;
        }
        .labelFreOp {
          border: 1px solid #fff;
          color: #fff;
        }
      }

      .second {
        font-size: 15px;
        font-weight: 400;
        margin-top: 1.2rem;
      }
    }

    .headerInfo {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      font-size: 12px;
      font-weight: 500;
      font-size: 1rem;

      &.free {
        align-self: center;
      }

      .headerInfoGray {
        color: $gray3;
        text-decoration: line-through;
        margin-right: 0.5rem;
      }
      .headerInfoDark {
        color: #455a64;
        font-weight: 600;
      }
      .headerInfoLight {
        color: $white;
        font-weight: 600;
      }
      .headerInfoBold {
        font-size: 2.5rem;
        font-weight: 600;
      }
    }
  }
  .cardActionButtons {
    margin-top: 1.5rem;
    padding: 1rem;
    border-top: 1px solid rgb(212, 212, 212);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 1rem;
    .optimized {
      font-size: 14px;
      color: #fff;
    }
  }
}
.cardOptimized {
  padding: 1rem;
  background-color: #003256 !important;
  color: #fff !important;
  border-radius: 8px !important;
}
