@import '/src/assets/styles/variable';
.titleWrapper {
  font-size: 12px;
  .activeTab {
    font-weight: 600;
    padding-bottom: 0.5rem;
    border-bottom: solid 2px #0494fd;
  }
}

.newRepTrainingHeader {
  display: flex;
  gap: 2rem;
  margin: 0 1.5rem 0 1.5rem;
  flex-wrap: wrap;

  .firstContainer {
    display: flex;
    flex-wrap: wrap;
    column-gap: 1.75rem;
    border-bottom: 1px solid #dbeaf4;
    margin-top: 1rem;
    margin-right: auto;

    .titleWrapper {
      width: fit-content;
      padding-bottom: 0.5rem;
      & div:first-child {
        display: flex;
        gap: 0.25rem;
      }
    }
    .tabs {
      width: fit-content;

      & :global(ul) {
        height: 100%;
        display: flex;
        align-items: end;
      }
      & :global(li) {
        display: flex;
        height: 100%;
      }
    }
  }
  .secondContainer {
    display: flex;
    flex-wrap: wrap;
    column-gap: 2rem;
    row-gap: 1rem;
    align-items: center;
    padding-right: 1rem;

    .readinessInfoWrapper {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      .value {
        color: #415766;
        font-size: 14px;
        font-weight: 600;
      }
    }
    .completedCountWrapper {
      display: flex;
      flex-direction: column;

      & :global(.progressBar) {
        margin-top: -4px;
      }
      & :global(.progressBar .progressValue) {
        display: none;
      }
    }
  }
}

.backButton {
  margin-right: -1rem;
  padding-right: 1rem;
}

.filter {
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  span:first-child {
    color: $gray5;
    margin-top: 15px;
    text-wrap: nowrap;
  }
}
