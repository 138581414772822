@import 'assets/styles/_variable.scss';

.card {
  background: #ffffff;
  border-radius: 0.25rem;
  padding-top: 2px;
  height: 100%;

  .header {
    margin: 0 2px;
    border-radius: 4px;
    background-color: #f3f5f899;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 42px;
    padding: 0 0.5rem 0 2px;
    position: relative;
    z-index: 2;

    .imageContainer {
      width: 36px;
      height: 36px;
      border-radius: 4px;
      background: #fff;
      display: grid;
      place-items: center;
    }

    .titleContainer {
      display: flex;
      align-items: center;
    }

    .title {
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      color: $gray1;
      margin-left: 0.875rem;
    }

    .viewMore {
      display: flex;
      gap: 0.5rem;
      color: #0494fd;
      font-size: 12px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0px;
      text-align: left;
      cursor: pointer;
      .icon {
        cursor: pointer;
        color: #0494fd;
        width: 18px;
        height: 18px;
        transform: rotate(180deg);
      }
    }
  }
}
