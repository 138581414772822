.recorderWrapper {
  width: 100%;
  max-width: 1200px;
  height: 75vh;
  margin: 0 auto;
}
.recorderWrapperRetake {
  height: calc(75vh - 2rem);
  margin-bottom: 2rem;
}

.videoContainer {
  width: 100%;
  height: 85%;
  background: #e8ebed;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.125rem;
  position: relative;
  @media screen and (max-width: 960px) {
    min-height: 65vh;
  }
}

.videoPlayer {
  width: 100%;
}

.continueButtons {
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
  gap: 1rem;
}
.resetButton {
  margin-right: auto;
  color: #0494fd;
  display: flex;
  align-items: center;
  gap: 9px;
  font-size: 14px;
  &:hover {
    svg {
      transform: rotate(-30deg);
    }
  }
  &:active {
    svg {
      transform: rotate(+30deg);
    }
  }
}

.continueButton {
  &:disabled {
    background: rgba(4, 148, 253, 0.5);
    text-transform: capitalize;
    color: #fff !important;
  }
}

.recordButtons {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
}

.counter {
  background: rgba(25, 52, 72, 0.7);
  color: #fff;
  font-size: 5rem;
  width: 25%;
  aspect-ratio: 1 / 1;
  max-width: 200px;
  max-height: 200px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  border-radius: 50%;
  display: grid;
  place-items: center;
}

.startButton {
  color: #fff;
  background: #ff623e;
  border-radius: 0.25rem;
  padding: 0 1.25rem;
  &:hover {
    background: #db4e2e;
  }
  &:active {
    background: #f25633;
  }
}

.listAttempts {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-bottom: 0.75rem;

  button {
    padding: 0 0 0.5rem 0;
    cursor: pointer;
    outline: none;
    background: none;
    color: #87a0b4;
    border-bottom: 3px solid transparent;
  }

  .selected {
    color: #000;
    border-bottom: 3px solid #0494fd;
  }
}
