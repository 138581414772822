.container {
  padding: 2rem 1.5rem;
}

.topCard {
  background: #fff;
  border-radius: 0.5rem;
  padding: 1rem;
  box-shadow: 0px 16px 18px 0px #0000000a;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 560px;

  * {
    flex-wrap: wrap;
  }

  .titleContainer {
    display: flex;
    align-items: baseline;
    gap: 0.5rem;

    .title {
      font-size: 1.5rem;
      color: #415766;
      font-weight: 600;
    }

    .authorContainer {
      color: #6a8294;
      font-size: 0.75rem;
      font-weight: 600;

      .author {
        text-transform: uppercase;
      }
    }
  }
  .description {
    font-size: 0.75rem;
    font-weight: 400;
    color: #415766;
  }

  .countStrong,
  .count {
    font-size: 0.625rem;
    font-weight: 600;
  }

  .count {
    color: #415766;
  }

  .progressBar {
    position: relative;
    width: 100%;
    background: #eeeeee;
    overflow: hidden;
    border-radius: 0.5rem;
    height: 10px;

    .completed {
      position: absolute;
      height: 100%;
      background: #2dc38d;
    }
    .progress {
      position: absolute;
      height: 100%;
      background: #0494fd;
    }
  }
  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;

    div {
      display: flex;
      gap: 0.75rem;
    }
  }
}

.chip {
  display: flex;
  padding: 6px 12px 6px 9px;
  gap: 6px !important;
  border-radius: 80px;
  background: #f9fafc;
  font-size: 0.75rem;
  color: #6a8294;
  align-items: center;

  strong {
    color: #000;
  }
}

.contentsContainer {
  .contents {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    cursor: pointer;
  }
}

.repStatusLabel {
  height: 1.5rem;
  font-size: 10px;
  border-radius: 1rem;
  display: flex;
  gap: 0.25rem;
  align-items: center;
  padding-left: 0.5rem;
  padding-right: 0.75rem;
  font-weight: 600;

  img{
    width: 16px;
    height: 16px;
  }

  &.inProgress {
    background: #eaf7ff;
    color: #0494fd;
  }
  &.completed {
    background: #e9f9f3;
    color: #2dc38d;
  }
}

.tabs {
  display: flex;
  flex-wrap: wrap;
  margin: 1rem 0;
  column-gap: 0.75rem;

  .tabItem {
    border-bottom: 2px solid transparent;
    display: grid;
    place-items: center;
    padding: 1rem;
    padding-bottom: 0.875rem;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: #6a8294;
  }

  .active {
    border-bottom: 3px solid #0494fd;
    color: #000;
  }
}
