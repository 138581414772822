@import 'variable';
body {
  .loaderBlk {
    min-height: 100vh;
    width: 100%;
    background-color: white;
    opacity: 0.6;
    position: absolute;
    z-index: 99;
    img,
    & :global(.MuiCircularProgress-root) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    svg {
      color: $blue;
    }
  }
  .signUpPage {
    .signUpSection {
      .signUpWrapper {
        .featuresWrapper {
          background: linear-gradient(180deg, #ecf7ff 0%, #ecf7ff 1.04%, #daefff 100%);
          min-height: 100vh;
          position: relative;
          .logo {
            position: absolute;
            left: 28px;
            top: 28px;
          }
          .featuresSliderOuterWrapper {
            position: absolute;
            top: 45%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 90%;
            .slideWrapper {
              text-align: center;
              .imgBlk {
                img {
                  height: 350px;
                  margin: 0 auto;
                }
              }
              .contentBlk {
                h2 {
                  margin-bottom: 8px;
                }
                p {
                  margin-bottom: 16px;
                }
              }
            }
          }
          .patternImg {
            position: absolute;
            bottom: 0;
            right: 0;
          }
        }
        .authWrapper {
          min-height: 100vh;
          background: $white;
          position: relative;
          .signInLink {
            position: absolute;
            right: 28px;
            top: 28px;
            color: $gray1;
          }
          .authInnerWrapper {
            width: 65%;
            margin: 0 auto;
            .formWrapper {
              width: 80%;
              margin: 0 auto;
              label {
                color: $gray3;
              }
              .agreeBlk {
                position: relative;
                margin-bottom: 24px;
                & :global(.MuiButtonBase-root) {
                  position: absolute;
                  left: 4px;
                  top: -2px;
                }
                & :global(.MuiTypography-root) {
                  display: block;
                  margin-left: 30px;
                }
                a {
                  font-size: $font12;
                }
              }
              & :global(.MuiTooltip-tooltip) {
                padding: 0;
                margin: 0;
              }
            }
          }
          .verifyContentWrapper {
            width: 70%;
            margin: 0 auto;
            text-align: center;
            img {
              margin-bottom: 24px;
            }
            h1 {
              margin-bottom: 16px;
              text-align: center;
            }
            h3 {
              margin-bottom: 48px;
              span {
                font-weight: $semiBoldFont;
                color: $gray2;
              }
            }
            .sendAgain {
              a {
                text-transform: none;
              }
            }
          }
        }
      }
    }
  }
  .signInPage {
    .signInSection {
      background-image: url($imageBasePath + 'singnin_background.png');
      min-height: 100vh;
      .signInWrapper {
        position: relative;
        .signInLink {
          position: absolute;
          right: 28px;
          top: 24px;
          color: $gray1;
        }
        .signInRow {
          min-height: 100vh;
        }
        .cardWrapper {
          // width: 30%;
          width: 394px;
          background: $white;
          margin: 48px auto;
          padding: 28px 28px 48px;
          text-align: center;
          .logo {
            display: block;
            margin: 0 auto 34px;
          }
          .signInText {
            text-align: center;
          }
          .emailVerified {
            display: block;
            margin: 0 auto 24px;
            height: 120px;
          }
          h1 {
            margin-bottom: 16px;
          }
          p {
            margin-bottom: 36px;
            span {
              font-weight: $semiBoldFont;
            }
            &.supportEmail {
              a {
                text-transform: initial;
              }
            }
          }
          label {
            color: $gray3;
            text-align: left;
          }
          h6 {
            text-align: left;
          }
          .passwordGroup {
            margin-bottom: 16px;
            & :global(.inputGroup) {
              margin-bottom: 16px;
            }
          }
          .rejectedImgBlk {
            position: relative;
            height: 100px;
            width: 100px;
            margin: 0 auto;
            .organizationLogo {
              border: 1px solid $gray7;
              border-radius: 50% !important;
            }
            .closeIcon {
              position: absolute;
              bottom: 1px;
              right: 1px;
              border: 1px solid $red;
              border-radius: 50%;
              color: $red;
              height: 20px;
              width: 20px;
            }
          }
          &.verifiedEmailWrapper {
            a {
              width: 240px;
            }
          }
          &.organizationCardWrapper {
            text-align: left;
            width: 520px;
            padding: 28px 58px;
            .logo {
              margin-bottom: 10px;
            }
            h1 {
              text-align: center;
              margin-bottom: 24px;
            }
            .inputFileGroup {
              position: relative;
              height: 64px;
              margin-bottom: 24px;
              .imgBlk {
                position: absolute;
                left: 0;
                top: 0;
                img {
                  height: 64px;
                  width: 64px;
                  object-fit: cover;
                  cursor: pointer;
                }
              }
              input[type='file'] {
                position: absolute;
                width: 60%;
                left: 0;
                height: 64px;
                opacity: 0;
                z-index: 99;
                cursor: pointer;
              }
              span {
                display: block;
                margin-left: 80px;
                position: relative;
                top: 24px;
                cursor: pointer;
                display: inline-block;
                z-index: 9;
              }
            }
          }
          &.organizationExistWrapper {
            .organizationLogo {
              height: 100px;
              width: 100px;
              object-fit: contain;
              border-radius: 50%;
              margin-bottom: 22px;
            }
            h1 {
              margin-bottom: 24px;
            }
            p {
              margin-bottom: 24px;
            }
            button {
              width: 324px;
            }
          }
          &.signInFormWrapper {
            width: 520px;
            .forgotpassword {
              font-size: $font12;
              font-weight: $regularFont;
              // float: right;
            }
          }
          &.chooseOrganizationWrapper {
            width: 454px;
            .userName {
              text-transform: capitalize;
            }
            .organizationList {
              margin-top: 60px;
              .organizationListItem {
                position: relative;
                padding: 8px 16px;
                border: 1px solid $gray7;
                border-radius: 4px;
                margin-bottom: 24px;
                min-height: 42px;
                cursor: pointer;
                @include transitionForAll($time: 0.4s, $transProp: ease-out);
                &:hover,
                &:focus,
                &:active {
                  // border: 1px solid $blue;
                  background: $background_lightblue;
                  @include transitionForAll($time: 0.3s, $transProp: ease-in);
                }
                .logoBlk {
                  position: absolute;
                  height: 44px;
                  width: 44px;
                  background: $gray7;
                  border-radius: 50%;
                  img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    max-height: 32px;
                    max-width: 32px;
                  }
                }
                .contentBlk {
                  margin-left: 60px;
                  margin-right: 20px;
                  text-align: left;
                  h2 {
                  }
                }
                .goArrow {
                  position: absolute;
                  right: 16px;
                  top: 50%;
                  transform: translateY(-50%);
                }
                &.createOrg {
                  margin-top: 32px;
                  .contentBlk {
                    h2 {
                      // font-weight: $regularFont;
                      padding-top: 10px;
                    }
                  }
                }
              }
            }
          }
          &.passwordRecoveryWrapper {
            a {
              width: 240px;
            }
          }
          &.passwordResetWrapper {
            .tooltipWrapper {
              text-align: left;
            }
            & :global(.MuiTooltip-tooltip) {
              padding: 0;
            }
            & :global(.MuiTooltip-popper) {
              transform: translate3d(204px, -58px, 0px) !important;
            }
          }
          &.passwordSuccesWrapper {
            p {
              margin-bottom: 0;
            }
          }
        }
        .backBtn {
          margin-top: 34px;
        }
      }
      .agreeBlk {
        position: relative;
        margin-bottom: 24px;
        & :global(.MuiButtonBase-root) {
          position: absolute;
          left: 4px;
          top: -2px;
        }
        & :global(.MuiTypography-root) {
          display: block;
          margin-left: 30px;
        }
        a {
          font-size: $font12;
        }
      }
    }
    &.invitePage {
      .agreeBlk {
        position: relative;
        margin-bottom: 40px;
        .agreeTitle {
          margin-bottom: 0 !important;
        }
        & :global(.MuiButtonBase-root) {
          position: absolute;
          left: 4px;
          top: -2px;
        }
        & :global(.MuiTypography-root) {
          display: block;
          margin-left: 30px;
        }
        a {
          font-size: $font12;
        }
      }
      & :global(.MuiTooltip-tooltip) {
        padding: 0;
        margin: 0;
      }
    }
  }
  .socialLoginWrapper {
    text-align: center;
    h1 {
      margin-bottom: 16px;
    }
    ul {
      display: block;
      text-align: center;
      margin-bottom: 40px;
      li {
        padding: 8px;
        a {
          border: 1px solid $gray6;
          border-radius: 4px;
          padding: 12px 16px;
          min-width: 166px;
          margin: 0 auto;
          color: $gray2;
          img {
            vertical-align: middle;
            margin-right: 8px;
            position: relative;
            top: -2px;
          }
        }
      }
    }
  }
  .optionHeading {
    position: relative;
    z-index: 1;
    overflow: hidden;
    text-align: center;
    margin-bottom: 24px;
    &:before,
    &:after {
      position: absolute;
      top: 51%;
      overflow: hidden;
      width: 50%;
      height: 1px;
      content: '\a0';
      background-color: $gray7;
    }
    &:before {
      margin-left: -50%;
      text-align: right;
    }
    span {
      padding: 0 8px;
      color: $gray4;
    }
  }
  .disabledEmailBlock {
    p {
      text-align: left;
      border: 1px solid $gray7;
      border-radius: 4px;
      padding: 10px 16px;
      box-sizing: border-box;
      margin-bottom: 0 !important;
      background: #e7f0f5;
    }
  }
  .tooltipWrapper {
    background: $gray2;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    padding: 16px 20px 16px 16px;
    position: relative;
    p {
      font-weight: $semiBoldFont;
      color: $blue6;
      margin-bottom: 4px !important;
      font-size: $font14;
    }
    ul {
      text-align: left;
      li {
        padding: 4px 0;
        img {
          width: 12px;
          vertical-align: middle;
          margin-right: 5px;
        }
        span {
          color: $blue6;
          font-size: $font14;
          b {
            font-weight: $semiBoldFont;
            color: $blue6;
            font-size: $font14;
            padding-right: 4px;
            position: relative;
            top: 1px;
          }
        }
        &:last-child {
          padding-bottom: 0;
        }
      }
    }
    &:after,
    &:before {
      bottom: auto;
      top: 48%;
      left: -10px;
      border: solid transparent;
      content: ' ';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      transform: rotate(270deg);
    }
    &:after {
      border-color: rgba(255, 255, 255, 0);
      border-bottom-color: $gray2;
      border-width: 10px;
      margin-left: -10px;
    }
    &:before {
      border-color: rgba(196, 196, 196, 0);
      border-bottom-color: $gray2;
      border-width: 10px;
      margin-left: -10px;
    }
  }
}

//mobile portrait view //
@media (min-width: 0px) and (max-width: 599px) {
  body {
  }
}

@media (min-width: 600px) and (max-width: 959px) {
  body {
  }
}

@media (min-width: 960px) and (max-width: 1279px) {
  body {
  }
}

@media (min-width: 1280px) {
  body {
  }
}
