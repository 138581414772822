@import 'assets/styles/_variable.scss';

.oAuthContainer {
  border: 1px solid rgba(0, 0, 0, 0.12);
  margin: 1rem;
  padding: 1rem;

  .extraInfo {
    .infoBlock {
      margin: 2rem 0;
      line-height: 1.5rem;

      h3 {
        color: #193448;
        margin-bottom: 0.5rem;
      }
    }
  }
}
