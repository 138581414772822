.background {
  background: #ffffff;
  border-radius: 8px;
  padding: 24px;
  display: flex;
  min-height: 80vh;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
}

.descriptionContainer {
  display: flex;
  justify-content: start;
}

.descriptionBody {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  margin-bottom: 1rem;
}

.headerSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  & :global(.inputGroup) {
    margin-bottom: 0;
  }
}

.descriptionBodyTitle {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.link {
  color: #0494fd;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}

.headerTitle {
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
  justify-content: space-between;
  & :global(.inputGroup) {
    margin-bottom: 0;
  }
}

.listAttempts {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-top: 0.5rem;
  p {
    padding: 0 0 0.5rem 0;
    cursor: pointer;
  }
  .selected {
    color: #000;
    border-bottom: 3px solid #0494fd;
  }
}

.descriptionRating {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: column;
  height: 100%;
  margin-top: auto;
}
