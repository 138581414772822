@import 'assets/styles/_variable.scss';

.progressGraphContainer {
  background: #ffffff;
  border-radius: 0.25rem;
  padding: 1rem;
  margin: -0.5rem 0;

  & h1:first-child {
    color: $gray3;
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  & h6:first-child {
    color: $gray2;
  }

  .totalContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%) scale(0.9);
    text-align: center;
  }

  .legendsContainer {
    width: fit-content;
    margin: 1rem auto;
    display: flex;
    flex-direction: column;
    gap: 9px;

    .legend {
      display: flex;
      column-gap: 0.75rem;
      align-items: center;
      color: $gray2;

      & span {
        text-wrap: nowrap;
      }

      & strong {
        margin-left: 0.5rem;
      }

      .dot {
        background: #ffffff;
        border-radius: 50%;
        width: 12px;
        height: 12px;
        box-sizing: border-box;
      }
    }
  }
}
