@import 'assets/styles/_variable.scss';

.streamBanner {
  padding: 16px 24px;
  background: url($imageBasePath + 'home_intoduction_bg.svg') no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 168px;
  position: relative;
  justify-content: center;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;

  h3 {
    font-weight: 600;
    margin-bottom: 6px;
    font-size: 1.5rem;
  }
  p {
    font-weight: 400;
    color: $gray2;
  }
}
