.dialog {
    :global(.MuiDialog-paper) {
      max-width: 1090px;
      width: 45rem;
      min-height: 70vh;
    }
    .customTitle {
      h3 {
        img,
        span {
          vertical-align: middle;
        }
        img {
          margin-left: 8px;
          margin-top: 2px;
        }
      }
    }
    .dialogBody {
      .leftContent,
      .rightContent {
        padding: 24px;
        width: 100%;
      }
      .rightContent {
        border-left: 1px solid #f3f5f8;
        h2 {
          margin-bottom: 16px;
        }
        .userPreference {
          color: #f3f5f8;
          margin-bottom: 8px;
        }
        .radioBtnList {
          // padding: 0;
          li {
            padding: 0 8px;
            &:first-child {
              padding-left: 0;
            }
          }
        }
      }
      .leftContent {
        padding-top: 16px;
        :global(.dzu-dropzone) {
          margin: 20px 0 0;
        }
        :global(.dzu-inputLabel) {
          position: relative;
        }
      }
    }
    :globla(.tertiaryBtn),
    :global(.primaryBtn) {
      padding: 10px 16px;
    }
    :global(.primaryBtn) {
      margin-left: 20px;
    }
  }
  
  .deadlineTitle {
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    font-weight: 400;
    color: #193448;
    line-height: 18px;
    transform: none;
    position: relative;
    margin-bottom: 6px;
    display: block;
  }
  