@import 'assets/styles/_variable.scss';

.dialog {
  :global(.MuiDialog-paper) {
    max-width: 1090px;
    width: 1090px;
    min-height: 70vh;
  }
  .customTitle {
    h3 {
      img,
      span {
        vertical-align: middle;
      }
      img {
        margin-left: 8px;
        margin-top: 2px;
      }
    }
  }
  .dialogBody {
    .leftContent,
    .rightContent {
      padding: 24px;
      width: 100%;
      & :global(.customLinkPulse) {
        font-size: 12px;
        color: #0494fd;
        font-weight: 600;
        display: block;
        margin-top: 0.5rem;
      }
    }

    .rightContent {
      border-left: 1px solid #f3f5f8;
      h2 {
        margin-bottom: 16px;
      }
      .userPreference {
        color: #f3f5f8;
        margin-bottom: 8px;
      }
      .radioBtnList {
        // padding: 0;
        li {
          padding: 0 8px;
          &:first-child {
            padding-left: 0;
          }
        }
      }
    }
    .leftContent {
      padding-top: 16px;
      :global(.dzu-dropzone) {
        margin: 20px 0 0;
      }
      :global(.dzu-inputLabel) {
        position: relative;
      }
    }
  }
  :globla(.tertiaryBtn),
  :global(.primaryBtn) {
    padding: 10px 16px;
  }
  :global(.primaryBtn) {
    margin-left: 20px;
  }
}

.noteBlock {
  position: relative;
  background: #e9f6ff;
  border-radius: 2px;
  padding: 10px 25px;
  margin-bottom: 20px;
  img {
    position: absolute;
    left: 8px;
    top: 10px;
  }
}

.deadlineTitle {
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #193448;
  line-height: 18px;
  transform: none;
  position: relative;
  margin-bottom: 6px;
  display: block;
}

.closeImage {
  :hover {
    cursor: pointer;
  }
}

//User With Roleplay
.usersWithRoleplayContainer {
  font-size: 12px;
  font-weight: 400;
  color: #6a8294;
  .userWRText {
    margin-bottom: 0.25rem;
  }
  .userWRList {
    margin-left: 1rem;
  }
}

.userWRTextBottom {
  font-size: 14px;
  font-weight: 400;
  color: #6a8294;
}

.selectAssignWrapper {
  & :global(.inputGroup) {
    margin-bottom: 0.5rem;
  }
}

.selectorContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: center;
  gap: 0.75rem;

  button {
    text-decoration: underline;
    font-size: 14px;
    font-weight: 500;
    color: $blue;
  }
}

.customRoleplayOpt {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  column-gap: 0.5rem;

  span {
    font-size: 10px;
    font-weight: 400;
    color: $blue;
    background: #ECEFF1;
    border-radius: 4px;
    padding: 0 4px;
  }
}
