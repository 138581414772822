@import 'assets/styles/_variable.scss';

.post {
  margin: 2rem 0;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  gap: 0.75rem;
  border-radius: 0.5rem;
}
