@import '/src/assets/styles/variable';

.title {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  span:first-child {
    margin-bottom: 22px;
    color: $gray5;
  }
}
