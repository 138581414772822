.homeCardContainer {
  background-color: #ffffff;
  border-radius: 4px;
  padding-top: 2px;
  .homeCardHeader {
    margin: 0 2px;
    border-radius: 4px;
    background-color: #f3f5f899;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 42px;
    padding: 0 0.5rem 0 2px;

    .imageContainer {
      width: 36px;
      height: 36px;
      border-radius: 4px;
      background: #fff;
      display: grid;
      place-items: center;
    }

    .title {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      h1 {
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 0px;
      }
    }

    .homeLink {
      display: flex;
      gap: 0.5rem;
      color: #0494fd;
      .icon {
        cursor: pointer;
        color: #0494fd;
        width: 18px;
        height: 18px;
        transform: rotate(180deg);
      }
      a {
        color: #0494fd;
        font-size: 12px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: left;
      }
    }
  }
  .homeCardContent {
    display: grid;
    justify-content: center;
    justify-items: stretch;
    padding: 2rem;
    min-height: 10rem;
    align-items: center;
    gap: 1rem;
    .homeChart {
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding-bottom: 1rem;
      gap: 4rem;
    }
    .totalPosts {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      span {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }
      strong {
        font-size: 24px;
        font-weight: 600;
        line-height: 33px;
        text-align: left;
      }
    }
    
    .oneColumn {
      margin-top: 1rem;
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 2;
      grid-row-end: 3;
      .sixColumn {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 1rem;
      }
    }
  }

  .homeCardContentAdmin {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 10rem;
    padding: 3.5rem 0rem 3.5rem 1rem;
    flex-wrap: wrap-reverse;
    gap: 1.5rem;
    .totalPosts {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      span {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }
      strong {
        font-size: 24px;
        font-weight: 600;
        line-height: 33px;
        text-align: left;
      }
    }

    .ChartWrapperAdmin {
      grid-column-start: 4;
      grid-column-end: 5;
      canvas {
        width: 250px;
        margin-right: -100px;
      }
    }
  }
}

.contentLabel {
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
  gap: 1rem;
  flex-wrap: nowrap;
  & span {
    text-wrap: nowrap;
  }
  & img {
    min-width: 14px;
  }
}
