@import 'variable';
@mixin hubspotContainer {
  width: 36px;
  border-radius: 50%;
  background: #f4e9e8;
  height: 36px;
  display: grid;
  place-items: center;
  img {
    width: 70%;
  }
}
body {
  :global(.MuiTooltip-tooltip) {
    max-width: 245px;
  }
  .checkinTooltipContent {
    background: #ecf7ff;
    border-radius: 4px;
    padding: 8px;
    font-weight: $regularFont;
    span {
      font-weight: $semiBoldFont;
    }
  }
  .trackingPage {
    .adminLandingOverviewShimmer {
      margin-bottom: 30px;
      .listInlineItem {
        width: 25%;
      }
    }
    .TrackingOverviewShimmer {
      background: $white;
      border-radius: 4px;
      margin-bottom: 70px;
      .leftBlkShimmer {
        border-right: 1px solid #e7f0f5;
        .titleBlkShimmer {
          background: $blue6;
          margin: 0 8px 8px;
          width: 100%;
          border-radius: 4px;
          position: relative;
        }
      }
      .TrackingOverviewShimmerList {
        display: inline-block;
        width: 25%;
        text-align: center;
      }
    }
    .TrackingSalesOverviewShimmer {
      margin-bottom: 16px;
      .titleBlk {
        background: $background_green;
        width: 100%;
        border-radius: 4px;
        position: relative;
        min-height: 36px;
        padding: 16px;
        margin-right: 12px;
      }
      .accordionTitleBlk {
        background: $white;
        border-radius: 4px;
        padding: 8px;
      }
      .accordionBody {
        background: $white;
        border-radius: 4px;
        padding: 20px;
      }
    }
    .trackingOuterWrapper {
      padding: 24px;
      .trackingIntroSection {
        background: $background_yellow;
        padding: 16px 24px;
        position: relative;
        margin: -24px -24px 24px;
        .titleWrapper {
          position: relative;
          .imgBlk {
            position: absolute;
            left: 0;
            top: 0;
            height: 50px;
            width: 50px;
            border-radius: 50%;
            background: $white;
            img {
              position: relative;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              max-height: 40px;
              max-width: 40px;
            }
          }
          .contentBlk {
            margin-left: 74px;
            h1 {
              margin-bottom: 4px;
            }
            p {
              color: $gray3;
            }
          }
        }
        .stepsWrapper {
          padding-right: 32px;
          .progressBlk {
            .stepProgress {
              // width: 50%;
              width: 200px;
            }
            & :global(.progressBar .progressValue) {
              // display: none;
            }
            & :global(.progressBar progress) {
              background-color: $white;
            }
          }
          .stepsBlk {
            text-align: left;
            padding-left: 24px;
            h2 {
              .steps {
                position: relative;
                top: 2px;
                color: $gray2;
              }
              .supportText {
                color: $gray3;
              }
            }
          }
        }
        .patternBg {
          position: absolute;
          right: 0;
          top: 0;
          height: 100%;
        }
      }
      .trackingSetupSection {
        .setupCardWrapper {
          background: $white;
          border-radius: 4px;
          padding: 8px;
          margin-bottom: 30px;
          .trackingSetupRow {
            .setupCard {
              background: $blue4;
              border-radius: 4px;
              padding: 48px 24px 64px;
              position: relative;
              min-height: 348px;
              box-sizing: border-box;
              width: 100%;
              .featuredImg {
                height: 64px;
                margin-bottom: 16px;
              }
              h2 {
                margin-bottom: 8px;
              }
              .patternImg {
                position: absolute;
                bottom: 0;
                right: 0;
              }
              &.salesCard {
                background: $background_green;
                .patternImg {
                  height: 620px;
                  bottom: -12px;
                }
              }
            }
            .cardsListWrapper {
              padding: 24px;
            }
            .btnRow {
              margin-top: 8px;
            }
          }
        }
      }
    }
    .goalCard {
      .cardWrapper {
        border: 1px solid #f3f5f8;
        border-radius: 4px;
        padding: 5px;
        text-align: center;
        background: $white;

        .topBlk {
          background: $background_grey;
          border-radius: 4px;
          padding: 16px 16px 16px;
          margin-bottom: 8px;
          .hubspot {
            @include hubspotContainer();
            margin: 0px auto;
            margin-bottom: 12px;
          }
          img {
            height: 36px;
            margin-bottom: 8px;
          }
          h3 {
          }
          &.backgroundBlue {
            background: $blue6;
          }
          &.backgroundGreen {
            background: $background_green;
          }
        }
        .bottomBlk {
          padding: 16px;
          h4 {
            font-size: $font12;
            // color: $gray6;
            .goalIcon {
              margin-right: 5px;
              vertical-align: middle;
              position: relative;
              top: -1px;
            }
          }
          h5 {
            // margin-bottom: 16px;
            color: $gray3;
          }
          .tag {
            color: $gray3;
            margin-top: 16px;
            span {
              background: $background_grey;
              border-radius: 4px;
              padding: 2px 12px 3px;
              font-size: $font10;
              text-transform: uppercase;
              font-weight: $semiBoldFont;
            }
          }
        }
      }
    }
    .goalListingSection {
      .goalContentWrapper {
        .titleRow {
          margin-bottom: 32px;
          .titleWrapper {
            position: relative;
            .featuredImg {
              position: absolute;
              left: 0;
              top: 0;
              width: 48px;
            }
            .info {
              margin-left: 62px;
              h2 {
                margin-bottom: 4px;
                span {
                  color: $gray2;
                  font-weight: $semiBoldFont;
                  background: $gray6;
                  border-radius: 4px;
                  padding: 1px 8px 2px;
                  text-transform: uppercase;
                  margin-left: 16px;
                  position: relative;
                  top: -1px;
                  // display: inline-block;
                }
              }
            }
          }
        }
        .contentRow {
          margin-bottom: 64px;
          .goalCard {
            .cardWrapper {
              .bottomBlk {
                h5 {
                  color: $gray1;
                  margin-top: 4px;
                }
              }
            }
          }
        }
        &:last-child {
          .contentRow {
            margin-bottom: 40px;
          }
        }
      }
    }
    .setupCompleteSection {
      margin-bottom: 40px;
      .setUpwrapper {
        // padding: 24px 0;
        min-height: 115px;
        background-color: $background_green;
        .titleWrapper {
          position: relative;
          .imgBlk {
            position: absolute;
            left: 32px;
            top: -4px;
            img {
              height: 80px;
            }
          }
          .contentBlk {
            margin-left: 140px;
            h1 {
              color: $green;
              margin-bottom: 6px;
            }
            p {
              color: $gray2;
              font-weight: $regularFont;
              span {
                font-weight: $semiBoldFont;
              }
            }
          }
        }
        .publishBtn {
          margin-right: 36px;
        }
      }
      &.userSetupCompleteSection {
        .setUpwrapper {
          padding: 20px 0 16px;
          min-height: auto;
          background-color: $gray2;
          border-radius: 4px;
          .titleWrapper {
            min-height: 80px;
            position: relative;
            .imageBlk {
              img {
              }
            }
            .contentBlk {
              min-height: 80px;
              position: relative;
              background-size: contain;
              background-position: right -10px;
              h1 {
                position: absolute;
                top: 49%;
                transform: translateY(-50%);
                color: $white;
                margin-bottom: 0;
                width: 85%;
              }
              .closeIcon {
                position: absolute;
                right: 32px;
                top: 49%;
                transform: translateY(-50%);
                cursor: pointer;
              }
              &.successContentBlk {
              }
            }
          }
        }
      }
    }
    .dashboard {
      :global(.headerSection .pageTitleBlock) {
        h1 {
          img {
            position: relative;
            top: 4px;
          }
        }
      }
      .dashboardContentWrapper {
        padding: 24px 0;
      }
    }
    .overViewSection {
      .overviewWrapper {
        .downloadRow {
          margin-bottom: 8px;
        }
        .contentOverviewWrapper {
          .titleRow {
            margin-bottom: 32px;
            .titleWrapper {
              position: relative;
              .featuredImg {
                position: absolute;
                left: 0;
                top: 0;
                width: 48px;
              }
              .info {
                margin-left: 62px;
                h2 {
                  margin-bottom: 4px;
                  span {
                    color: $gray2;
                    font-weight: $semiBoldFont;
                    background: $gray6;
                    border-radius: 4px;
                    padding: 1px 8px 2px;
                    text-transform: uppercase;
                    margin-left: 16px;
                    position: relative;
                    top: -1px;
                    // display: inline-block;
                  }
                }
              }
            }
            .filterBlk {
              min-width: 150px;
              float: right;
              & :global(.inputGroup .reactSelect > div) {
                height: 36px;
                min-height: 36px;
              }
              & :global(.inputGroup .reactSelect input) {
                height: 20px;
              }
              & :global(.inputGroup) {
                margin-bottom: 0;
              }
            }
          }
          .noMargin{
            margin-bottom: 0;
          }
          .viewContentWrapper {
            .tableTitleRow {
              span {
                font-size: $font12;
                font-weight: $semiBoldFont;
                text-transform: uppercase;
              }
              ul {
                li {
                  padding: 8px 0;
                }
              }
            }
            .count {
              margin-right: 8px;
              display: inline-block;
              position: relative;
              &.rise {
                span {
                  color: $green;
                }
              }
              &.fall {
                span {
                  color: $red;
                }
              }
            }
          }
          // .orgViewWrapperOld {
          //   background: $white;
          //   border-radius: 4px;
          //   margin-bottom: 70px;
          //   ul {
          //     border-bottom: 1px solid $gray7;
          //     // overflow-x: auto;
          //     // white-space: nowrap;
          //     text-align: left;
          //     width: 100%;
          //     li {
          //       width: 25%;
          //       text-align: center;
          //     }
          //   }
          //   .tableTitleRow {
          //     .leftBlk {
          //       border-right: 1px solid $gray7;
          //       span {
          //         padding: 19px 32px 0;
          //         display: inline-block;
          //       }
          //     }
          //     .rightBlk {
          //       ul {
          //         li {
          //           &.active {
          //             span {
          //               color: $blue;
          //               background: $blue4;
          //               display: inline-block;
          //               border-radius: 2px;
          //               padding: 4px 12px;
          //             }
          //           }
          //         }
          //       }
          //     }
          //   }
          //   .cardItem {
          //     .contentRow {
          //       .leftBlk {
          //         border-right: 1px solid $gray7;
          //         .titleBlk {
          //           background: $blue6;
          //           margin: 0 8px 8px;
          //           width: 100%;
          //           border-radius: 4px;
          //           position: relative;
          //           h3 {
          //             position: absolute;
          //             left: 50%;
          //             top: 50%;
          //             transform: translate(-50%, -50%);
          //             width: 90%;
          //             min-height: 36px;
          //             color: $gray2;
          //             img {
          //               position: absolute;
          //               left: 0;
          //               top: 0;
          //               margin-right: 8px;
          //               vertical-align: middle;
          //               height: 36px;
          //             }
          //             span {
          //               display: block;
          //               margin-left: 50px;
          //               position: relative;
          //               top: 5px;
          //             }
          //           }
          //         }
          //       }
          //       .rightBlk {
          //         h4 {
          //           margin-bottom: 8px;
          //           .count {
          //             img {
          //               position: absolute;
          //               top: -1px;
          //               left: 50%;
          //               transform: translateY(-50%);
          //             }
          //           }
          //         }
          //         .customTarget {
          //           span {
          //             font-size: $font14;
          //             color: $gray3;
          //           }
          //         }
          //       }
          //     }
          //     &:last-child {
          //       .contentRow {
          //         .rightBlk {
          //           ul {
          //             border-bottom: none;
          //           }
          //         }
          //       }
          //     }
          //   }
          // }
          .orgViewWrapper {
            // background: $white;
            border-radius: 4px;
            margin-bottom: 70px;

            .tableTitleRow {
              .leftBlk {
                border-right: 1px solid $gray7;
                background-color: $white;
                span {
                  padding: 19px 32px 16px;
                  display: inline-block;
                }
                .titleBlk {
                  background: $blue6;
                  margin: 0 8px 8px;
                  // width: 100%;
                  border-radius: 4px;
                  position: relative;
                  min-height: 100px;
                  box-sizing: border-box;
                  h3 {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    width: 90%;
                    min-height: 36px;
                    color: $gray2;
                    img {
                      position: absolute;
                      left: 0;
                      top: 0;
                      margin-right: 8px;
                      vertical-align: middle;
                      height: 36px;
                    }
                    span {
                      display: block;
                      margin-left: 50px;
                      position: relative;
                      top: 7px;
                      padding: 0;
                    }
                  }
                }
              }
              .rightBlk {
                ul {
                  // border-bottom: 1px solid $gray7;
                  overflow-x: auto;
                  white-space: nowrap;
                  text-align: left;
                  width: 100%;
                  padding: 0;
                  height: 100%;
                  vertical-align: top;
                  li {
                    width: 25%;
                    text-align: center;
                    padding: 0;
                    height: 100%;
                    vertical-align: top;
                    background-color: $white;
                    .monthTitle {
                      padding: 12px 0;
                      border-bottom: 1px solid $gray7;
                      &.active {
                        padding: 9.5px 0;
                        span {
                          color: $blue;
                          background: $blue4;
                          display: inline-block;
                          border-radius: 2px;
                          padding: 4px 8px;
                          font-size: 11px;
                        }
                      }
                    }
                    .content {
                      border-bottom: 1px solid $gray7;
                      min-height: 68px;
                      padding: 18px 0;
                      h4 {
                        margin-bottom: 8px;
                        .count {
                          img {
                            position: absolute;
                            top: -1px;
                            left: 50%;
                            transform: translateY(-50%);
                          }
                        }
                      }
                      .customTarget {
                        span {
                          font-size: $font14;
                          color: $gray3;
                        }
                      }
                      &:last-child {
                        border-bottom: none;
                      }
                    }
                  }
                }
              }
            }
            .cardItem {
              .contentRow {
                .leftBlk {
                  border-right: 1px solid $gray7;
                }
                .rightBlk {
                }
              }
              &:last-child {
                .contentRow {
                  .rightBlk {
                    ul {
                      border-bottom: none;
                    }
                  }
                }
              }
            }
          }
          .saleViewWrapper {
            .tableTitleRow {
              margin-bottom: 12px;
              padding: 0 16px;
              // text-align: center;
              .achivement {
                padding-left: 44px;
              }
            }
            .salesCardItem {
              margin-bottom: 24px;
              .accordionHeader {
                .accordionTitleBlk {
                  background: $white;
                  border-radius: 4px;
                  padding: 8px;
                  .contentRow {
                    .titleBlk {
                      .hubspot {
                        @include hubspotContainer();
                      }
                      background: $background_green;
                      // margin: 8px 8px;
                      width: 100%;
                      border-radius: 4px;
                      position: relative;
                      min-height: 36px;
                      padding: 16px;
                      margin-right: 12px;
                      h3 {
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        width: 90%;
                        min-height: 36px;
                        color: $gray2;
                        img {
                          position: absolute;
                          left: 0;
                          top: 0;
                          margin-right: 8px;
                          vertical-align: middle;
                          height: 36px;
                        }
                        span {
                          display: block;
                          margin-left: 50px;
                          position: relative;
                          top: 5px;
                        }
                      }
                    }
                    .rightBlk {
                      position: relative;
                      .targetList {
                        padding: 0 0 0 40px;
                        li {
                          padding: 0 6px;
                          &:first-child {
                            // padding-left: 0;
                          }
                        }
                      }
                      .salesProgressBar {
                        &.danger {
                          color: $red;
                          &::-webkit-progress-value {
                            background-color: $red !important;
                          }
                        }
                      }
                      .middleBlk {
                        & :global(.progressValue) {
                          font-weight: $semiBoldFont;
                          color: $gray2;
                          padding-left: 8px;
                        }
                      }
                      .profileList {
                        padding: 0;
                        li {
                          padding: 0;
                          .profilePic {
                            height: 28px;
                            width: 28px;
                            border-radius: 50%;
                            object-fit: cover;
                            &.avatar {
                              position: relative;
                              top: -2px;
                            }
                          }
                        }
                      }
                      .caret {
                        position: absolute;
                        right: 16px;
                        top: 50%;
                        transform: translateY(-50%);
                        cursor: pointer;
                        &.opened {
                          transform: rotate(180deg);
                        }
                      }
                    }
                  }
                }
              }
              .accordionBody {
                .bodyWrapper {
                  background: $white;
                  border-radius: 4px;
                  margin-top: 1px;
                  padding: 16px;
                  .graphBlk {
                    max-height: 200px;
                    canvas {
                      // height: 100% !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .performersWrapper {
        .salesPerformersWrapper {
          background: $white;
          border-radius: 4px;
          width: 100%;
          .topBlk {
            padding: 16px;
            h4 {
              color: $gray1;
            }
            .filterBlk {
              width: 75%;
              float: right;
              & :global(.inputGroup .reactSelect > div) {
                height: 32px;
                min-height: 32px;
              }
              & :global(.inputGroup) {
                margin-bottom: 0;
              }
            }
          }
          .titleBlk {
            padding: 0px 16px 16px;
            border-bottom: 1px solid $gray7;
            span {
              text-transform: uppercase;
              font-size: $font12;
            }
            .total {
              position: relative;
              left: 20%;
            }
          }
          .bottomBlk {
            padding: 16px;
            .performersItem {
              margin-bottom: 20px;
              .profileWrapper {
                position: relative;
                .imgBlk {
                  position: absolute;
                  top: 0;
                  left: 0;
                  .profilePic {
                    height: 28px;
                    width: 28px;
                    border-radius: 50%;
                    object-fit: cover;
                  }
                }
                .contentBlk {
                  margin-left: 38px;
                  h5 {
                    // text-transform: capitalize;
                    color: $gray1;
                  }
                  h6 {
                    color: $gray4;
                    font-size: $font10;
                  }
                }
              }
              .progressBlk {
                text-align: right;
                progress {
                  width: 50%;
                  &.danger {
                    color: $red;
                    &::-webkit-progress-value {
                      background-color: $red !important;
                    }
                  }
                }
                & :global(.progressValue) {
                  font-weight: $semiBoldFont;
                  color: $gray2;
                  padding-left: 8px;
                }
              }
              &:last-child {
                margin-bottom: 0px;
              }
            }
          }
        }
      }
    }
    .teamActivityWrapper {
      background: $white;
      border-radius: 4px;
      width: 100%;
      .topBlk {
        padding: 16px;
        h4 {
          color: $gray1;
        }
        .filterBlk {
          width: 75%;
          float: right;
          & :global(.inputGroup .reactSelect > div) {
            height: 32px;
            min-height: 32px;
          }
          & :global(.inputGroup) {
            margin-bottom: 0;
          }
        }
      }
      .contentBlk {
        .monthSelect {
          display: grid;
          align-items: start;
          padding: 0 20px;
          .month {
            grid-column: 1/2;
            grid-row: 1;
            color: $gray3;
          }
          .buttons {
            grid-column: 2;
            grid-row: 1;
            text-align: end;
            .buttonsWrapper {
              .arrowIcon {
                cursor: pointer;
                fill: $blue;
                font-size: $font18;
                padding: 4px 3px 4px 5px;
                // box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
                box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
                border-radius: 4px;
                &.prev {
                  margin-right: 12px;
                  padding: 4px 1px 4px 7px;
                }
              }
            }
          }
          .monthUserGraph {
            grid-column: 1/3;
            grid-row: 2;
            padding-top: 16px;
            // max-width: 260px;
            max-width: 330px;
            margin: 0 auto;
            &.checkinMonthGraph {
              max-width: 250px;
            }
            .monthItems {
              .monthItem {
                padding-bottom: 0px;
                padding-right: 6px;
                .databoxWrapper {
                  width: 41px;
                  height: 41px;
                  // width: 31px;
                  // height: 31px;
                  cursor: pointer;
                  .activeCheckin,
                  .active {
                    display: none;
                  }
                  img {
                    position: absolute;
                    top: 9px;
                    left: 8.5px;
                  }
                  &.checkinDataBoxWrapper {
                    width: 29px;
                    height: 29px;
                  }
                }
                &.hide {
                  opacity: 0;
                  pointer-events: none;
                }
                &.activeCheckinItem,
                &.active {
                  padding-right: 3px;
                  margin-left: -3px;
                  position: relative;
                  top: 2px;
                  margin-top: -6px;
                  .checkinDataBoxWrapper {
                    border: 3px solid $white;
                    background: $green !important;
                    border-radius: 4px;
                    // width: 28px;
                    // height: 24px;
                    box-shadow: 0px 1px 8px 5px rgba(203, 209, 217, 0.63);
                    .activeCheckin {
                      display: inline;
                    }
                    .active {
                      display: none;
                    }
                  }
                }
                &.today {
                  position: relative;
                  .databoxWrapper {
                    // background: $green !important;
                    .activeCheckin {
                      display: inline;
                      width: 25px;
                    }
                  }
                }
                &.active {
                  .checkinDataBoxWrapper {
                    background: rgba(1, 22, 39, 0.1) !important;
                    .activeCheckin {
                      display: none;
                    }
                    .active {
                      display: inline;
                    }
                  }
                }
              }
            }
          }
          .listColorValues {
            padding-top: 16px;
            padding-bottom: 16px;
            grid-column: 1/3;
            grid-row: 3;
            margin: 0 auto;
            ul {
              li {
                cursor: pointer;
                margin-right: 6px;
                .squareBox {
                  display: inline-block;
                  width: 35px;
                  height: 35px;
                }
                p {
                  margin: 0px 4px;
                  transform: translateY(-5px);
                }
              }
            }
            &.checkin {
              margin: 0;
              ul {
                li {
                  .squareBox {
                    width: 25px;
                    height: 25px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .teamOuterWrapper {
      .teamSection {
        .teamContainer {
          .salesTeamMembers {
            background: rgba(255, 255, 255, 0.4);
            border-radius: 4px;
            // height: calc(100vh - 150px);
            overflow: hidden;
            position: sticky;
            top: 80px;
            .topBlk {
              padding: 16px 20px;
              h4 {
                color: $gray1;
                margin-bottom: 16px;
              }
              .searchBlk {
                & :global(.inputGroup) {
                  margin-bottom: 0;
                }
              }
            }
            .bottomBlk {
              padding-bottom: 8px;
              max-height: calc(100vh - 285px);
              overflow: auto;
              .membersList {
                .memberItem {
                  margin-bottom: 8px;
                  padding: 8px 16px 8px;
                  border-left: 4px solid transparent;
                  @include transitionForAll($time: 0.4s, $transProp: ease-out);
                  .profileWrapper {
                    position: relative;
                    .imgBlk {
                      position: absolute;
                      top: 2px;
                      left: 0;
                      .profilePic {
                        height: 32px;
                        width: 32px;
                        border-radius: 50%;
                        object-fit: cover;
                      }
                    }
                    .contentBlk {
                      margin-left: 42px;
                      h5 {
                        color: $gray1;
                        margin-bottom: 2px;
                        // text-transform: capitalize;
                      }
                      h6 {
                        color: $gray4;
                        font-size: $font10;
                      }
                    }
                  }
                  cursor: pointer;
                  &.active {
                    border-left: 4px solid $blue;
                    background: $white;
                  }
                  &:hover,
                  &:focus,
                  &:active {
                    @include transitionForAll($time: 0.3s, $transProp: ease-in);
                    // border-left: 4px solid $blue;
                    background: $white;
                  }
                }
              }
            }
          }
          .contentOverviewWrapper {
            .titleRow {
              margin-bottom: 20px;
            }
            .viewContentWrapper {
              .cardItem {
                .contentRow {
                  .leftBlk {
                    .titleBlk {
                      h3 {
                        font-size: $font14;
                      }
                    }
                  }
                  .rightBlk {
                    .count {
                      font-size: $font10;
                    }
                    h4 {
                      font-size: $font18;
                      line-height: $lineHeight24;
                      margin-bottom: 6px;
                    }
                    .customTarget {
                      span {
                        font-size: $font12;
                      }
                    }
                  }
                }
              }
            }
            .orgViewWrapper {
              margin-bottom: 32px;
            }
          }
          .memberInfoWrapper {
            margin-bottom: 36px;
            border-bottom: 1px solid #dbeaf4;
            padding-bottom: 34px;
            .profileWrapper {
              position: relative;
              .imgBlk {
                position: absolute;
                left: 0;
                top: 0;
                .profilePic {
                  height: 80px;
                  width: 80px;
                  object-fit: cover;
                  border-radius: 50%;
                  &.avatar {
                    font-size: $font32;
                  }
                }
              }
              .contentBlk {
                margin-left: 100px;
                h4 {
                  margin-bottom: 16px;
                  span {
                    position: relative;
                    top: -1px;
                    margin-left: 14px;
                    display: inline-block;
                    img {
                      margin-right: 5px;
                    }
                  }
                }
                h5 {
                  margin-bottom: 8px;
                  img {
                    margin-right: 10px;
                    position: relative;
                    top: 2px;
                  }
                }
                h6 {
                  img {
                    margin-right: 10px;
                    position: relative;
                    top: 2px;
                  }
                }
              }
            }
            .rightBlk {
              p {
                margin-bottom: 16px;
              }
              h6 {
                margin-bottom: 8px;
              }
              .graphBlk {
              }
            }
          }
          .checkinDetails {
            border-radius: 4px;
            background: $color_F8F9FB;
            overflow: hidden;
            .teamActivityWrapper {
              border-radius: 0;
            }
            .teamCheckinDetails {
              background: $color_F8F9FB;
              padding: 16px;
              width: 100%;
              .checkinTitle {
                margin-bottom: 16px;
                color: $gray2;
                span {
                  position: relative;
                  top: -1px;
                  margin-left: 14px;
                  display: inline-block;
                  color: $gray3;
                  img {
                    margin-right: 5px;
                  }
                }
              }
              .goalContainer {
                margin-bottom: 8px;
                height: 450px;
                overflow-y: auto;
              }
              .lessonWrapper {
                margin: 0px 0 16px;
                h6 {
                  margin-bottom: 10px;
                  color: $gray1;
                }
                .content {
                  background: $white;
                  border-radius: 4px;
                  padding: 16px;
                  p {
                    color: $gray3;
                  }
                }
              }
            }
          }
        }
      }
    }
    .noCheckinResult {
      width: 100%;
      .noResult {
        min-height: 100%;
      }
      img {
        margin-bottom: 0px;
      }
      h6 {
        font-size: $font12;
        font-weight: $semiBoldFont;
      }
    }
    .checkinGoalContainer {
      .goalOuterWrapper {
        background: $white;
        border-radius: 4px;
        margin-bottom: 8px;
        .info {
          position: relative;
          min-height: 24px;
          img {
            position: absolute;
            left: 0px;
            top: 0px;
            height: 24px;
          }
          p {
            margin-left: 32px;
            position: relative;
            top: 2px;
          }
        }
        .titleBlk {
          padding: 4px;
          .info {
            background: $blue6;
            padding: 10px;
            border-radius: 4px;
            img {
              top: 10px;
              left: 10px;
            }
          }
        }
        .tableTitleBlk {
          padding-bottom: 8px;
          border-bottom: 1px solid $gray7;
          span {
            padding: 0 16px;
            text-transform: uppercase;
            font-size: $font12;
          }
        }
        .tableContentBlk {
          padding: 16px 10px;
          .tableItem {
            margin-bottom: 24px;
            .achivementBlk {
              display: block;
              margin: 0 auto;
              ul {
                padding: 0;
                justify-content: center;
                li {
                  padding: 0 4px;
                  .count {
                    margin-right: 8px;
                    display: inline-block;
                    position: relative;
                    text-align: center;
                    img {
                      position: absolute;
                      top: -3px;
                      left: 50%;
                      transform: translateY(-50%);
                    }
                    &.rise {
                      span {
                        color: $green;
                      }
                    }
                    &.fall {
                      span {
                        color: $red;
                      }
                    }
                  }
                  .value {
                    padding-right: 4px;
                  }
                }
              }
            }
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
        &.salesGoalOuterWrapper {
          margin-bottom: 16px;
          .titleBlk {
            .info {
              background: $background_green;
              img {
                background: $background_green;
              }
            }
          }
        }
      }
    }
    .userBannerSection {
      background-image: url($imageBasePath + 'users_overview_bg.png');
      background-color: $background_grey;
      background-size: cover;
      background-repeat: no-repeat;
      padding: 46px;
      background-position-y: -30px;
      .userBannerRow {
        .userBannerWrap {
          text-align: center;

          .userBannerHeading {
            margin-bottom: 16px;
          }
          .userBannerParagraph {
            margin-bottom: 20px;
            .userBannerHighlight {
              font-weight: $semiBoldFont;
            }
          }
          .userBannerSubText {
            margin-bottom: 16px;
          }
          .userBannerBtn {
            margin-bottom: 16px;
            position: relative;
            padding-left: 45px;
            :global(img) {
              position: absolute;
              left: 21px;
              top: 10px;
            }
          }
        }
      }
    }
    .userOverviewSection {
      background-color: $background_lightgrey;
      padding: 48px 0px;
      .userOverviewWrap {
        text-align: center;
        .userOverviewRow {
          .userOverviewIcon {
            width: 64px;
            height: 64px;
            object-fit: cover;
            margin-bottom: 18px;
          }
          .userOverviewHeading {
            margin-bottom: 10px;
          }
          .userOverviewDuration {
            background: $gray6;
            display: inline-block;
            border-radius: 4px;
            padding: 2px 8px;
            font-weight: $semiBoldFont;
            color: $gray2;
            margin-bottom: 18px;
          }
          .userOverviewParagraph {
            margin-bottom: 30px;
          }
          .userOverviewWrap {
            width: 100%;
          }
          .userOverviewInnerRow {
            width: 70%;
            margin: 0 auto;
            .userOverviewGoalsBlk {
              background: $white;
              text-align: center;
              padding: 24px 28px;
              width: 100%;
              border-radius: 4px;
              .userOverviewGoalBlkIcon {
                width: 24px;
                height: 24px;
                object-fit: cover;
                margin-bottom: 10px;
              }
              .userOverViewGoalsBlkHeading {
                color: $gray2;
                margin-bottom: 10px;
              }
              .userOverviewGoalsCount {
                margin-bottom: 10px;
              }
              .userOverviewGoalTarget {
                color: $gray4;
                .goalTargetIcon {
                  margin-right: 5px;
                  vertical-align: middle;
                  position: relative;
                  top: -1px;
                }
              }
            }
          }
        }
        .userOverviewSalesRow {
          width: 70%;
          margin: 0 auto;
          margin-bottom: 8px;
          background: $white;
          padding: 16px;
          border-radius: 4px;
          .userSalesLeadBlk {
            text-align: left;
            width: 100%;
            position: relative;
            .salesGoalLeadIcon {
              position: absolute;
              left: 0px;
              top: 3px;
            }
            .salesLeadBlkText {
              color: $gray2;
              margin-left: 35px;
              line-height: $lineHeight27;
            }
          }
          .userSalesLeadBlkRight {
            text-align: right;
            width: 100%;
            .userSalesLeadBlkRightContent {
              position: relative;
              top: -3px;
              .salesLeadBlkRightCount {
                display: inline-block;
                top: 2px;
                position: relative;
              }
              .salesLeadBlkRightText {
                display: inline-block;
                color: $gray4;
                .salesGoalTargetIcon {
                  margin-right: 5px;
                  vertical-align: middle;
                  position: relative;
                  top: -1px;
                  margin-left: 10px;
                }
              }
            }
          }
        }
      }
    }
    .checkinHistoryWrapper {
      padding: 12px 0px;
      .checkinFilterWrapper {
        float: right;
        text-align: right;
        .checkinTooltip {
          margin-right: 18px;
        }

        :global(.inputGroup) {
          width: 65%;
          float: left;
          text-align: left;
          margin-bottom: 0;
          :global(.reactSelect > div) {
            height: 39px;
            min-height: 39px;
          }
        }
        .checkinFilterBtn {
          padding: 10px;
          padding-left: 35px;
          position: relative;
          line-height: $lineHeight18;
          & img {
            position: absolute;
            left: 10px;
            top: 10px;
          }
        }
      }
      .checkinHistoryTitleRow {
        margin-bottom: 0px;
        .checkinTitleWrapper {
          padding: 0px 12px;
          .checkinHistoryTitleRowOuter {
            .checkingHistoryTitle {
              // color: $gray3;
              font-size: $font12;
              line-height: $lineHeight20;
            }
          }
        }
      }
      .checkinBlkWrapper {
        background: $white;
        border-radius: 4px;
        padding: 16px 24px;
        margin-bottom: 16px;
        position: relative;
        .checkinBlkRow {
          .checkinBlkRowOuter {
            .checkinDateWrapper {
              margin: auto 0;
              padding-right: 24px;

              .checkingDate {
                color: $gray1;
                margin-bottom: 4px;
              }
              .checkingDay {
                line-height: $lineHeight20;
              }
            }
          }
          .checkinOrganizationGoals {
            background: $blue6;
            text-align: center;
            padding: 28px;
            border-radius: 4px;
            height: 100%;
            box-sizing: border-box;
            .checkinOrganizationGoalsCountWrapper {
              margin-bottom: 38px;
              &:last-child {
                margin-bottom: 0px;
              }
              .checkinOrganizationGoalsIcon {
                width: 22px;
                object-fit: cover;
                position: relative;
                top: 7px;
                left: -10px;
              }
              .checkinOrganizationCountTitle {
                display: inline-block;
                font-size: $font14;
                color: $gray2;
                margin-bottom: 2px;
              }
              .checkinOrganizationCount {
                color: $gray2;
              }
            }
            .checkinOrganizationGoalsRevenueWrapper {
              padding-top: 30px;

              .checkinOrganizationGoalsIcon {
                width: 22px;
                object-fit: cover;
              }
              .checkinOrganizationCountTitle {
                display: inline-block;
                position: relative;
                top: -6px;
                left: 10px;
                margin-bottom: 12px;
                color: $gray2;
              }
              .checkinOrganizationGoalsDollarIcon {
                width: 17px;
                object-fit: cover;
              }
              .checkinOrganizationCount {
                color: $gray2;
                display: inline-block;
                display: inline-block;
                position: relative;
                top: -6px;
                left: 5px;
              }
            }
          }
        }
        .checkinBlkRowInner {
          .checkinSalesGoalsWrapper {
            background: $background_green;
            border-radius: 4px;
            padding: 16px;
            height: 100%;
            box-sizing: border-box;
            .checkinSalesGoals {
              margin-bottom: 4px;
              &:last-child {
                margin-bottom: 0px;
              }
              .checkinSalesGoalsBlk {
                position: relative;
                height: 100%;
                width: 100%;
                .checkinSalesGoalsBlkIcon {
                  position: absolute;
                  left: 0px;
                  top: 0px;
                  width: 22px;
                  height: 22px;
                  object-fit: contain;
                }
                .checkinSalesGoalsBlkText {
                  color: $gray2;
                  position: relative;
                  left: 35px;
                  top: 0px;
                }
              }
              .checkinSalesGoalsBlkRight {
                float: right;
                width: 65%;
                .checkinSalesGoalsBlkRightContent {
                  position: relative;
                  top: -7px;
                  .customlistInlineItem {
                    padding-bottom: 0px;
                    padding-top: 5px;
                  }
                  :global(.customTargetValue) {
                    position: relative;
                    top: -2px;
                    & p span {
                      color: $gray3;
                      font-size: $font12;
                      line-height: $lineHeight18;
                    }
                  }
                  .checkinSalesGoalsBlkRightCount {
                    display: inline-block;
                    padding-right: 16px;
                  }
                }
              }
            }
          }
          .checkinLesson {
            .checkinLessonList {
              width: 95%;
              .checkinLessonListItem {
                display: block;
                background: $background_grey;
                border-radius: 4px;
                margin-bottom: 8px;
                .checkinLessonListHeading {
                  font-weight: $semiBoldFont;
                  margin-bottom: 5px;
                }
                .viewMore {
                  a {
                    font-size: $font12;
                    font-weight: $regularFont;
                  }
                }
                .checkinLessonListContent {
                  line-height: $lineHeight20;
                }
              }
            }
          }
        }
        .checkinMoreActionWrapper {
          .checkinMoreAction {
            position: absolute;
            right: 10px;
            top: 16px;
            cursor: pointer;
          }
        }
      }
    }
    .userOverviewGoalsSection {
      .saleViewWrapper {
        .tableTitleRow {
          .achivement {
            padding-left: 24px !important;
          }
        }
        .salesCardItem {
          .accordionHeader {
            .accordionTitleBlk {
              .contentRow {
                .customTarget {
                  padding-left: 24px;
                  span {
                    color: $gray3;
                  }
                }
                .deltaList {
                  padding: 0;
                  li {
                    padding: 0 4px;
                  }
                }
                .progressBlk {
                  & :global(.progressValue) {
                    color: $gray2;
                    font-weight: $semiBoldFont;
                  }
                }
              }
            }
          }
        }
      }
    }
    .userCheckinCardOuterWrapper {
      background: $white;
      border-radius: 4px;
      .editCheckinTitle {
        border-bottom: 1px solid $gray7;
        padding: 12px 16px;
        .info {
          position: relative;
          margin-bottom: 8px;
          img {
            position: absolute;
            left: 0;
            top: 2px;
          }
          p {
            margin-left: 24px;
          }
        }
        a {
          width: 100%;
          box-sizing: border-box;
          // padding: 7px 36px 9px;
          img {
            vertical-align: middle;
          }
          span {
            position: relative;
            top: 1px;
          }
        }
      }
      .noCheckinWrapper {
        margin: 0 8px;
        padding: 8px 0;
        .noCheckinContent {
          background: $blue4;
          padding: 16px 16px 30px;
        }
        h5 {
          margin-bottom: 16px;
        }
        h6 {
          margin-bottom: 16px;
        }
        img {
          height: 116px;
          margin: 16px 0;
        }
        a {
          padding: 7px 36px 9px;
          img {
            height: auto;
            margin: 0 8px 0 0px;
          }
          span {
            position: relative;
            top: -1px;
          }
        }
        & :global(.noResultFound) {
          min-height: auto;
        }
      }
      .userCheckinContentWrapper {
        .tableTitleBlk {
          padding: 16px 16px 12px;
          p {
            img {
              margin-right: 4px;
            }
          }
        }
        .userTableContentBlk {
          .titleBlk {
            p {
              color: $gray1;
            }
          }
          .achivementBlk {
            ul {
              justify-content: flex-end !important;
              li {
                // .value{
                //   width: 64px;
                // }
                // &.customTargetBlk{
                //   // min-width: 64px;
                //   // display: none;
                // }
              }
            }
          }
          & :global(.supportTextSemibold) {
            color: $gray2;
          }
        }
      }
    }
  }
  .goalsDialog {
    :global(.MuiDialog-paper) {
      max-width: 80%;
      width: 100%;
      max-height: calc(100% - 32px);
      :global(.MuiDialogContent-root) {
        overflow-y: initial;
      }
      .dialogBody {
        .modalCloseIcon {
          cursor: pointer;
          position: absolute;
          right: 30px;
          top: 20px;
        }
        .dialogRow {
          width: 100%;
          .organizationGoalsLeftWrapper {
            position: relative;
            background: $blue4;
            padding: 48px 26px;
            min-height: 100%;
            box-sizing: border-box;
            &.salesGoalssWrapper {
              background: rgba(45, 195, 141, 0.1);
            }
            .goalsLeftInfoBox {
              color: #415766;
              display: flex;
              border-radius: 4px;
              background: $background_yellow;
              padding: 12px 8px;
              align-items: flex-start;
              margin-bottom: 24px;
              .infoIcon {
                margin-top: 5px;
                margin-right: 10px;
              }
            }
            .featuredImg {
              width: 64px;
              height: 64px;
              object-fit: cover;
            }
            .customHeading {
              margin-top: 16px;
            }
            .customSupportText {
              color: $black;
              line-height: $lineHeight20;
              margin-top: 16px;
            }
            .goalsDurationList {
              list-style: none;
              margin: 12px 0px 20px;
              li {
                display: inline-block;
                margin-right: 8px;
              }

              .goalsDurationBtn {
                padding: 14px 16px;
                background: $white;
                display: inline-block;
                border-radius: 4px;
                color: $gray4;
                font-size: $font12;
                border: 2px solid transparent;
                &.goalsActiveBtn {
                  border-color: $blue;
                  color: $blue;
                }
                &.disabledBtn {
                  cursor: not-allowed;
                  background: $gray6;
                  color: $white;
                  pointer-events: none;
                }
              }
            }
            .goalsDescriptionInput {
              margin-top: 4px;
              height: 84px !important;
              resize: none;
              color: $gray3;
              z-index: 99999;
            }
            & :global(.textAreaGroup) {
              position: relative;
              z-index: 99;
            }
            .dialogPattern {
              position: absolute;
              right: 0;
              bottom: 0;
              z-index: 9;
            }
          }
          .organizationGoalsRightWrapper {
            padding: 48px 24px 20px 24px;
            .goalsInfoBox {
              color: $gray2;
              display: flex;
              border-radius: 4px;
              background: $blue6;
              padding: 12px 8px;
              margin: 20px 0px;
              .infoIcon {
                margin-right: 8px;
              }
              .infoBoxHighlight {
                font-weight: $semiBoldFont;
              }
            }
            .goalsInputWrapperBox {
              min-height: 400px;
              height: 400px;
              overflow-y: auto;
              padding-right: 8px;
              &.noPadRight {
                padding-right: 0px;
              }
              .goalsInputWrapper {
                .customLabel {
                  position: absolute;
                  left: 0;
                  top: 0;
                  border-radius: 2px;
                  background: $gray6;
                  color: $white;
                  font-size: $font10;
                  padding: 0px 8px;
                }
                .more {
                  position: absolute;
                  right: 16px;
                  top: 12px;
                  a {
                    img {
                      transform: rotate(90deg);
                    }
                  }
                }
                .unitListingLabel {
                  margin-bottom: 5px;
                  display: block;
                  color: $gray1;
                }
                .unitListing {
                  li {
                    padding: 14px 16px;
                    cursor: pointer;
                    background: $white;
                    margin-right: 16px;
                    border: 1px solid $gray7;
                    border-radius: 4px;
                    &.activeUnit {
                      color: $blue;
                      border-color: $blue;
                    }
                    &:last-child {
                      margin: 0;
                    }
                  }
                }
                background: $background_grey;
                margin: 16px 0px;
                border-radius: 4px;
                padding: 24px;
                margin-top: 0;
                position: relative;
                margin-bottom: 16px;
                :global(.inputGroup) {
                  margin-bottom: 0px;
                }
                .goalsTextBlk {
                  position: relative;
                  .goalsImgIcon {
                    width: 36px;
                    height: 36px;
                    object-fit: cover;
                    position: absolute;
                    top: 0px;
                    left: 0px;
                  }
                  .inputTextWrapper {
                    margin-left: 50px;
                    .goalsBlkHeading {
                      color: $gray1;
                    }
                    :global(.switchWrapper) {
                      display: inline-block;
                    }
                  }
                }
                .goalsInputBlk {
                  position: relative;
                  .customGoalInput {
                    & input {
                      padding-left: 50px;
                    }
                  }
                  .inputIcon {
                    position: absolute;
                    left: 10px;
                    top: 35px;
                    width: 31px;
                    height: 26px;
                  }
                }
                .addBtnRight {
                  text-align: right;
                }
              }
              .goalsAddWrapper {
                position: relative;
                .addGoalCloseBtn {
                  cursor: pointer;
                  position: absolute;
                  right: 15px;
                  top: 15px;
                }
              }
              &.salesGoalsInputWrapperBox {
                min-height: 442px;
                height: 442px;
              }
            }
            .addGoalBtn {
              display: inline-block;
            }
          }
        }
      }
    }
  }
  .checkinDrawer {
    :global(.MuiDrawer-paper) {
      width: 45%;
      background: $background_grey;
      .checkinDrawerWrapper {
        .checkingDrawerHeader {
          background: $white;
          padding: 20px 24px;
          position: relative;
          .checkinDrawerCloseIcon {
            cursor: pointer;
            position: absolute;
            right: 35px;
            top: 25px;
          }
          .checkinDrawerIcon {
            width: 24px;
            height: 24px;
            object-fit: cover;
            position: absolute;
            left: 24px;
            top: 20px;
          }
          .checkinDrawerHeading {
            margin-left: 32px;
            display: inline-block;
          }
          .checkinDrawerDurationTag {
            background: $background_grey;
            display: inline-block;
            border-radius: 4px;
            padding: 1px 9px;
            font-weight: $semiBoldFont;
            color: $gray3;
            margin-left: 8px;
          }
          .checkinDrawerDuration {
            margin-left: 8px;
            display: inline-block;
            text-transform: uppercase;
          }
        }
        .checkinDrawerBody {
          padding: 22px 16px;
          padding-bottom: 0px;
          position: relative;
          height: 80vh;
          overflow-y: scroll;
          .checkinInputWrapper {
            .checkinDrawerInput {
              margin-bottom: 8px;
              .checkinLabel {
                color: $gray1;
                margin-bottom: 8px;
                display: block;
              }
            }
          }
          .checkinCustomInputWrapper {
            margin-bottom: 26px;
            &:last-child {
              margin-bottom: 0px;
            }
            .checkinDrawerInput {
              margin-bottom: 8px;
              .checkinLabel {
                color: $gray1;
                margin-bottom: 8px;
                display: block;
                .checkinMessage {
                  margin-right: 6px;
                }
                img {
                  vertical-align: sub;
                }
              }
            }
            .checkinCustomLabel {
              margin-bottom: 8px;
              display: block;
              color: $gray1;
            }
            .checkinInputBlk {
              background: $white;
              border-radius: 4px;
              margin-bottom: 22px;
              border: 1px solid $gray7;
              position: relative;
              .checkinDrawerCustomInput {
                :global(.inputGroup) {
                  margin-bottom: 0px;
                  & input {
                    border: none;
                  }
                }
              }
              :global(.errorMsg) {
                position: absolute;
                bottom: -25px;
              }
              .checkinDrawerGoalInputCountWrapper {
                background: $background_lightgrey;
                border-radius: 4px;
                padding: 12px 6px;
                position: relative;
                width: 100%;
                margin: 1px;
                text-align: center;
                .checkinDrawerGoalInputIcon {
                  width: 14px;
                  height: 14px;
                  object-fit: cover;
                  position: relative;
                  top: 3px;
                  top: 2px;
                }
                .checkinDrawerGoalInputCount {
                  color: $gray3;
                  margin-left: 3px;
                  display: inline-block;
                }
              }
            }
            .checkinDrawerProgressWrapper {
              width: auto;
              :global(.progressValue) {
                float: right;
                position: relative;
                top: 3px;
              }
              .checkinProgressBar {
                width: 85%;
                &.progressBarDanger {
                  color: $red;
                }
              }
            }
            .checkinInputBtnBlk {
              .checkinInputBtn {
                padding: 4px 24px;
                background: $white;
                color: $gray4;
                border: 1px solid $gray7;
                margin-right: 24px;
                margin-bottom: 8px;
                line-height: $lineHeight27;
                &.activeBtn {
                  border: 1px solid $blue;
                  color: $blue;
                }
              }
            }
            .explanationTextArea {
              margin-top: 16px;
              :global(.textAreaGroup) {
                margin-bottom: 0;
              }
            }
            :global(.textAreaGroup) {
              margin-bottom: 24px;
              &label {
                margin-bottom: 8px;
              }
            }
            .checkinTextArea {
              resize: none;
              padding-right: 100px;
              color: $gray3;
            }
          }
        }
        .checkinDrawerFooter {
          background: $white;
          padding: 8px;
          position: absolute;
          bottom: 0px;
          width: calc(100% - 18px);
        }
      }
    }
  }
  .tooltipWrapper {
    color: $white;
    .supportText {
      color: $white;
    }
  }
  .performanceInfoTooltip {
  }
  .performanceTooltipContent {
    // width: 300px;
    h1 {
      background: #ecf7ff;
      border-radius: 4px;
      padding: 8px;
      font-weight: $regularFont;
      // margin-bottom: 16px;
      span {
        font-weight: $semiBoldFont;
      }
    }
    h2 {
      font-weight: $regularFont;
      // margin-bottom: 32px;
      span {
        font-weight: $semiBoldFont;
      }
    }
    .tableTitle {
      position: relative;
      margin-bottom: 8px;
      img {
        position: absolute;
        left: 0;
        top: 0;
      }
      span {
        display: block;
        margin-left: 32px;
      }
    }
  }
  .more {
    position: absolute;
    right: 16px;
    top: 12px;
    a {
      img {
        transform: rotate(90deg);
      }
    }
  }
}

//mobile portrait view //
@media (min-width: 0px) and (max-width: 599px) {
  body {
  }
}

@media (min-width: 600px) and (max-width: 959px) {
  body {
  }
}

@media (min-width: 960px) and (max-width: 1400px) {
  body {
    .checkinDrawer {
      :global(.MuiDrawer-paper) {
        width: 48%;
      }
    }
  }
}

@media (min-width: 1280px) {
  body {
  }
}

.noTransform {
  text-transform: none !important;
  span {
    text-transform: none !important;
  }
}