.stream-input-select-container {
  div[class$='menu'] {
    > div {
      > div {
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }
    }
  }

  &.stream-user-filter {
    min-width: 280px;

    .select-option-image-container {
      width: 20px;
      height: 20px;
      img {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }

  &.stream-category-filter {
    .stream-input-selector {
      width: 185px;
    }

    .select-option-image-container {
      padding: 4px;
      width: 18px;
      height: 18px;
      img {
        height: 18px;
        width: 18px;
      }
    }

    .withBorders {
      width: min-content;
      min-width: 190px;

      div[class$='control'] {
        border: none !important;
      }

      div[class$='singleValue'] {
        border-radius: 1.5rem;
        padding: 2px 12px;
        width: fit-content;
        font-size: 0.7rem;
        font-weight: 600;
        margin-right: 2.8rem;
        background-color: #fff;
        width: 100%;
        text-align: center;
      }
    }
  }

  .inputGroup {
    margin: 0;
    width: 100%;
  }
  .stream-input-selector {
    input {
      height: 0.75rem;
    }
  }
}
