@import 'assets/styles/_variable.scss';

.buttonsContainer {
  display: flex;
  row-gap: 0.5rem;
  column-gap: 1rem;

  & > button:last-child {
    margin-left: auto;
  }
}

.primary {
  text-decoration: underline;
  font-size: 14px;
  font-weight: 500;
  color: $blue;
}

.secondary {
  text-decoration: underline;
  font-size: 13px;
  font-weight: 500;
  color: $gray3;
}

.addBtn {
  margin-inline: auto;
}

.error {
  & :global(.reactSelect) {
    border: 1px solid $red;
    background: $lightRed !important;
    border-radius: 0.25rem;
    * {
      background: none !important;
      color: #415766 !important;
    }
  }
}

.opt {
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    width: 18px;
    height: 18px;
    opacity: 0.7;
    filter: grayscale(1);
  }
}
