.wrapper {
  opacity: 0.5;
  pointer-events: none;
  height: 100%;
  position: relative;

  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%)
}
