body {
  .profileDrawer {
    .MuiPaper-root {
      width: 30%;
    }
    .profileDrawerHeader {
      padding: 24px;
      background-color: $gray7;
      .profileDrawerCloseIcon {
        float: left;
        margin-top: 5px;
        &:hover {
          cursor: pointer;
        }
      }
      .profileDrawerHeaderTitle {
        display: inline-block;
        margin-left: 22px;
      }
    }
    .profileWrapper {
      position: relative;
      height: 100%;
    }
    .profileDrawerBody {
      padding: 40px 16px;
      height: calc(80vh - 34px);
      position: relative;
      overflow-y: scroll;
      padding-bottom: 0px;
      .profileImageWrapper {
        margin-bottom: 24px;
        .profileImage {
          position: relative;
          .profileDrawerImg {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            display: inline-block;
            object-fit: cover;
          }
          .profileImageAvatar {
            width: 100px;
            height: 100px;
            display: inline-block;
            text-align: center;
            line-height: 100px;
            font-size: 40px;
          }
          .profileEditBtn {
            margin-left: 116px;
            border: 1px solid $gray6;
            color: $gray2;
            border-radius: 4px;
            position: absolute;
            padding: 4px 12px;
            top: 40px;
            text-transform: capitalize;
          }
        }
        .editFormList {
          position: absolute;
          margin-left: 116px;
          padding: 0;
          top: 40px;
          li {
            padding: 0 16px 0 0;
            &:last-child {
              padding: 0;
            }
            .inputFileGroup {
              position: relative;
              margin: 0;
              label {
                margin: 0;
              }
              a {
                border-radius: 4px;
                display: inline-block;
                position: relative;
                padding: 4px 27px;
                border: 1px solid $gray6;
                span {
                  color: $gray2;
                  font-size: $font12;
                }
              }
            }
          }
        }
      }
      .profileDrawerInputRow {
        .profileDrawerInputWrapper {
          margin-bottom: 16px;
          .inputGroup input::placeholder {
            // color: $gray2;
          }
          .profileDrawerInput {
            & label {
              color: $gray3;
            }
          }
          & label {
            color: $gray3;
          }
          .inputGroup .reactSelect .css-1wa3eu0-placeholder {
            // color: $gray2;
          }
          .passwordShow {
            top: 39px;
            right: 25px;
            .imgHide {
              margin-top: 2px;
            }
          }
          .inputGroup {
            margin-bottom: 0;
          }
          .changePasswordWrapper {
            border: 1px solid $gray7;
            padding: 14px 16px;
            &:hover {
              cursor: pointer;
            }
            .supportText {
              font-size: $font14;
              color: $gray2;
              display: inline-block;
            }
            .passwordExpandIcon {
              float: right;
              margin-top: 5px;
            }
          }
        }
      }
      .passwordFormTitle {
        margin-bottom: 24px;
        svg {
          cursor: pointer;
          fill: $gray3;
          margin-bottom: 8px;
          margin-left: -3px;
        }
        h3 {
          // margin-left: 8px;
        }
      }
      // .submitBlk{
      //   ul{
      //     li{
      //       padding: 0;
      //       min-width: calc(50% - 8px);
      //       margin-right: 16px;
      //       &:last-child{
      //         margin: 0;
      //       }
      //       button{
      //         width: 100%;
      //       }
      //     }
      //   }
      // }
      .MuiTooltip-popper {
        transform: translate3d(-8px, -175px, 0px) !important;
        .MuiTooltip-tooltip {
          background: $gray2;
          padding: 0;
          .tooltipWrapper {
            background: $gray2;
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.25);
            border-radius: 4px;
            padding: 16px 20px 16px 16px;
            position: relative;
            p {
              font-weight: $semiBoldFont;
              color: $blue6;
              margin-bottom: 4px !important;
              font-size: $font14;
            }
            ul {
              text-align: left;
              li {
                padding: 4px 0;
                img {
                  width: 12px;
                  vertical-align: middle;
                  margin-right: 5px;
                }
                span {
                  color: $blue6;
                  font-size: $font14;
                  b {
                    font-weight: $semiBoldFont;
                    color: $blue6;
                    font-size: $font14;
                    padding-right: 4px;
                    position: relative;
                    top: 1px;
                  }
                }
                &:last-child {
                  padding-bottom: 0;
                }
              }
            }
            &:after,
            &:before {
              bottom: 0;
              top: 193px;
              left: 88%;
              border: solid transparent;
              content: ' ';
              height: 0;
              width: 0;
              position: absolute;
              pointer-events: none;
              transform: rotate(180deg);
            }
            &:after {
              border-color: rgba(255, 255, 255, 0);
              border-bottom-color: $gray2;
              border-width: 10px;
              margin-left: -10px;
            }
            &:before {
              border-color: rgba(196, 196, 196, 0);
              border-bottom-color: $gray2;
              border-width: 10px;
              margin-left: -10px;
            }
          }
        }
      }
    }
    // .profileSubmitBtnRow {
    //   position: relative;
    //   .profileSubmitBtnWrapper {
    //     position: absolute;
    //     width: 100%;
    //     bottom: -30px;
    //     background: $white;
    //     z-index: 99;
    //     .profileSubmitBtn {
    //       width: 100%;
    //     }
    //   }
    // }
    .profileFooter {
      overflow: hidden;
      width: 100%;
      padding: 0px 14px;
      position: absolute;
      bottom: 10px;
      box-sizing: border-box;
      .profileSubmitBtnRow {
        .profileSubmitBtnWrapper {
          background: $white;
          z-index: 99;
          .profileSubmitBtn {
            width: 100%;
          }
        }
      }
    }
  }
  .notificationPlacement {
    top: 64px !important;
    right: 0px !important;
    left: auto !important;
    width: 30%;
  }
  .dismissBtn {
    padding: 2px 10px;
    margin-top: 5px;
    font-size: 0.7rem;
  }
  .notificationPopupWrapper {
    background: $white;
    border-radius: 4px;
    .notificationHeader {
      padding: 12px 16px;
      background: $background_lightgrey;

      .selectBlk {
        text-align: left;
        .inputGroup {
          margin-bottom: 0;
          .reactSelect {
            > div {
              height: 34px;
              min-height: 34px;
            }
            input {
              height: 18px;
            }
          }
        }
      }
      .notificationCountText {
        color: $blue;
      }
    }
    .notificationBody {
      padding-top: 10px;
      padding-bottom: 8px;
      height: 65vh;
      overflow-y: auto;
      overflow-x: hidden;
      .notificationItems {
        .notificationItemOuterWrapper {
          padding: 16px 20px;
        }
        .notificationItemWrapper {
          border-bottom: 1px solid $gray7;
          padding: 16px 8px 10px;
          margin: 0;
          position: relative;
          // padding-bottom: 0;
          .notificationImgWrapper {
            position: absolute;
            left: 8px;
            top: 15px;
            .notificationImg {
              width: 54px;
              height: 54px;
              border-radius: 50%;
              object-fit: cover;
              line-height: 54px;
            }
            .notificationAvatar {
              width: 54px;
              height: 54px;
              border-radius: 50%;
              object-fit: cover;
              line-height: 58px;
              font-size: 24px;
              text-transform: uppercase;
            }
          }
          .notificationCustomText {
            line-height: $lineHeight24;
            color: $gray2;
            margin-left: 65px;
            margin-right: 24px;
            .notificationHighlight {
              font-weight: $semiBoldFont;
              color: $gray1;
            }
          }
          .notificationTime {
            font-size: $font10;
            line-height: $lineHeight27;
            // margin-bottom: 10px;
          }
          .notificationStatus {
            width: 8px;
            height: 8px;
            background: $orange;
            position: absolute;
            right: 32px;
            top: 50%;
            border-radius: 50px;
            transform: translateY(-50%);
          }
          .moreBtn {
            position: absolute;
            top: 16px;
            right: 8px;
            // transform: translateY(-50%);
          }
        }
      }
      .noResultFound {
        min-height: 55vh;
      }
      .notificationNostate {
        h5 {
          margin-bottom: 10px;
        }
        img {
          margin-bottom: 24px;
        }
      }
    }
    .notificationFooter {
      background: $background_grey;
      padding: 10px 0px;
      text-align: center;
      .linkBtn {
        font-weight: $regularFont;
      }
    }
    &:after,
    &:before {
      bottom: 0;
      top: -20px;
      left: 73%;
      border: solid transparent;
      content: ' ';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      transform: rotate(0deg);
    }
    &:after {
      border-color: rgba(255, 255, 255, 0);
      border-bottom-color: $background_lightgrey;
      border-width: 10px;
      margin-left: -10px;
    }
    &:before {
      border-color: rgba(196, 196, 196, 0);
      border-bottom-color: $background_lightgrey;
      border-width: 10px;
      margin-left: -10px;
    }
  }
}
