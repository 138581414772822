@import 'assets/styles/_variable.scss';

.card {
  padding: 0.5rem;
  border-radius: 0.25rem;
  background: #fff;
  cursor: pointer;

  .content {
    border-radius: 2px 2px 0 0;
    padding: 0.75rem;
    color: #415766;
    font-size: 0.75rem;

    .title {
      font-size: 1.25rem;
      font-weight: 600;
    }
    .description {
      margin: 1rem 0;
    }
    .user {
      font-weight: 700;
      margin-bottom: 0.5rem;
    }
    .more {
      color: $blue;
      font-weight: 600;
      cursor: pointer;
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 0.625rem;
    margin-top: 1.125rem;
    color: #6a8294;
  }
}

.container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 1.25rem;
  grid-row-gap: 1.25rem;
  align-items: start;

  @media screen and (min-width: $sm) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (min-width: $lg) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.historyTitle {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  font-size: 1.125rem;
  font-weight: 600;
}

.message {
  width: 100%;
  margin-top: 3rem;
  text-align: center;
  padding-bottom: 3rem;
  font-weight: 600;
  color: #6a8294;
}
