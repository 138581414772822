.icon {
  vertical-align: middle;
  opacity: 0.6;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
}
.unassign {
  width: 14px;
  margin-right: 12px;
  fill: #879fad;
}
