.inputMinutes {
  padding: 0.25rem 0;
  display: flex;
  align-items: center;
  gap: 0 1rem;
  margin: 0;
  flex-wrap: wrap;
  .inputLabel {
    margin-top: -1rem;
  }
}
