.streamContainer {
  * {
    flex-wrap: wrap;
  }

  display: flex;
  flex-direction: row;
  margin: 0 1rem;
  @media (min-width: 720px) {
    margin: 0 2rem;
  }
  @media (min-width: 920px) {
    margin: 0 3rem;
  }
  flex-wrap: wrap-reverse;
  gap: 2rem;
  justify-content: start;

  .mainContent {
    display: flex;
    flex-direction: column;
    width: 100%;

    flex: 2;
    @media (min-width: 720px) {
      min-width: 410px;
    }
  }
  .sideContent {
    display: flex;
    flex-direction: column;

    flex: 1;
    min-width: 100%;
    @media (min-width: 720px) {
      min-width: 280px;
    }
  }
}


.filterContainer{
  display: flex;
  justify-content: flex-end;
  margin: 0 2rem;
}