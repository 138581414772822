.timer-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  margin: 0 0.25rem;
  .timeLabel {
    width: max-content !important;
  }
}

.recording-circle {
  background-color: #f00;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  animation: ease pulse 1.5s infinite;

  &.paused {
    animation: none;
  }
}

@keyframes pulse {
  0% {
    background-color: #f00;
  }
  50% {
    background-color: #ea8484;
  }
  100% {
    background-color: #f00;
  }
}
