.header {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin-bottom: 0.5rem;

  img {
    width: 14px;
    height: 14px;
    margin-top: 2px;
  }
}
.listContainer {
  height: 360px;
  overflow-y: auto;
  padding-right: 0.5rem;
  padding-bottom: 1rem;
}
.itemContainer {
  display: flex;
  flex-wrap: wrap;
  background: #fafbfc;
  border-radius: 0.25rem;
  margin-bottom: 0.5rem;
  cursor: default !important;

  .contentContainer {
    padding: 0 0.5rem 0.5rem 0.75rem;
    margin-right: auto;
  }

  .grabContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .moreIcon {
      cursor: pointer;
      padding: 9px 12px;
      width: 3px;
    }

    .dragIcon {
      padding: 4px;
      transform: rotate(90deg);
      cursor: grab;
      &:active {
        cursor: grabbing;
      }
    }
  }
}
.title {
  display: flex;
  align-items: center;
  .name {
    color: #546e7a;
  }
}
.goalsContainer {
  margin-left: 1rem;
  color: #78909c;
}
.info {
  width: 14px;
  height: 14px;
  margin-left: 0.25rem;
  transform: translateY(1px);
}
.hubspot {
  width: 14px;
  height: 14px;
  opacity: 0.8;
  margin-left: 0.25rem;
  transform: translateY(1px);
}
