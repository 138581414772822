.description_header {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin-bottom: 1.5rem;

  @media screen and (min-width: 1280px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(1, 1fr);
  }
}
.description_header_submit {
  display: flex;
  flex-wrap: wrap;
}
.avatar {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.description_header_title {
  color: #6a8294;
  font-size: 12;
  font-weight: 500;
  margin-bottom: 0.5rem;
}
.img {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  object-fit: cover;
}

.videoContainer {
  display: grid;
  place-items: center;
  width: 100%;
  max-width: 100%;
  max-height: 50vh;
  background: #e8ebed;
}

.player-wrapper {
  width: auto; // Reset width
  height: auto; // Reset height
}
.react-player {
  padding-top: 56.25%; // Percentage ratio for 16:9
  position: relative; // Set to relative
}

.react-player > div {
  position: absolute; // Scaling will occur since parent is relative now
}
