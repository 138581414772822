.seeLearningPaths {
  margin-top: 1rem;
}

// Cards
.card {
  background-color: #fff;
  border-radius: 1rem;
  max-width: 35rem;
  padding: 1rem;
  cursor: default;

  &:hover {
    box-shadow: 0px 16px 18px 0px #0000000a;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    .headerText {
      display: flex;
      justify-self: center;
      align-items: center;
      flex-wrap: wrap;
      column-gap: 0.5rem;
      .headerTextTitle {
        font-size: 16px;
        font-weight: 600;
      }
      .headerTextAuthor {
        font-size: 8px;
        font-weight: 600;
        color: #6a8294;
      }
    }
  }
  .description {
    font-size: 10px;
    font-weight: 400;
    line-height: 16px;
    padding-left: 0.25rem;
    margin-bottom: 0.5rem;
    min-height: 3rem;
  }

  .bubblesContainer {
    display: grid; 
    grid-row: auto auto;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    margin-bottom: 1rem;
    text-wrap: nowrap;

    grid-template-columns: repeat(1, auto);
    @media (min-width: 480px) {
      grid-template-columns: repeat(2, auto);
    }

    > div {
      flex-wrap: wrap;
      display: flex;
      align-items: center;
      gap: 0.25rem;
      background-color: #f9fafc;
      padding: 6.44px 12.88px 6.44px 9.66px;
      border-radius: 80px;
      font-family: Open Sans;
      font-size: 0.75rem;
      font-weight: 400;
    }
  }
}
