.successContainer {
  :global(.MuiDialog-paper) {
    max-width: 480px;
    width: 80%;
  }

  .title {
    text-align: center;

    h2 {
      font-weight: 600;
      color: #415766;
      margin: 1rem 0;
    }
    p {
      font-size: 0.8rem;
      color: #6a8294;
      font-weight: 400;
    }
  }

  .buttons {
    display: flex;
    gap: 1rem;
    margin: 1.5rem 0;
    button {
      flex: 1;
    }
  }
}
