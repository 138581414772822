.nudge-container {
  .nudge:hover {
    filter: invert(50%) sepia(54%) saturate(5123%) hue-rotate(184deg) brightness(100%)
      contrast(104%);
    cursor: pointer;
  }
  .nudge:active {
    filter: none;
    transform: scale(0.9);
  }
  .nudge.disabled {
    opacity: 0.3;
    cursor: default;
  }
}
