.notificationSetting {
  padding-top: 20px;
  display: flex;
  gap: 10px;
}

.notificationTitle {
  font-weight: 400;
  font-size: 14px;
  color: #415766;
}

.notificationDescription,
.notificationDisabled {
  font-weight: 400;
  font-size: 12px;
  color: #6b7e8b;
}

.notificationDisabled {
  color: #000000;
}

.arrowBack {
  cursor: pointer;
  color: #6a8294;
}
