.container {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    margin-bottom: 1.5rem;
    width: 49%;
    min-width: 100%;
    background-color: #ffffff;
    border-radius: 12px;

    &.interactive:hover{
      cursor: pointer;
      box-shadow: 0px 16px 18px 0px #0000000a;
    }
    
    @media (max-width: 640px) {
      .main {
        flex-wrap: wrap;
        justify-content: center;
      }
      .mainText {
        width: 90% !important;
      }
      .bubblesContainer {
        display: flex !important;
        flex-wrap: wrap !important;
      }
    }
    @media (min-width: 800px) {
      .graphContainer {
        height: 100%; 
        display: grid; 
        align-content: center;
      }
    }
    @media (min-width: 1120px) {
      min-width: 480px;
    }
    
    > div {
      min-height: 10rem;
      padding: 0.75rem 1rem;
    }
    .main {
      width: 100%;
      display: flex;
      .mainGraph {
        width: 30%;
      }
      .mainText {
        width: 70%;
        .headerText {
          display: flex;
          justify-self: center;
          align-items: center;
          flex-wrap: wrap;
          column-gap: 0.5rem;
          .headerTextTitle {
            font-size: 16px;
            font-weight: 600;
          }
          .headerTextAuthor {
            font-size: 8px;
            font-weight: 600;
            color: #6a8294;
          }
        }
        .description {
          font-size: 10px;
          font-weight: 400;
          line-height: 16px;
          margin-bottom: 0.5rem;
          margin-top: 0.5rem;
        }
        .bubblesContainer {
          display: grid;
          grid-template-columns: repeat(2, auto);
          grid-row: auto auto;
          grid-column-gap: 10px;
          grid-row-gap: 10px;
          margin-bottom: 1rem;
          text-wrap: nowrap;
          > div {
            display: flex;
            align-items: center;
            gap: 0.25rem;
            background-color: #f9fafc;
            padding: 6.44px 12.88px 6.44px 9.66px;
            border-radius: 80px;
            font-family: Open Sans;
            font-size: 0.75rem;
            font-weight: 400;
          }
        }
      }
    }
    .deadline {
      min-width: 15vw;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .number{
        font-size: 2rem;
        font-weight: 600;
        margin: 0 0 1rem 1rem;
      }
      .numberText{
        font-size: 1rem;
        font-weight: 600;
        margin: 0 0 1rem 1rem;
      }
      .icon{
        margin: 1rem 0 0 1rem;
        width: 30px;
      }
    }
    .completion {
      min-width: 20vw;
      background-color: #0494fd;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      color: #FFF;
      .number{
        font-size: 2rem;
        font-weight: 600;
        margin: 0 0 1rem 1rem;
      }
      .numberText{
        font-size: 1rem;
        font-weight: 600;
        margin: 0 0 1rem 1rem;
      }
      .icon{
        margin: 1rem 0 0 1rem;
        width: 30px;
      }
    }
  }
  
  .totalContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%) scale(0.9);
    text-align: center;
  }