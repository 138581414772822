.comment {
  display: flex;
  margin: 0 0.5rem;
  margin-bottom: 0.75rem;
  gap: 0.6rem;

  .picture {
    img,
    & :global(.avatar) {
      width: 40px;
      height: 40px;
    }
  }

  .commentContent {
    flex: 1;

    .name {
      font-family: Open Sans;
      color: #193448;
      font-size: 0.8rem;
      font-weight: 400;
      display: flex;
      gap: 1rem;
      justify-content: space-between;
    }
    .commentText {
      transform: translateY(-4px);
    }

    .reactions {
      display: flex;
      justify-content: end;
      margin-top: -0.9rem;
    }
  }
}
