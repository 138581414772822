@import 'variable';
body {
  .onboardingOverviewShimmer {
    .overviewcircle {
      margin-left: 20px;
    }
  }
  .onboardingPage {
    .setupOnboardingsection {
      background: $background_orange;
      padding: 16px 24px;
      position: relative;
      .titleWrapper {
        position: relative;
        .imgBlk {
          position: absolute;
          left: 0;
          top: 0;
          height: 50px;
          width: 50px;
          border-radius: 50%;
          background: $white;
          img {
            position: relative;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-height: 40px;
            max-width: 40px;
          }
        }
        .contentBlk {
          margin-left: 74px;
          h1 {
            margin-bottom: 4px;
          }
          p {
            color: $gray3;
          }
        }
      }
      .stepsWrapper {
        .progressBlk {
          .stepProgress {
            width: 90%;
          }
          & :global(.progressBar .progressValue) {
            display: none;
          }
        }
        .stepsBlk {
          h2 {
            .steps {
              position: relative;
              top: 2px;
              color: $gray2;
            }
            .supportText {
              color: $gray3;
            }
          }
        }
      }
      .patternBg {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
      }
    }
    .onboardingStepsection {
      padding: 24px;
      .indexListWrapper {
        padding-right: 16px;
        .titleRow {
          margin-bottom: 16px;
          h5 {
            color: $gray2;
          }
          button {
            padding: 6px 16px;
          }
        }
        .indexList {
          .listItem {
            margin-bottom: 4px;
            margin-left: 20px;
            a {
              padding: 8px 4px 10px;
              display: block;
              position: relative;
              background: transparent;
              border-radius: 4px;
              @include transitionForAll($time: 0.4s, $transProp: ease-out);
              .typeBlk {
                position: absolute;
                left: 8px;
                top: 2px;

                .number {
                  font-size: $font10;
                  min-height: 18px;
                  min-width: 18px;
                  text-align: center;
                  background: $white;
                  border-radius: 50%;
                  display: inline-block;
                }
                img {
                  margin-left: 12px;
                  height: 20px;
                  position: relative;
                  top: 6px;
                }
                .activeImg {
                  display: none;
                }
              }
              .customStepIcon {
                position: absolute;
                top: 12px;
                right: 8px;
              }
              .title {
                display: block;
                margin-left: 60px;
                position: relative;
                top: 1px;
              }

              &:hover {
                background: $white;
                .typeBlk {
                  .number {
                    background: $gray7;
                  }
                }
              }
              &.active {
                background: $blue5;
                .typeBlk {
                  .number {
                    color: $blue;
                  }
                }
                .title {
                  color: $blue;
                }
                @include transitionForAll($time: 0.3s, $transProp: ease-in);
                .activeImg {
                  display: inline-block;
                }
                .defaultImg {
                  display: none;
                }
              }
              &.completed {
                .typeBlk {
                  .number {
                    background: $green;
                    color: $white;
                  }
                }
              }
              &.customStep {
                padding: 8px 24px 10px 4px;
              }
            }
          }
        }
      }
      .introductionCardWrapper {
        background: $white;
        border-radius: 4px;
        padding: 24px;
        .titleRow {
          margin-bottom: 44px;
          h1 {
            margin-bottom: 4px;
          }
          p {
          }
          ul {
            padding: 0;
            li {
              padding: 0 8px;
              &.infoItem {
                img {
                  margin-right: 5px;
                  vertical-align: middle;
                  position: relative;
                  top: -1px;
                }
                span {
                  color: $gray5;
                }
              }
              &:last-child {
                padding-right: 0;
              }
            }
          }
        }
        .contentWrapper {
          background: $blue6;
          border-radius: 4px;
          padding: 24px;
          position: relative;
          min-height: 220px;
          margin-bottom: 24px;
          .videoWrapper {
            position: absolute;
            left: 24px;
            top: 24px;
            background: $gray4;
            border-radius: 4px;
            .videoContent {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              text-align: center;
              cursor: pointer;
              z-index: 99;
              p {
                color: $white;
              }
            }
            video {
              border-radius: 4px;
              width: 375px;
              height: 208px;
            }
            &:before {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              background: rgba(25, 52, 72, 0.7);
              display: block;
              height: 213px;
              width: 100%;
              border-radius: 4px;
            }
            &.shimmerVideoWrapper {
              &:before {
                background: rgba(0, 0, 0, 0.11);
              }
            }
          }
          .imageWrapper {
            position: absolute;
            left: 24px;
            top: 24px;
            background: transparent;
            border-radius: 4px;
            img {
              border-radius: 4px;
              width: 375px;
              height: 208px;
            }
            .downloadBtn {
              margin-top: 20px;
            }
          }
          .contentBlk {
            margin-left: 400px;
            h4 {
              margin-bottom: 8px;
            }
            ul {
              li {
                padding: 0 0 12px 24px;
                display: block;
                position: relative;
                &::before {
                  content: '';
                  position: absolute;
                  left: 4px;
                  top: 6px;
                  background: url($imageBasePath + 'caret_right.svg')
                    no-repeat;
                  height: 16px;
                  width: 16px;
                }
              }
            }
          }
          &.contentWrapperSample {
            min-height: 210px;
            .downloadSampleBtn {
              span {
                text-transform: none;
              }
            }
          }
        }
        .addvideoBtnBlk {
          button {
            text-transform: none;
          }
        }
      }
    }
    .dashboard {
      padding: 24px;
      .onboardingStepsection {
        padding: 24px 0;
      }
      // .progressSection{
      //   margin-bottom: 24px;
      //   .progressChart{
      //     background: $white;
      //     border-radius: 4px;
      //     padding: 16px;
      //     width: 100%;
      //     h3{
      //       padding-bottom: 10px;
      //     }
      //     .chartLegend{
      //       h6{
      //         color: $gray2;
      //       }
      //       p{
      //         font-size: 15px;
      //         color: $gray3;
      //         line-height: 22px;
      //         margin-bottom: 24px;
      //       }
      //       ul{
      //         margin-left: 24px;
      //         li{
      //           margin-bottom: 24px;
      //           position: relative;
      //           strong{
      //             margin-left: 8px;
      //           }
      //           &::before{
      //             content: '';
      //             position: absolute;
      //             width: 6px;
      //             height: 6px;
      //             background: $white;
      //             border-radius: 50%;
      //             left: -21px;
      //             top: 8px;
      //             z-index: 99;
      //           }
      //           &.completed{
      //             &::after{
      //               content: '';
      //               position: absolute;
      //               width: 12px;
      //               height: 12px;
      //               background: $green;
      //               border-radius: 50%;
      //               left: -24px;
      //               top: 5px;
      //             }
      //           }
      //           &.inProgress{
      //             &::after{
      //               content: '';
      //               position: absolute;
      //               width: 12px;
      //               height: 12px;
      //               background: $yellow;
      //               border-radius: 50%;
      //               left: -24px;
      //               top: 5px;
      //             }
      //           }
      //           &.yetToStart{
      //             &::after{
      //               content: '';
      //               position: absolute;
      //               width: 12px;
      //               height: 12px;
      //               background: $orange;
      //               border-radius: 50%;
      //               left: -24px;
      //               top: 5px;
      //             }
      //           }
      //         }
      //       }
      //     }
      //   }
      //   .completionTime{
      //     background: $white;
      //     border-radius: 4px;
      //     padding: 24px 16px;
      //     position: relative;
      //     padding-left: 92px;
      //     margin-bottom: 24px;
      //     img{
      //       position: absolute;
      //       left: 16px;
      //       top: 24px;
      //     }
      //   }
      //   .overallRatings{
      //     padding: 16px 24px;
      //     background: $white;
      //     border-radius: 4px;
      //     h3{
      //       padding-bottom: 16px;
      //     }
      //     h6{
      //       margin-bottom: 4px;
      //     }
      //     .ratingList{
      //       ul{
      //         padding-left: 30px;
      //         li{
      //           margin-bottom: 8px;
      //           height: 14px;
      //           position: relative;
      //           span{
      //             position: absolute;
      //             left: -28px;
      //             top: -4px;
      //           }
      //           &.five{
      //             width: 30%;
      //             background-color: $green;
      //           }
      //           &.four{
      //             width: 40%;
      //             background-color: $color_AED888;
      //           }
      //           &.three{
      //             width: 15%;
      //             background-color: $yellow;
      //           }
      //           &.two{
      //             width: 10%;
      //             background-color: $color_FFB235;
      //           }
      //           &.one{
      //             width: 5%;
      //             background-color: $orange;
      //           }
      //         }
      //       }
      //     }
      //   }
      // }
      // .teamProgress{
      //   .teamProgressWrapper{
      //     background-color: $white;
      //     padding: 24px 16px;
      //     border-radius: 4px;
      //     .headingRow{
      //       .filtersList{
      //         text-align: right;
      //         input{
      //           width: 294px;
      //           margin-right: 20px;
      //         }
      //         button{
      //           background: $white;
      //           border: 1px solid $gray7;
      //           border-radius: 4px;
      //           padding: 5px 16px;
      //           color: $gray2;
      //           position: relative;
      //           bottom: 2px;
      //           svg{
      //             margin-left: 10px;
      //           }
      //           @include transitionForAll($time: 0.3s, $transProp: ease-out);
      //           &:hover{
      //             border-color: $blue;
      //             color: $blue;
      //             @include transitionForAll($time: 0.3s, $transProp: ease-in);
      //             svg{
      //               path{
      //                 fill: $blue;
      //                 @include transitionForAll($time: 0.3s, $transProp: ease-in);
      //               }
      //             }
      //           }
      //         }
      //       }
      //     }
      //     .teamProgressDetail{
      //       .titleRow{
      //         border-bottom: 1px solid $gray7;
      //       }
      //       .contentBlock{
      //         padding: 20px 0;
      //         .item{
      //           padding: 12px 0;
      //           .copyText{
      //             position: relative;
      //             top: 2px;
      //           }
      //           .comments{
      //             display: inline-block;
      //             position: relative;
      //             left: 12px;
      //             bottom: 2px;
      //             span{
      //               position: relative;
      //               left: 4px;
      //               bottom: 4px;
      //             }
      //           }
      //           .progressBar{
      //             max-width: 70px;
      //           }
      //         }
      //       }
      //     }
      //   }
      // }
    }
    .uploadedFilePreviewWrapper {
      background: $white;
      border-radius: 4px;
      padding: 24px;
      .moreBlk {
        margin-left: 24px;
      }
      .topBlkWrapper {
        margin-bottom: 22px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        &.markCompleteWrapper {
        }
      }
      .markCompleteBlk {
        text-align: center;
        max-width: 180px;
        min-width: 180px;
        border: 1px solid $blue;
        padding: 6px 16px;
        border-radius: 4px;
        margin-left: 24px;
        & :global(.inputGroup) {
          margin: 0;
          label {
            margin: 0;
            svg {
              height: 20px;
              padding-right: 4px;
              width: 20px;
            }
            span {
              font-size: $font14;
              color: $blue;
              padding: 0;
            }
          }
        }
        &.disabled,
        &.contentNotRead {
          border: 1px solid $gray6;
          cursor: not-allowed;
          pointer-events: none;
          & :global(.inputGroup) {
            margin: 0;
            label {
              margin: 0;
              svg {
                color: $gray3;
              }
              span {
                color: $gray2;
              }
            }
          }
        }
        &.contentNotRead {
          pointer-events: all;
          cursor: auto;
        }
      }
      .videoWrapper {
        margin-bottom: 24px;
        // height: 70vh;
        background: $gray7;
        border-radius: 4px;
        video,
        iframe {
          width: 100%;
          border-radius: 4px;
          // object-fit: cover;
        }
        video {
          height: 70vh;
        }
        &.defaultIframeWrapper {
          // height: unset;
          iframe {
            min-height: 70vh;
          }
        }
      }
      .contentRow {
        margin-bottom: 12px;
        h3 {
        }
        ul {
          li {
          }
        }
      }
      .description {
        padding-bottom: 16px;
        color: $gray2;
        textarea {
          padding: 0;
          background: transparent;
        }
        :global(.inputGroup) {
          margin-bottom: 0;
        }
      }
      .viewMore {
        margin-bottom: 20px;
        padding-top: 48px;
        margin-top: -48px;
        background: linear-gradient(0deg, #ffffff 0%, rgba(255, 255, 255, 0) 137.03%);
        position: relative;
        &.viewMoreClear {
          background: transparent;
        }
      }
      .tagList {
        // margin-top: 16px;
        margin-bottom: 20px;
      }
      .optionalList {
        padding: 0;
        // margin-top: 16px;
        // margin-bottom: 22px;
        li {
          padding: 0 8px;
          .optional,
          .mandatory {
            background: $background_orange;
            border-radius: 4px;
            padding: 6px 8px 5px;
            img {
              position: relative;
              top: 1px;
              margin-right: 4px;
            }
          }
          .calendar {
            border: solid #5F5B5B99 0.1rem;
            border-radius: 4px;
            padding: 6px 8px 5px;
            img {
              position: relative;
              top: 1px;
              margin-right: 4px;
            }
          }
          .mandatory {
            background: $background_green;
          }
          &:first-child {
            padding-left: 0;
          }
        }
      }
      .vedioPreview {
      }
      .filePreview {
        // display: none;
        margin-bottom: 16px;
        .leftBlk {
          .contentWrapper {
            padding-right: 24px;
            h3 {
              margin-bottom: 6px;
            }
            p {
              // margin-bottom: 16px;
            }
            .tagList {
              margin-top: 16px;
              margin-bottom: 16px;
            }
            .optionalList {
              margin-top: 16px;
              margin-bottom: 32px;
            }
          }
        }
        .rightBlk {
          text-align: right;
          .imageWrapper {
            margin-bottom: 16px;
            img {
              background: #e7f0f5;
              // opacity: 0.7;
              border-radius: 4px;
              height: 200px;
              width: 95%;
              object-fit: cover;
            }
          }
        }
      }
    }
    .commentOuterWrapper {
      h4 {
        margin-bottom: 12px;
      }
      .commentWrapper {
        position: relative;
        border: 1px solid #e7f0f5;
        border-radius: 4px;
        padding: 5px 14px;
        margin-bottom: 32px;
        .userInfo {
          position: absolute;
          left: 14px;
          top: 7px;
          .profilePic,
          .avatar {
            height: 28px;
            width: 28px;
            // line-height: 21px;
          }
        }
        .enterComment {
          margin: 0 160px 0px 42px;
          & :global(.inputGroup) {
            margin-bottom: 0;
          }
          textarea {
            border: none;
            padding: 5px 0;
          }
        }
        .postComment {
          position: absolute;
          right: 14px;
          top: 6px;
        }
      }
      .commentPreviewWrapper {
        .noComments {
          margin: 16px 0;
        }
        .commentList {
          .commentItem {
            position: relative;
            margin-bottom: 28px;
            .userInfo {
              position: absolute;
              left: 0px;
              top: -1px;
              .profilePic,
              .avatar {
                height: 44px;
                width: 44px;
              }
            }
            .commentDetail {
              margin-left: 56px;
              h6 {
                color: $gray1;
                margin-bottom: 4px;
              }
              p {
                margin-bottom: 14px;
              }
              ul {
                li {
                  padding: 0 8px;
                  cursor: pointer;
                  &:first-child {
                    padding-left: 0;
                  }
                }
              }
              .updateCommentWrapper {
                position: relative;
                textarea {
                  padding-right: 140px;
                }
                button {
                  position: absolute;
                  right: 14px;
                  top: 50%;
                  transform: translateY(-50%);
                  -webkit-transform: translateY(-50%);
                  -moz-transform: translateY(-50%);
                  -ms-transform: translateY(-50%);
                  -o-transform: translateY(-50%);
                }
              }
            }
            .infolList {
              padding: 0;
              li {
                padding: 0 8px;
                span {
                  color: $gray4;
                }
                img {
                  padding: 8px;
                }
                &:last-child {
                  padding-right: 0;
                }
              }
            }
          }
        }
      }
    }
    .setupCompleteSection {
      margin: 24px;
      .setUpwrapper {
        // background-image: url('setup_complete_bg.png');
        // padding: 24px 0;
        min-height: 115px;
        background-color: $background_green;
        .titleWrapper {
          position: relative;
          .imgBlk {
            position: absolute;
            left: 32px;
            top: -4px;
            img {
              height: 80px;
            }
          }
          .contentBlk {
            margin-left: 140px;
            h1 {
              color: $green;
              margin-bottom: 6px;
            }
            p {
              color: $gray2;
              font-weight: $regularFont;
              span {
                font-weight: $semiBoldFont;
              }
            }
          }
        }
        .publishBtn {
          margin-right: 36px;
        }
      }
    }
  }
  .addVideoDialog {
    :global(.MuiDialog-paper) {
      max-width: 1090px;
      width: 1090px;
    }
    .customTitle {
      h3 {
        img,
        span {
          vertical-align: middle;
        }
        img {
          margin-left: 8px;
          margin-top: 2px;
        }
      }
    }
    .dialogBody {
      .leftContent,
      .rightContent {
        padding: 24px;
        width: 100%;
      }
      .rightContent {
        border-left: 1px solid $gray7;
        h2 {
          margin-bottom: 16px;
        }
        .userPreference {
          color: $gray1;
          margin-bottom: 8px;
        }
        .radioBtnList {
          // padding: 0;
          li {
            padding: 0 8px;
            &:first-child {
              padding-left: 0;
            }
          }
        }
      }
      .leftContent {
        padding-top: 16px;  
        :global(.dzu-dropzone) {
          margin: 20px 0 0;
        }
        :global(.dzu-inputLabel) {
          position: relative;
        }
        .fileUpload {
          border: 1px dashed $blue;
          box-sizing: border-box;
          border-radius: 8px;
          padding: 48px;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 15px;
          height: 160px;
          background-color: #F9FCFF;
          .FileUploadText{
            img {
              margin-bottom: 32px;
            }
            h6 {
              margin-bottom: 8px;
            }
          }
        }
        .embedVideoBlock {
          padding: 16px 0;
          .noteBlock {
            position: relative;
            background: #f2faff;
            border-radius: 2px;
            padding: 8px 24px;
            padding-left: 30px;
            margin-bottom: 20px;
            img {
              position: absolute;
              left: 8px;
              top: 10px;
            }
          }
          .errorGroup {
            border: 1px solid $red;
            background: $lightRed;
            &:focus {
              background: transparent;
            }
          }
          .errorMsg {
            margin-bottom: 16px;
            margin-top: -16px;
          }
          .previewBlock {
            padding: 40px;
            text-align: center;
            background: $color_F9FCFF;
            border: 1px solid $gray7;
            border-radius: 4px;
            img {
              margin-bottom: 16px;
            }
            p {
              line-height: 20px;
              color: $gray6;
            }
            iframe {
              max-width: 100%;
              border-radius: 4px;
            }
            .linkPreviewBlk {
              video {
                max-width: 100%;
                border-radius: 4px;
                margin-bottom: 16px;
              }
              img {
                margin-bottom: 16px;
              }
              p {
                word-break: break-word;
                a {
                  text-transform: none;
                }
              }
            }
          }
        }
      }
    }
    :globla(.tertiaryBtn),
    :global(.primaryBtn) {
      padding: 10px 16px;
    }
    :global(.primaryBtn) {
      margin-left: 20px;
    }
  }

  .viewMoreBtn {
    button {
      border-radius: 25px;
      padding: 6px 16px;
      font-size: $font12;
    }
  }

  ////harsha scss

  .publishDialog {
    :global(.MuiDialog-paper) {
      max-width: 500px;
      width: 500px;
    }
    .dialogBody {
      background-color: $white;
      padding: 80px 24px 24px;
      position: relative;
      .publishBanner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 176px;
      }
      .modalCloseIcon {
        position: absolute;
        right: 30px;
        top: 30px;
        z-index: 1;
        cursor: pointer;
      }
      h4 {
        margin: 8px 0 4px;
      }
      ul {
        margin-top: 24px;
        li {
          margin-right: 16px;
          button {
            padding: 12px 24px;
            &:global(.secondaryBtn) {
              padding: 11px 24px;
            }
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
      .publishLogo {
        position: relative;
      }
    }
  }

  .feedBackDialog {
    .ratingForm {
      margin-top: 24px;
      label {
        font-size: 32px;
      }
      &.publish {
        ul {
          li {
            button {
              box-sizing: border-box;
              min-width: 180px;
            }
          }
        }
      }
    }
    textarea {
      margin-top: 32px;
    }
  }

  :global(.filePreviewComponent) {
    max-width: 300px;
    video {
      width: 300px;
    }
  }
}

//mobile portrait view //
@media (min-width: 0px) and (max-width: 599px) {
  body {
    .onboardingPage {
    }
  }
}

@media (min-width: 600px) and (max-width: 959px) {
  body {
    .onboardingPage {
    }
  }
}

@media (min-width: 960px) and (max-width: 1279px) {
  body {
  }
}

@media (min-width: 1280px) {
  body {
  }
}
