.incorrect,
.correct {
  background: #f9e9e9;
  color: #c32d2d;
  border-radius: 4px;
  padding: 2px 10px 2px 10px;
  height: 20px;
  display: flex;
  gap: 4px;
  align-items: center;
  font-weight: 600;
  font-size: 10px;
}

.correct {
  color: #2dc38d;
  background: #e9f9f3;
}
