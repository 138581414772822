@import 'assets/styles/_variable.scss';

.container {
  background: $white;
  border-radius: 0.25rem;
  margin-top: 1.5rem;
}

.myTeam {
  .title {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1rem 0 1rem;

    :global(.inputGroup) {
      margin-bottom: 0;
      max-width: 160px;
      // max-height: 32px;

      max-height: 32px;
      * {
        min-height: unset;
      }
    }

    h1 {
      color: $gray3;
      font-size: 1rem;
      font-weight: 600;
    }
  }
}

.table {
  background: $white;
}

.row,
.rowHeader {
  display: flex;
  justify-content: space-evenly;
  padding-right: 1rem;

  .cell,
  .headerCell {
    background: $white;
    color: $gray2;
    padding: 1.25rem 1rem;
    display: flex;
    align-items: center;

    h1 {
      font-weight: 600;
      font-size: 1rem;
    }
  }

  .statusOnDate {
    word-break: normal;
    .dateLabel {
      margin-left: 1rem;
      color: #6a8294;
    }
  }

  .userCell {
    display: flex;
    gap: 10px;

    .info {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
  }

  .roleplayCell {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
  }

  .actions {
    display: flex;
    gap: 1.25rem;
  }
}

.rowHeader {
  border-bottom: 1px solid #dbeaf4;
}

.message {
  width: 100%;
  padding-top: 2rem;
  text-align: center;
  padding-bottom: 2rem;
  font-weight: 600;
  color: #6a8294;
}

.teamButton {
  width: 90px;
  height: 36px;
  border-radius: 100px !important;
  padding: 0 !important;

  &:disabled {
    background: rgba(4, 148, 253, 0.5);
    text-transform: capitalize;
    color: #fff !important;
  }
}
