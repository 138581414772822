.recorder-btn-container,
.recording-indicator-button {
  position: relative;
  width: 2.5rem !important;
  height: 2.5rem !important;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
  display: grid;
  place-items: center;
  &:hover {
    background: #ebebeb;
  }
  &:active {
    background: #0494fd;
    img {
      filter: invert(94%) sepia(0%) saturate(0%) hue-rotate(153deg) brightness(104%) contrast(105%);
    }
  }
}
