.smartMentor {
  bottom: 1.1rem;
  right: 1.1rem;
  border: 0;
  z-index: 1;
  position: fixed;
  border-radius: 50%;
  background-color: #c4e6ff;
  color: #fff;
  height: 3.2rem;
  width: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    cursor: pointer;
    background-color: #a7daff;
  }

  .iconLogo {
    width: 30px;
    height: 30px;
    fill: #fff !important;
    &:hover {
      cursor: pointer;
    }
  }
}
