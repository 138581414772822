@import 'assets/styles/_variable.scss';

.commentsContainer {
  margin-bottom: 0.75rem;
  .commentsList {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
  }
  .hideComments {
    text-align: center;
    margin-top: 0.5rem;
    > button {
      color: #0495fddc;
      font-size: 0.8rem;
      cursor: pointer;
      padding: 0.5rem;
      padding-bottom: 0;

      &:hover {
        color: #0494fd;
      }
      &:active {
        transform: translateY(-2px);
      }
    }
  }
}
