.choicesContainer {
  display: flex;
  row-gap: 1rem;
  flex-direction: column;

  .choice {
    display: flex;
    column-gap: 0.5rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    width: fit-content;

    span {
      color: #193448cc;
    }

    .radio,
    .selected {
      border-radius: 50%;
      background: #eeeeee;
      height: 16px;
      width: 16px;
    }
    .selected {
      background: #0494fd;
      display: grid;
      place-content: center;
    }
  }
}
