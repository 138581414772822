@import '../../../assets/styles/variable';
.fileUpload {
  border: 1px dashed $blue;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 48px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  height: 160px;
  background-color: #f9fcff;
  .FileUploadText {
    img {
      margin-bottom: 32px;
    }
    h6 {
      margin-bottom: 8px;
    }
  }
}
