@import 'assets/styles/_variable.scss';
.addButton {
  border-radius: 1rem;
  border: 1px dashed #bdbdbd;
  color: #616161;
  width: 100%;
  height: 96px;
  display: grid;
  place-items: center;
  cursor: pointer;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 1rem;

  .label {
    display: flex;
    gap: 1rem;
    align-items: end;
    .plus {
      font-size: 2rem;
      padding-bottom: 2px;
    }
  }

  &.smallHeight {
    height: 56px;
    font-size: 16px;
    .plus {
      font-size: 1.75rem;
    }
  }

  &:hover {
    border: 1px solid $blue;
    color: $blue;
  }
}
