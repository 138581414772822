.radioBtnList {
    // padding: 0;
    li {
      padding: 0 8px;
      &:first-child {
        padding-left: 0;
      }
    }
  }

  .frequencyInputs{
    display: flex;
    gap: 0.5rem;
    .frequencyInput{
        width: 50%;
    }
  }
