.container {
  padding-top: 1rem;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  
  .headerTitle {
    font-size: 16px;
    font-weight: 600;
    color: #757575;
  }
  .headerDescription {
    font-size: 10px;
    font-weight: 400;
    color: #415766;
  }
}

.containerRep{
  padding: 1rem 2rem;
  .headerText{
    margin-bottom: 1.25rem;
  }
}