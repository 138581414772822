@import 'assets/styles/_variable.scss';

.moduleStatus {
  padding: 4px 8px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: 24px;

  img {
    margin-right: 5px;
    height: 12px;
  }

  &.disabled {
    background: rgba(106, 130, 148, 0.1);
    span {
      color: $gray3;
      font-size: $font14;
      font-weight: $semiBoldFont;
    }
  }
  &.draft {
    background: rgba(106, 130, 148, 0.1);
    border-radius: 4px;
    padding: 4px 12px;
    span {
      color: $gray3;
      font-size: $font14;
      font-weight: $semiBoldFont;
    }
  }
  &.published {
    background: rgba(45, 195, 141, 0.1);
    span {
      color: $green;
      font-size: $font14;
      font-weight: $semiBoldFont;
    }
  }
}
