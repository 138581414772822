@import 'assets/styles/_variable.scss';

body {
  .chatContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 90vh;
    width: 100%;
    box-sizing: border-box;
  }
  .messagesContainer {
    flex: 1;
    display: flex;
    flex-direction: column-reverse;
    gap: 10px;
    overflow-y: auto;
    background-color: #f3f5f8;
    margin-bottom: 1rem;
    box-sizing: border-box;
    padding: 0 14.5rem;
    @include xsmall {
      padding: 0 2rem;
    }
    @include small {
      padding: 0 7rem;
    }
    @include medium {
      padding: 0 10rem;
    }
  }
  .messageIA {
    align-self: flex-start;
    padding: 8px;
    background-color: #eceff1;
    border-radius: 8px 8px 8px 0;
    max-width: 60%;
    word-wrap: break-word;
    box-sizing: border-box;
    @include xsmall {
      max-width: 70%;
    }
  }
  .messageUser {
    align-self: flex-end;
    padding: 8px;
    background-color: #0994fd;
    border-radius: 8px 8px 0 8px;
    max-width: 60%;
    word-wrap: break-word;
    box-sizing: border-box;
    color: white;
    @include xsmall {
      max-width: 70%;
    }
  }

  .loadMessageContainer {
    align-self: flex-start;
    margin-left: 250px;
    font-size: $font14;
    color: #607d8b;
    margin-bottom: 1rem;
    @include xsmall {
      margin-left: 2rem;
    }
    @include small {
      margin-left: 7rem;
    }
    @include medium {
      margin-left: 10rem;
    }
  }

  .inputContainer {
    background-color: white;
    border-top: 1px solid #ddd;
    display: flex;
    align-items: center;
    padding: 8px;
    margin: 0 230px 1rem 230px;
    border-radius: 8px;
    @include xsmall {
      margin: 0 2rem 2rem 2rem;
    }
    @include small {
      margin: 0 7rem 1rem 7rem;
    }
    @include medium {
      margin: 0 10rem 1rem 10rem;
    }
  }
  .promptContainer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 0px 0.875rem;
    padding: 0 14.5rem;
    margin-bottom: 2.5rem;
    @include xsmall {
      grid-template-rows: repeat(4, 1fr);
      grid-template-columns: 1fr;
      gap: 0.65rem 0px;
      padding: 0 2rem;
      margin-bottom: 0.3rem;
    }
    @include small {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      gap: 1rem;
      padding: 0 7rem;
      margin-bottom: 1.5rem;
    }
    @include medium {
      padding: 0 10rem;
    }
  }
  .tipContainer {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid #cfd8dc;
    padding: 0.875rem 0.55rem 0.875rem 0.75rem;
  }

  .textContainer {
    margin-bottom: 1rem;
    @include xsmall {
      margin-bottom: 0px;
    }
  }

  .title {
    font-weight: $semiBoldFont;
    color: #193448;
    margin-bottom: 5px;
  }
  .text {
    font-size: $font12;
    font-weight: $regularFont;
    color: #6a8294;
    word-wrap: break-word;
    hyphens: auto;
  }
}
