body {
  .primaryHeader {
    position: fixed;
    right: 0;
    top: 0;
    left: 0;
    background: $white;
    z-index: 999;
    width: 100%;
    // border-bottom: 1px solid #f2f2f2;
    .headerWrapper {
      padding: 16px;
      .headerRow {
        height: 32px;
        .logo-container {
          display: flex;
          gap: 15px;
          align-items: center;
          .logo {
            height: 30px;
            img {
              height: 100%;
            }
          }
        }
      }

      ul {
        padding: 0;
        li {
          padding: 0 16px;
          a {
            .activeImg {
              display: none;
            }
            &:hover {
              @include transitionForAll($time: 0.3s, $transProp: ease-in);
              .activeImg {
                display: inline-block;
              }
              .defaultImg {
                display: none;
              }
              .notificationCount {
                // color: $blue;
                // background-color: $blue4;
              }
            }
          }
          .profileWrapper {
            button {
              padding: 0;
              margin-top: -14px;
              .profilePic {
                height: 32px;
                width: 32px;
                object-fit: cover;
                border-radius: 50%;
              }
              .caret {
                margin-left: 8px;
              }
              &:hover {
                background: transparent;
              }
            }
            button[aria-controls='menu-list-grow'] .caret {
              transform: rotate(180deg);
            }
          }
          .profileDropdown {
            min-width: 160px;
            top: 12px !important;
            z-index: 9;
            > div {
              box-shadow: none;
            }
            ul {
              position: relative;
              background: $white;
              box-shadow: 0px 4px 9px 1px rgba(143, 146, 151, 0.19);
              border-radius: 5px;
              padding: 2px 0 0;
              li {
                padding: 8px 16px;
                // color: $gray60;
                display: block;
                .unit {
                  float: right;
                  // color: $gray40;
                }
                &.logOut {
                  // border-top: 1px solid $gray95;
                }
                &.orgItem {
                  background: $color_EEF1F5;
                  position: relative;
                  margin: 5px;
                  padding: 12px;
                  p {
                    position: relative;
                    img {
                      position: absolute;
                      left: 0;
                      top: -2px;
                      height: 28px;
                      width: 28px;
                      object-fit: contain;
                      border-radius: 2px;
                      background: $white;
                      margin-right: 5px;
                    }
                    span {
                      position: relative;
                      top: 1px;
                      margin-left: 34px;
                      text-transform: capitalize;
                    }
                  }
                }
              }
            }
          }
          &.active {
            a {
              .activeImg {
                display: inline-block;
              }
              .defaultImg {
                display: none;
              }
              span {
                display: inline-block;
              }
            }
          }
          &:last-child {
            padding-right: 0;
          }
          &.notification {
            position: relative;
            .notificationCount {
              position: absolute;
              right: 12px;
              top: -7px;
              font-size: 10px;
              font-weight: 600;
              border-radius: 50%;
              width: 16px;
              height: 16px;
              background: $blue;
              text-align: center;
              color: white;
              vertical-align: middle;
              line-height: 16px;
            }
            .hundredNotificationCount {
              width: 20px;
              height: 20px;
              line-height: 20px;
              font-size: 9px;
              right: 10px;
              top: -9px;
            }
          }
        }
      }
    }
  }
  .errorBoundary {
    min-height: 90vh;
    img {
      margin-bottom: 8px;
    }
    h1 {
      margin-bottom: 8px;
    }
    h2 {
      margin-bottom: 24px;
    }
  }
  .indexList {
    .listItem {
      margin-bottom: 4px;
      a {
        padding: 8px 4px 10px;
        display: block;
        position: relative;
        background: transparent;
        border-radius: 4px;
        @include transitionForAll($time: 0.4s, $transProp: ease-out);

        &:hover {
          .typeBlk {
            .dragWrapper {
              position: relative;
              cursor: move !important;
              &:after {
                content: url($imageBasePath + 'drag_to_do.svg');
                left: 4px;
                top: -12px;
                position: absolute;
              }
            }
          }
        }
        .typeBlk {
          position: absolute;
          left: 4px;
          top: 2px;
          .dragWrapper {
            width: 20px;
            display: inline-block;
          }
          .number {
            font-size: $font10;
            min-height: 18px;
            min-width: 18px;
            text-align: center;
            background: $white;
            border-radius: 50%;
            display: inline-block;
          }
          img {
            margin-left: 10px;
            height: 20px;
            position: relative;
            top: 6px;
          }
          .activeImg {
            display: none;
          }
        }
        .customStepIcon {
          position: absolute;
          top: 12px;
          right: 8px;
        }
        .newAlert {
          position: absolute;
          top: 8px;
          font-size: $font10;
          right: 2px;
          background: $orange;
          color: $white;
          padding: 1px 8px;
          border-radius: 25px;
        }
        .title {
          display: block;
          margin-left: 74px;
          position: relative;
          top: 1px;
          color: $gray2;
          &.marginRight {
            margin-right: 35px;
          }
        }
        &:hover {
          background: $white;
          .typeBlk {
            .number {
              background: $gray7;
            }
          }
        }
        &.active {
          background: $blue5;
          .typeBlk {
            .number {
              color: $blue;
            }
          }
          .title {
            color: $blue;
          }
          @include transitionForAll($time: 0.3s, $transProp: ease-in);
          .activeImg {
            display: inline-block;
          }
          .defaultImg {
            display: none;
          }
        }
        &.completed {
          .typeBlk {
            .number {
              background: $green;
              color: $white;
            }
          }
        }
        &.customStep {
          padding: 8px 24px 10px 4px;
        }
        &.disabledStep {
          padding: 8px 24px 10px 4px;
          &.disabledStepIcon {
            right: 12px;
          }
          .typeBlk,
          span {
            opacity: 0.5;
          }
        }
      }
    }
  }

  // .emptyHeaderdiv {
  //   height: 64px;
  // }

  main {
    min-height: calc(100vh);
    background: $background_grey;
    > div {
      padding: 64px 0px 0px 74px;
    }
  }

  .sideBar {
    overflow-y: auto;
    overflow-x: hidden;
    background-color: $white;
    position: fixed;
    top: 65px;
    left: 0;
    bottom: 0;
    height: calc(100% - 64px - 2.9rem);
    box-sizing: border-box;
    z-index: 998;
    width: 74px;
    .topRadiusPattern {
      position: absolute;
      top: 0;
      right: -6px;
    }
    // .BottomRadiusPattern{
    //   position: absolute;
    //   bottom: 0;
    //   right: -6px;
    // }
    ul {
      li {
        padding: 4px 8px;
        text-align: center;
        display: block;
        a {
          display: block;
          @include transitionForAll($time: 0.4s, $transProp: ease-out);
          .activeImg {
            display: none;
          }
          img {
            height: 36px;
            width: 36px;
            border-radius: 4px;
            vertical-align: middle;
            margin-bottom: 4px;
          }
          p {
            @include fontStyle(
              $fontFamily: $font_OpenSans,
              $size: $font8,
              $weight: $semiBoldFont,
              $color: $gray4,
              $lineHeight: $lineHeight14
            );
            text-transform: uppercase;
          }
          &:hover {
            @include transitionForAll($time: 0.3s, $transProp: ease-in);
            .activeImg {
              display: inline-block;
            }
            .defaultImg {
              display: none;
            }
            p {
              color: $blue;
            }
          }
        }
        &.active {
          a {
            .activeImg {
              display: inline-block;
            }
            .defaultImg {
              display: none;
            }
            p {
              color: $blue;
            }
          }
        }
      }
    }
  }
  .withRepViewBanner {
    top: 109px;
  }
  .sales-Img {
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: $white;
    width: 74px;
    height: 2.9rem;
    text-align: center;
    img {
      margin-top: 5%;
      height: 70%;
    }
  }

  .snackbar {
    .toastIcon {
      height: 24px;
      width: 24px;
      position: absolute;
      top: 10px;
      left: 10px;
    }
    .closeIcon {
      height: 8px;
      width: 8px;
      position: absolute;
      top: 19px;
      right: 12px;
      cursor: pointer;
    }
    > div {
      min-width: 380px;
      padding: 12px 16px;
      background: #d8dfe7;
      // border: 1px solid #778da9;
      box-sizing: border-box;
      border-radius: 4px;
      position: relative;
      padding-right: 40px;
      p {
        padding-left: 28px;
        @include fontStyle($font_OpenSans, $font14, $semiBoldFont, $lineHeight24);
      }
      &.info {
        background: $color_info_bg;
        border: 1px solid $color_info_text;
        p {
          color: $color_info_text;
        }
      }
      &.success {
        background: $color_success_bg;
        border: 1px solid $color_success_text;
        p {
          color: $color_success_text;
        }
      }
      &.error {
        background: $color_error_bg;
        border: 1px solid $color_error_text;
        p {
          color: $color_error_text;
        }
      }
      &.warning {
        background: $color_warning_bg;
        border: 1px solid $color_warning_text;
        p {
          color: $color_warning_text;
        }
      }
      &.disabled {
        p {
          color: $color_disabled_text;
        }
      }
    }
  }
  .dateRangeFilterWrapper {
    .dateValues {
      border: 1px solid $gray7;
      padding: 8px 24px 8px 16px;
      border-radius: 4px;
      min-width: 160px;
      box-sizing: border-box;
      background: $white;
      cursor: pointer;
      position: relative;
      p {
        text-align: left;
        padding-right: 12px;
        // color: $gray2;
        img {
          position: absolute;
          right: 13px;
          top: 15px;
          width: 12px;
          &.closeIcon {
            top: 14px;
          }
        }
      }
    }
  }
  .dateRangeSelectPopup {
    .dateRangeSelectWrapper {
      .dateRangePicker {
        // margin-bottom: 24px;
      }
      .footer {
        padding: 16px;
        text-align: right;
        ul {
          li {
            margin-right: 16px;
            button {
              // padding: 8px 24px;
            }
            &:last-child {
              margin: 0;
            }
          }
        }
      }
    }
  }
  // .snackbar {
  //   > div {
  //     max-width: 488px;
  //     padding: 4px 16px;
  //     background: #d8dfe7;
  //     box-shadow: none;
  //     // box-shadow: 0px 2px 8px 5px rgba(194, 199, 206, 0.53);
  //     box-sizing: border-box;
  //     border-radius: 25px;
  //     position: relative;
  //     text-align: center;
  //     p {
  //       @include fontStyle(
  //         $fontFamily: $font_OpenSans,
  //         $size: $font14,
  //         $weight: $semiBoldFont,
  //         $color: $white,
  //         $lineHeight: $lineHeight24
  //       );
  //       z-index: 8888888;
  //     }
  //     &.info {
  //       background: $color_info_bg;
  //     }
  //     &.success {
  //       background: $color_success_bg;
  //     }
  //     &.error {
  //       background: $color_error_bg;
  //     }
  //     &.warning {
  //       background: $color_warning_bg;
  //     }
  //     &.disabled {
  //       background: $gray3;
  //     }
  //   }
  // }

  .searchWrapper {
    width: 100%;
    margin-bottom: 32px;
    background: $background_grey;
    form {
      .search {
        position: relative;
        border-radius: 4px;
        width: 100%;
        margin: 0 auto;
        border: 1px solid $gray7;
        input {
          border: none;
          background: $background_grey;
          height: 32px;
          &:focus {
            box-shadow: none;
          }
        }
        // margin: 0 auto;
        .searchIcon {
          position: absolute;
          right: 0;
          top: -4px;
          cursor: pointer;
          z-index: 99;
          // background: $black;
          height: 32px;
          width: 32px;
          box-sizing: border-box;
          img {
            height: 20px;
            padding: 10px 5px;
          }
        }
        .searchArea {
          width: 100%;
          padding-right: 50px;
        }
      }
    }
  }

  .noResultFound {
    // min-height: 70vh;
    min-height: calc(100vh - 170px);
    img {
      // height: 150px;
      margin-bottom: 32px;
      &.linkimg {
        margin-bottom: 0;
        margin-right: 8px;
        position: relative;
        top: 3px;
      }
    }
    h5 {
      // color: $gray2;
      font-weight: $regularFont;
      margin-bottom: 24px;
      span {
        font-weight: $semiBoldFont;
      }
    }
    h6 {
    }
  }

  .logOutDialog {
    z-index: 99999 !important;
    .MuiPaper-root {
      min-width: 400px;
      position: relative;
      padding: 15px 0;
    }
    svg {
      position: absolute;
      cursor: pointer;
      top: 10px;
      right: 10px;
      font-size: 22px;
    }
    h3 {
      padding-top: 0;
    }
    ul {
      text-align: center;
      padding: 8px 0;
      li {
        padding: 0;
        margin-right: 15px;
        button {
          padding: 10px 32px;
          &.secondaryBtn {
            background: $white;
            padding: 9px 32px;
            // color: $color_black;
            // border: 1px solid #E2E2E2;
          }
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .dialog {
    .MuiDialogTitle-root {
      background-color: $background_grey;
      h2 {
        @include fontStyle(
          $fontFamily: $font_OpenSans,
          $size: $font18,
          $weight: $semiBoldFont,
          $color: $gray1,
          $lineHeight: $lineHeight27
        );
        position: relative;
        .modalCloseIcon {
          position: absolute;
          right: 0;
          top: 5px;
          cursor: pointer;
        }
      }
    }
    .MuiDialogContent-root {
      padding: 0;
    }
    .MuiDialogActions-root {
      padding: 16px 24px;
      border-top: 1px solid $gray7;
    }
  }

  .tabList {
    padding: 0;
    margin-left: -16px;
    li {
      padding: 8px 16px;
      cursor: pointer;
      color: $gray4;
      &.active {
        color: $gray1;
        position: relative;
        &::before {
          content: '';
          position: absolute;
          height: 2px;
          width: calc(100% - 32px);
          background-color: $blue;
          left: 50%;
          bottom: 0;
          transform: translateX(-50%);
          -webkit-transform: translateX(-50%);
          -moz-transform: -webkit-translateX(-50%);
          -ms-transform: -webkit-translateX(-50%);
          -o-transform: -webkit-translateX(-50%);
        }
      }
    }
  }

  .headerSection {
    border-bottom: 1px solid $color_DBEAF4;
    .pageTitleBlock {
      h1 {
        display: inline-block;
        padding-bottom: 12px;
        margin-right: 56px;
        font-size: 18px;
        font-weight: $semiBoldFont;
        img {
          margin-right: 8px;
        }
      }
      .tabList {
        display: inline-block;
        li {
          &::before {
            bottom: -3px;
          }
        }
      }
    }
    .dashboardProgressWrapper {
      padding-bottom: 8px;
      .progressBlk {
      }
      .stepsBlk {
        h2 {
          span {
            vertical-align: middle;
          }
          .supportText {
            margin-left: 5px;
          }
        }
      }
    }
  }
  .headerSectionRoleplay {
    .pageTitleBlock {
      h1 {
        display: inline-block;
        padding-bottom: 12px;
        margin-right: 56px;
        font-size: 18px;
        font-weight: $semiBoldFont;
        img {
          margin-right: 8px;
        }
      }
      .tabList {
        display: inline-block;
        li {
          &::before {
            bottom: -3px;
          }
        }
      }
    }
    .dashboardProgressWrapper {
      padding-bottom: 8px;
      .progressBlk {
      }
      .stepsBlk {
        h2 {
          span {
            vertical-align: middle;
          }
          .supportText {
            margin-left: 5px;
          }
        }
      }
    }
  }

  .filterSection {
    padding: 12px 0;
    .filterBlocker {
      justify-content: flex-end;
      .filterList {
        background-color: $white;
        border: 1px solid $gray7;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 0;
        display: inline-block;
        li {
          padding: 8px 24px;
          cursor: pointer;
          &.active {
            border: 1px solid $blue;
            border-radius: 4px;
            color: $blue;
          }
        }
      }
      .downloadBtn {
        margin-left: 24px;
        background: $white;
        border: 1px solid $gray7;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 8px 24px;
        font-weight: $semiBoldFont;
        color: $gray2;
        img {
          position: relative;
          top: 4px;
          right: 8px;
        }
        &.disabledBtn {
          background: $gray6;
          cursor: not-allowed;
          pointer-events: none;
        }
        &:hover,
        &:focus,
        &:active {
          border: 1px solid $blue;
          background: $background_lightblue;
          @include transitionForAll($time: 0.3s, $transProp: ease-in);
        }
      }
    }
  }

  .userDetail {
    position: relative;
    padding-left: 48px;
    .imageBlk {
      position: absolute;
      left: 0;
      top: 0;
      height: 34px;
      width: 34px;
      // font-size: 24px;
      // text-transform: uppercase;
      img {
        height: 34px;
        width: 34px;
      }
    }
    h5 {
      // margin-bottom: 4px;
      width: 100%;
    }
  }

  .status {
    border-radius: 2px;
    padding: 2px 6px;
    &.status1 {
      background: rgba(45, 195, 141, 0.1);
      color: $green;
    }
    &.status2 {
      background: rgba(235, 155, 66, 0.1);
      color: $orange;
    }
    &.status3 {
      color: $color_E1AE2A;
      background: rgba(246, 203, 92, 0.1);
    }
  }

  .engagementList {
    padding: 0;
    li {
      padding: 0 10px;
      cursor: pointer;
      img {
        vertical-align: middle;
        margin-right: 4px;
      }
      span {
        position: relative;
        top: 1px;
      }
      &:last-child {
        padding-right: 0;
      }
    }
  }

  .tagList {
    padding: 0;
    li {
      padding: 0 8px;
      span {
        background: $gray7;
        border-radius: 14px;
        padding: 4px 8px;
        display: inline-block;
        img {
          margin-left: 8px;
        }
      }
      &:first-child {
        padding-left: 0;
      }
    }
  }

  .confirmationDialog {
    .MuiDialog-paper {
      max-width: 500px;
      width: 482px;
    }
    .dialogBody {
      background-color: $white;
      padding: 16px 24px 24px;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 150px;
        width: 100%;
        background-color: rgba(242, 250, 255, 0.5);
      }
      .modalCloseIcon {
        position: absolute;
        right: 30px;
        top: 30px;
        z-index: 1;
        cursor: pointer;
      }
      h4 {
        margin: 8px 0 12px;
        font-size: 20px;
        font-weight: normal;
      }
      ul {
        margin-top: 32px;
        li {
          margin-right: 16px;
          button {
            padding: 16px 40px;
            &.secondaryBtn {
              padding: 15px 40px;
            }
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
      .confirmationFig {
        position: relative;
        margin-bottom: 16px;
      }
    }
  }
  .moreMenu {
    .MuiPaper-root {
      box-shadow: 0px 4px 9px rgba(170, 170, 170, 0.25);
      border-radius: 4px;
    }
    ul {
      li {
        border-bottom: 0.5px solid #e7f0f5;
        img {
          width: 14px;
          margin-right: 12px;
        }
        span {
          display: inline-block;
          font-weight: $semiBoldFont;
        }
        &.deleteAction {
          span {
            color: $red;
          }
        }
        &:last-child {
          border-bottom: none;
        }
      }
    }
    &.moreMenuHoirzontal {
      .MuiPaper-root {
        box-shadow: 0px 2px 8px 5px rgba(194, 199, 206, 0.53);
      }
    }
  }

  .overviewProgressSection {
    margin-bottom: 24px;
    .progressChart {
      background: $white;
      border-radius: 4px;
      padding: 16px;
      width: 100%;
      h3 {
        padding-bottom: 10px;
      }
    }
    .completionTime {
      background: $white;
      border-radius: 4px;
      padding: 24px 16px;
      position: relative;
      padding-left: 92px;
      margin-bottom: 24px;
      img {
        position: absolute;
        left: 16px;
        top: 24px;
      }
    }
    .overallRatings {
      padding: 16px 24px;
      background: $white;
      border-radius: 4px;
      min-height: 180px;
      box-sizing: border-box;
      h3 {
        padding-bottom: 16px;
      }
      h6 {
        margin-bottom: 4px;
      }
      .ratingList {
        ul {
          padding-left: 30px;
          li {
            margin-bottom: 8px;
            height: 14px;
            position: relative;
            span {
              position: absolute;
              left: -28px;
              top: -4px;
            }
            width: 0%;
            &.five {
              // width: 30%;
              background-color: $green;
            }
            &.four {
              // width: 40%;
              background-color: $color_AED888;
            }
            &.three {
              // width: 15%;
              background-color: $yellow;
            }
            &.two {
              // width: 10%;
              background-color: $color_FFB235;
            }
            &.one {
              // width: 5%;
              background-color: $orange;
            }
          }
        }
      }
    }
  }

  .dashboardListing {
    .teamProgressWrapper {
      background-color: $white;
      padding: 24px 16px;
      border-radius: 4px;
      .headingRow {
        .filtersList {
          text-align: right;
          li {
            position: relative;
          }
          input {
            width: 294px;
            // margin-right: 20px;
            padding-right: 24px;
          }
          .cancelBtn {
            position: absolute;
            right: 7px;
            width: 12px;
            cursor: pointer;
            top: 9px;
          }
          button {
            background: $white;
            border: 1px solid $gray7;
            border-radius: 4px;
            padding: 5px 16px;
            color: $gray2;
            position: relative;
            bottom: 2px;
            svg {
              margin-left: 10px;
            }
            @include transitionForAll($time: 0.3s, $transProp: ease-out);
            &:hover {
              border-color: $blue;
              color: $blue;
              @include transitionForAll($time: 0.3s, $transProp: ease-in);
              svg {
                path {
                  fill: $blue;
                  @include transitionForAll($time: 0.3s, $transProp: ease-in);
                }
              }
            }
          }
        }
      }
      .teamProgressDetail {
        .titleRow {
          border-bottom: 1px solid $gray7;
        }
        .contentBlock {
          padding: 4px 0 0;
          margin-top: 16px;
          // max-height: 190px;
          // overflow: auto;
          .noResultFound {
            min-height: 250px;
            img {
              margin-bottom: 20px;
            }
          }
          .item {
            padding: 12px 0;
            .copyText {
              position: relative;
              top: 4px;
              cursor: pointer;
              margin-left: 4px;
            }
            .comments {
              display: inline-block;
              position: relative;
              left: 12px;
              bottom: 2px;
              span {
                position: relative;
                left: 4px;
                bottom: 4px;
              }
            }
            .progressBar {
              progress {
                max-width: 70px;
                &::-webkit-progress-bar {
                  background-color: $gray7;
                }
              }
              .usersCompleted {
                width: 275px;
                max-width: 275px !important;
              }
            }

            .usersVoteList {
              li {
                padding: 0 16px;
                &:first-child {
                  padding-left: 0;
                }
              }
            }
          }
        }
      }
    }
  }

  .circularProgressbar {
    width: 32px;
    height: 32px;
  }

  .customTooltipContent {
    min-width: 150px;
    ul {
      li {
        display: list-item;
        list-style-position: inside;
        list-style-type: disc;
        padding: 4px 0;
      }
    }
  }

  .featuresSlider {
    .slick-dots {
      bottom: -100px;
      li {
        width: 40px;
        button {
          height: 4px;
          width: 40px;
          display: inline-block;
          &:before {
            content: '';
            background: $gray6;
            height: 4px;
            width: 40px;
            display: inline-block;
          }
        }
        &.slick-active {
          button {
            &:before {
              background: $blue;
            }
          }
        }
      }
    }
  }
  .modulesSlider {
  }
  .customProgressChartWrapper {
    .chartLegend {
      .overall-info {
        margin-bottom: 24px;
        h6 {
          color: $gray2;
        }
        p {
          font-size: 15px;
          color: $gray3;
          line-height: 22px;
        }
      }
      ul {
        margin-left: 24px;
        li {
          margin-bottom: 24px;
          &:last-child {
            margin-bottom: 0;
          }
          position: relative;
          strong {
            margin-left: 8px;
          }
          &::before {
            content: '';
            position: absolute;
            width: 6px;
            height: 6px;
            background: $white;
            border-radius: 50%;
            left: -21px;
            top: 8px;
            z-index: 99;
          }
          &.completed {
            &::after {
              content: '';
              position: absolute;
              width: 12px;
              height: 12px;
              background: $green;
              border-radius: 50%;
              left: -24px;
              top: 5px;
            }
          }
          &.inProgress {
            &::after {
              content: '';
              position: absolute;
              width: 12px;
              height: 12px;
              background: $orange;
              border-radius: 50%;
              left: -24px;
              top: 5px;
            }
          }
          &.yetToStart {
            &::after {
              content: '';
              position: absolute;
              width: 12px;
              height: 12px;
              background: $yellow;
              border-radius: 50%;
              left: -24px;
              top: 5px;
            }
          }
        }
      }
    }
  }

  .customArcProgressWrapper {
    > div {
      margin: 0 auto;
    }
  }

  .customTargetValue {
    p {
      span {
        color: $gray3;
      }
      img,
      span {
        vertical-align: middle;
      }
      img {
        margin-right: 4px;
      }
    }
  }

  .customDeltaValue {
    margin-right: 8px;
    display: inline-block;
    position: relative;
    img {
      position: absolute;
      top: -1px;
      left: 48%;
      transform: translateY(-50%);
    }
    &.rise {
      span {
        color: $green;
      }
    }
    &.fall {
      span {
        color: $red;
      }
    }
  }

  .dzu-dropzone {
    border: none;
    overflow: auto;
    min-height: 450px;
  }

  .filePreviewComponent {
    .fileWrapper {
      border: 1px solid #e7f0f5;
      border-radius: 4px;
      margin-bottom: 16px;
      min-width: 300px;
      .contentWrapper {
        padding: 8px 16px 24px;
        h6 {
          margin-bottom: 16px;
          color: $gray1;
        }
        p {
          justify-content: space-between;
          color: $gray4;
          margin-bottom: 8px;
        }
        .MuiLinearProgress-colorPrimary {
          background-color: $gray7;
          .MuiLinearProgress-barColorPrimary {
            background-color: $green;
          }
          &.secondary {
            .MuiLinearProgress-barColorPrimary {
              background-color: $color_error_bg;
            }
          }
        }
        .failed {
          // color: $color_error_bg;
          color: $red;
        }
        .uploaded {
          img {
            margin-right: 5px;
            position: relative;
            bottom: 1px;
          }
        }
      }
      .imagePreview {
        height: 200px;
        width: 100%;
        object-fit: cover;
      }
    }
    .buttonList {
      li {
        margin: 0 16px;
      }
      .removeBtn {
        margin: 0 auto;
        img {
          margin-right: 8px;
        }
        span {
          color: $gray4;
        }
      }
    }
  }

  .inviteUserDialog {
    .MuiPaper-root {
      padding: 48px 30px 24px 20px;
      position: relative;
      min-width: 650px;
      box-sizing: border-box;
    }
    .dialogClose {
      position: absolute;
      top: 28px;
      right: 18px;
      z-index: 99;
      color: $gray3;
      cursor: pointer;
    }
    .featuredImg {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 9;
    }
    .dialogTitle {
      padding: 0 28px;
      margin-bottom: 48px;
      .title {
        margin-bottom: 8px;
        display: block;
      }
      .description {
        color: $gray3;
        font-weight: $regularFont;
        display: block;
      }
    }
    .dialogContent {
      padding: 0 28px;
      .dialogContentWrapper {
        max-height: 350px;
        // overflow-y: auto;
        .emailWrapper {
          .listItem {
            margin-bottom: 16px;
            .emailRow {
              border: 1px solid $gray7;
              position: relative;
              .inputGroup {
                margin-bottom: 0;
                border-right: 1px solid $gray7;
                input {
                  background: $white;
                  border: none;
                }
              }
              .rightBlk {
                // text-align: right;
                padding: 10px 16px;
                &.disabled {
                  background: $gray6;
                  cursor: not-allowed;
                  pointer-events: none;
                  position: relative;
                  height: 46px;
                }
                .selectRole {
                  position: relative;
                  a {
                    display: block;
                    .active {
                      color: $gray2;
                    }
                  }
                  span {
                    color: $gray5;
                  }
                  img {
                    position: absolute;
                    right: 0px;
                    top: 9px;
                    margin-left: 8px;
                    // float: right;
                  }
                }
              }
              .deleteInvite {
                position: absolute;
                right: -24px;
                top: 16px;
              }
            }
            .infoRow {
              margin-bottom: 0px;
              margin-top: 4px;
              ul {
                padding: 2px 0;
                li {
                  padding: 0 6px;
                  .checkboxGroup {
                    margin-bottom: 0;
                    label {
                      margin: 0;
                    }
                    .MuiSvgIcon-root {
                      height: 16px;
                      width: 16px;
                    }
                  }
                  &:first-child {
                    padding-left: 0;
                    padding-right: 32px;
                    img {
                      margin-left: 8px;
                      position: relative;
                      top: 3px;
                      // cursor: pointer;
                    }
                  }
                }
              }
              .rightBlk {
                text-align: right;
                a {
                  img {
                    position: relative;
                    top: 2px;
                    margin-right: 8px;
                  }
                  span {
                    font-size: $font12;
                  }
                }
              }
            }
          }
        }
      }
    }
    .dialogActions {
      padding: 8px 28px;
    }
  }

  .sampleVideoDialog {
    .MuiDialog-container {
      .MuiPaper-root {
        border: none;
        box-shadow: none;
        overflow-y: initial;
        .MuiDialogContent-root {
          background: #e5e5e5;
          // background: $color_white;
          border: none;
          padding: 0;
          video {
            min-width: 960px;
            // border-radius: 4px;
          }
        }
        .MuiIconButton-root {
          .MuiSvgIcon-root {
            height: 34px;
            width: 34px;
            color: $white;
          }
        }
      }
    }
  }

  .green {
    color: $green;
  }

  .customPagination {
    ul {
      justify-content: center;
      li {
        button {
          background: $white;
          border: 1px solid $gray6;
          color: $gray4;
          font-weight: $semiBoldFont;
          &.Mui-selected {
            background: $white;
            color: $blue;
          }
        }
      }
    }
  }

  .downloadBtn {
    // margin-left: 24px;
    background: $white;
    border: 1px solid $gray7;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 8px 24px;
    font-weight: $semiBoldFont;
    color: $gray2;
    @include transitionForAll($time: 0.4s, $transProp: ease-out);
    img {
      position: relative;
      top: 4px;
      right: 8px;
    }
    &.disabledBtn {
      background: $gray6;
      cursor: not-allowed;
      pointer-events: none;
    }
    &:hover,
    &:focus,
    &:active {
      border: 1px solid $blue;
      background: $background_lightblue;
      @include transitionForAll($time: 0.3s, $transProp: ease-in);
    }
  }

  .pageNotFound {
    background: $background_grey;
    .noResultFound {
      min-height: 100vh;
      img {
        margin-bottom: 24px;
      }
      h6 {
        margin-bottom: 24px;
      }
    }
  }
  .moreMenutraining {
    z-index: 1300 !important;
  }
}

.grow-wrap {
  /* easy way to plop the elements on top of each other and have them both sized based on the tallest one's height */
  display: grid;
}
.grow-wrap::after {
  /* Note the weird space! Needed to preventy jumpy behavior */
  content: attr(data-replicated-value) ' ';

  /* This is how textarea text behaves */
  white-space: pre-wrap;

  /* Hidden from view, clicks, and screen readers */
  visibility: hidden;
}
.grow-wrap > textarea {
  /* You could leave this, but after a user resizes, then it ruins the auto sizing */
  resize: none;

  /* Firefox shows scrollbar on growth, you can hide like this. */
  overflow: hidden;
}
.grow-wrap > textarea,
.grow-wrap::after {
  /* Identical styling required!! */
  border: 1px solid black;
  padding: 0.5rem;
  font: inherit;

  /* Place on top of each other */
  grid-area: 1 / 1 / 2 / 2;
}

.note-block {
  background-color: $yellow;
  padding: 4px 10px;
  position: relative;
  img {
    height: 10px;
    width: 10px;
    margin-right: 2px;
  }
  p {
    font-size: 12px;
    line-height: 20px;
    padding-right: 16px;
  }
  .closeNote {
    position: absolute;
    right: 8px;
    top: 4px;
  }
  &.introduction-note-block {
    margin: -24px -24px 24px;
  }
}
