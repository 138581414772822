.listPayments {
  font-size: 14px;
  color: #0494fd;
  height: 2.4rem;
  border-radius: 6px;
  border: 1px solid #0494fd;
  padding: 0.5rem 1rem;
  &:hover {
    color: #0487e4;
  }
}
.listDialog {
  width: 40rem;
}
.listHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 3rem;
  background: #f3f5f8;
  .cancelCloseIcon {
    cursor: pointer;
    margin-right: 2.8rem;
  }
  .titleText {
    font-size: 1rem;
    font-weight: 600;
    line-height: 25px;
    margin-left: 2.8rem;
  }
}

.listBody {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  .payments {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 90%;
    .payment {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 1rem;
      flex-wrap: wrap;
      gap: 1rem;
      .paymentInfo {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: flex-end;
        .paymentInfoFirst {
          img {
            margin-right: 0.5rem;
          }
        }
        .paymentInfoSecond {
          color: #455a64;
        }
      }
      .paymentActions {
        margin-top: 1rem;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
      }
    }
  }
  .bodyButtons {
    width: 90%;
    height: 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 1rem;
    gap: 1rem;
    margin-top: -1rem;
    .close {
      color: #0494fd;
      font-size: 14px;
      font-weight: 600;
      height: 2.2rem;
      font-family: 'Open Sans', sans-serif;
      padding: 5px 1rem;
      border-radius: 6px;
      &:hover {
        color: #0586e2;
      }
    }
  }
}

.paymentNoDefault {
  margin-top: 1.5rem;
  border-top: 1px solid rgb(212, 212, 212);
  border-bottom: 1px solid rgb(212, 212, 212);
  min-height: 14rem;
  overflow-y: auto;
}

.scrollbar {
  overflow-y: auto;
  max-height: 13rem;
}
