@import 'assets/styles/_variable.scss';

.couponContainer {
  .error {
    color: $color_error_text;
    font-size: 0.75rem;
  }

  .couponInput {
    display: flex;
    align-items: center;

    & :global(.inputGroup) {
      margin-bottom: 0.25rem;
      flex: 1;
    }
    .button {
      margin: 0 1.5rem;
      color: $blue;
      cursor: pointer;

      &.disabled {
        color: $gray3;
        pointer-events: none;
      }
    }
  }
}

.success {
  margin-bottom: 1rem;
  .coupon {
    color: $blue;
  }
}

.resetBtn {
  width: 100%;
  text-align: center;
  span {
    cursor: pointer;
    color: #415766b3;
    font-size: 0.85rem;
    font-weight: 600;
    text-decoration: underline;
  }
}
