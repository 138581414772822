.card {
  padding: 1rem;
  border-radius: 8px !important;
  border: 0.2px solid #c1c3c3;
  .cardHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 1rem;

    .headerText {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      min-height: 3.5rem;
      .first {
        font-size: 30px;
        line-height: 2rem;
      }
      .second {
        font-size: 15px;
        font-weight: 400;
        margin: 1rem 0;
      }
    }

    .headerInfo {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      font-size: 12px;
      font-weight: 500;
    }
  }

  .cardActionButtons {
    margin-top: 1rem;
    padding: 1rem;
    padding-bottom: 0.5rem;
    border-top: 1px solid rgb(212, 212, 212);
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
  }
}
