.container {
  bottom: 0px;
  right: 10px;
  border: 0;
  z-index: 1;
  position: fixed;
  width: 25vw;
  min-width: 350px;
  height: 75vh;
  background-color: #ffffff;
  padding: 0.5rem;
  border-radius: 5px 5px;
}

.smartMentorWraper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  .smartMentorContent {
    width: 95%;
    height: 90%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
}
