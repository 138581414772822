@import 'variable';
body {
  .homePage {
    .homeIntroductionSection {
      padding: 16px 24px;
      background: url($imageBasePath + 'home_intoduction_bg.svg')
        no-repeat;
      background-size: cover;
      background-position: center;
      min-height: 168px;
      position: relative;
      justify-content: center;
      box-sizing: border-box;
      .userInfoWrapper {
        .contentBlk {
          h3 {
            margin-bottom: 6px;
          }
          p {
            color: $gray2;
          }
        }
      }
      &.userIntroduction {
        background: url($imageBasePath + 'home_intoduction_bg_user.svg')
          no-repeat;
        background-size: cover;
        background-position: center;
        .userInfoWrapper {
          position: relative;
          text-align: left;
          .userImgBlk {
            position: absolute;
            left: 0;
            top: -7px;
            img {
              border: 4px solid $white;
              border-radius: 50%;
              width: 75px;
              height: 75px;
            }
            .userImg {
              border: 4px solid $white;
              width: 75px;
              height: 75px;
              font-size: $font32;
            }
          }
          .contentBlk {
            margin-left: 100px;
          }
        }
      }
    }
    .homePageSection {
      padding: 24px;
      & :global(.customProgressChartWrapper .chartLegend ul li) {
        margin-bottom: 12px;
      }
      &.salesRep {
        .userIntroduction {
          margin: -24px;
          margin-bottom: 24px;
        }
      }
      .pageTitle {
        margin-bottom: 24px;
        h3 {
          img,
          span {
            vertical-align: middle;
          }
          img {
            margin-right: 12px;
          }
        }
      }
      .homePageContent {
        .trackingInfoItem {
          .title {
            margin-bottom: 11px;
            img,
            span {
              vertical-align: middle;
            }
            img {
              margin-right: 8px;
            }
          }
          .trackingValueWrapper {
            justify-content: center;
            h3 {
              margin-right: 10px;
            }
          }
        }
        .overallDetailWrapper {
          border-radius: 4px;
          background: $white;
          padding: 16px;
          padding-right: 0;
          flex-wrap: nowrap;
          height: 100%;
          box-sizing: border-box;
          img {
            margin-right: 16px;
          }
          .contentBlk {
            h3 {
              font-size: 22px;
            }
          }
        }
        .moduleSetup {
          padding: 24px;
          border-radius: 4px;
          background: $white;
          height: 100%;
          box-sizing: border-box;
          .progressInfo {
            justify-content: flex-end;
            .progressValue {
              margin-right: 16px;
              p {
                text-align: right;
                color: $gray2;
                span {
                  color: $gray3;
                  font-weight: $regularFont;
                }
              }
            }
            .progressBarBlk {
              width: calc(100% - 130px);
              .progressBar {
                width: 100%;
              }
              & :global(.progressBar .progressValue) {
                display: none;
              }
            }
          }
          img {
            width: 65px;
            margin-bottom: 16px;
          }
          h3 {
            margin-bottom: 5px;
            text-transform: capitalize;
          }
          .description {
            margin-bottom: 36px;
          }
          .btnLink {
            margin-bottom: 0;
            a {
              box-sizing: border-box;
            }
          }
        }
        .overviewTitleBlk {
          border-radius: 4px;
          padding: 3px 2px;
          position: relative;
          background: rgba(243, 245, 248, 0.6);
          margin: -22px;
          margin-bottom: 0px;
          h3 {
            img,
            span {
              vertical-align: middle;
            }
            .moduleIcon {
              margin-right: 6px;
            }
          }
          .gotoModuleLinkContainer {
            .gotoModuleLink {
              color: $blue;
              margin-left: 14px;
              span {
                margin-right: 4px;
              }
            }
            float: right;
            position: absolute;
            top: 11px;
            right: 12px;
          }
        }
        .moduleOverview {
          padding: 24px;
          background: $white;
          height: 100%;
          box-sizing: border-box;
          border-radius: 4px;
          .overviewTitleBlk {
          }
          .infoWrapper {
            h3 {
              margin-bottom: 8px;
            }
          }
          .defaultOverviewWrapper {
            height: 100%;
            padding: 30px 0;
          }
          .recruitOverviewWrapper {
          }
          .onboardingOverviewWrapper {
            .infoWrapper {
              margin-bottom: 24px;
              &.usersListInfo {
                margin: 0;
                .usersList {
                  margin-bottom: 8px;
                  li {
                    margin-left: -4px;
                    &:first-child {
                      margin-left: 0;
                    }
                    .profilePic {
                      width: 26px;
                      height: 26px;
                      border-radius: 50%;
                      font-size: $font10;
                      color: $gray2;
                      background: $gray7;
                    }
                  }
                }
              }
            }
          }
          .trainingOverviewWrapper {
          }
          .trackOverviewWrapper {
            .trackingOverview {
              .title {
                margin-bottom: 11px;
                img,
                span {
                  vertical-align: middle;
                }
                img {
                  padding: 6px;
                  background: $blue6;
                  border-radius: 50%;
                  margin-right: 8px;
                }
              }
              .trackingValueWrapper {
                justify-content: center;
                h3 {
                  margin-right: 10px;
                }
              }
            }
          }
        }
        .moduleInitWrapper {
          padding: 8px 8px;
          border-radius: 4px;
          background: $white;
          height: 100%;
          // max-height: 260px;
          box-sizing: border-box;
          .featuredImg {
            width: 100%;
            // max-height: 244px;
            height: 100%;
            border-radius: 4px;
            object-fit: cover;
          }
          .contentWrapper {
            padding: 24px;
            .title {
              text-transform: capitalize;
              margin-bottom: 8px;
              img,
              span {
                vertical-align: middle;
              }
              img {
                margin-right: 0;
                margin-left: -8px;
              }
            }
            .description {
              margin-bottom: 24px;
            }
            .progressInfoWrapper {
              justify-content: space-between;
              .progressValue {
                margin-right: 8px;
                p {
                  text-align: left;
                  color: $gray2;
                  span {
                    font-weight: $regularFont;
                    color: $gray3;
                  }
                }
              }
              .progressBarBlk {
                width: calc(100% - 80px);
                margin-top: -3px;
                &.training {
                  width: calc(100% - 155px);
                }
                .progressBar {
                  width: 100%;
                }
                & :global(.progressBar .progressValue) {
                  display: none;
                }
              }
            }
            .completedAlert {
              display: flex;
              .message {
                margin-left: 6px;
                p {
                  color: $green;
                }
              }
            }
            .btnLink {
              padding-top: 24px;
              a {
                width: 100%;
                box-sizing: border-box;
              }
            }
          }
        }
        .homePageProgressSectioin {
          .userDetailsHeader {
            margin-bottom: 40px;
            .userInfoWrapper {
              position: relative;
              text-align: left;
              min-height: 75px;
              .userImgBlk {
                position: absolute;
                left: 0;
                top: 2px;
                img {
                  border: 4px solid $white;
                  border-radius: 50%;
                  width: 75px;
                  height: 75px;
                }
                .userImg {
                  border: 4px solid $white;
                  width: 75px;
                  height: 75px;
                  font-size: $font32;
                }
              }
              .contentBlk {
                margin-left: 100px;
                h3 {
                  margin-bottom: 8px;
                }
                p {
                  margin-bottom: 2px;
                  span {
                    color: $gray2;
                  }
                  &:last-child {
                    margin: 0;
                  }
                  img,
                  span {
                    vertical-align: middle;
                  }
                  img {
                    margin-right: 8px;
                    margin-top: 2px;
                  }
                }
              }
            }
            .goalAchievementWrapper {
              p {
                margin-bottom: 12px;
              }
            }
          }
          .progressContent {
            .progressContentWrapper {
              padding: 24px 16px;
              box-sizing: border-box;
              background: $white;
              height: 100%;
              border-radius: 4px;
              .overviewTitleBlk {
                // margin-bottom: 48px;
                margin: -22px -14px 0 -14px;
              }
              .defaultProgressContent {
                height: 100%;
                padding-top: 30px;
              }
              .trackingProgressContent {
                .noStateWrapper {
                  min-height: 100%;
                  padding: 0 32px;
                  img {
                    margin-bottom: 12px;
                  }
                  h6 {
                    margin-bottom: 24px;
                  }
                }
                .checkinAlertMessage {
                  border-radius: 4px;
                  background: $background_grey;
                  padding: 16px 20px 16px 12px;
                  // margin-bottom: 22px;
                  .textBlk {
                    display: flex;
                    align-items: center;
                  }
                  .btnBlk {
                    .customLink {
                      padding: 10px 16px;
                      width: 100%;
                      box-sizing: border-box;
                      img,
                      span {
                        vertical-align: middle;
                      }
                      img {
                        margin-right: 4px;
                      }
                    }
                  }
                }
                .organizationGoals {
                  height: 100%;
                  box-sizing: border-box;
                  background: rgba(4, 148, 253, 0.1);
                  padding: 0px 8px 8px;
                  .goalsStatusHeading {
                    color: $gray3;
                    font-size: $font14;
                    font-weight: $semiBoldFont;
                    padding: 20px 0px 4px;
                  }
                  .trackingInfoItem {
                    margin-bottom: 24px;
                    text-align: left;
                    .title {
                      margin-bottom: 8px;
                      img {
                        // background: $white;
                        width: 22px;
                      }
                    }
                  }
                }
                .salesGoalsStatus {
                  height: 100%;
                  box-sizing: border-box;
                  background: rgba(45, 195, 141, 0.1);
                  .goalsStatusHeading {
                    color: $gray3;
                    font-size: $font14;
                    font-weight: $semiBoldFont;
                    padding: 20px 10px 4px;
                  }
                  .goalsListing {
                    padding: 16px 10px;
                    li {
                      margin-bottom: 16px;
                      &:last-child {
                        margin: 0;
                      }
                      .goalItem {
                        .title {
                          img,
                          span {
                            vertical-align: middle;
                          }
                          span {
                            color: $gray2;
                          }
                          img {
                            width: 22px;
                            margin-right: 8px;
                          }
                        }
                      }
                      .achievement {
                        h3 {
                          margin-right: 16px;
                          // width: 64px;
                        }
                        // & :global(.customTargetValue){
                        //   // padding-left: 100px;
                        // }
                      }
                    }
                  }
                }
              }
              .trainingProgressContent {
                :global(.customProgressChartWrapper .chartLegend) {
                  margin-top: 32px;
                  max-width: 100%;
                }
                .infoWrapper {
                  margin-bottom: 32px;
                  &:last-child {
                    margin: 0;
                  }
                  h3 {
                    margin-bottom: 16px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

//mobile portrait view //
@media (min-width: 0px) and (max-width: 599px) {
  body {
    .homePage {
    }
  }
}

@media (min-width: 600px) and (max-width: 959px) {
  body {
    .homePage {
    }
  }
}

@media (min-width: 960px) and (max-width: 1279px) {
  body {
  }
}

@media (min-width: 1280px) {
  body {
  }
}
