@import '/src/assets/styles/variable';
.banner {
  background: $blue4;
  height: 44px;
  display: flex;
  gap: 1rem;
  flex-wrap: nowrap;
  justify-content: end;
  align-items: center;
  padding: 0 1rem;
  overflow: hidden;
  
  p {
    color: $blue;
    overflow: hidden;
    margin-right: auto;
    text-wrap: nowrap;
    font-size: 0.75rem;
  }
  button {
    font-size: 0.75rem;
    padding: 0.25rem 0.5rem;
    border-radius: 6px;
    background: none;
    border: 1px solid $blue;
    color: $blue;
    cursor: pointer;
    text-wrap: nowrap;
  }
  img {
    cursor: pointer;
  }
}
.aux {
  height: 44px;
}
