.smartMentorHeader {
  width: 95%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f3f5f8;
  padding: 0.5rem;
  .headerTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .headerActions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .modalCloseIcon:hover {
      cursor: pointer;
    }
  }
}
