.reactionMenu {
  display: flex;
  justify-content: space-between;
  margin: 0 5px 0.6rem 5px;

  .comments {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }
  .reactions {
    display: flex;
  }

  .commentsCounter {
    cursor: pointer;
    display: flex;
    gap: 0.3rem;

    &:hover {
      .text {
        font-weight: 600;
      }
    }

    .number {
      color: #0494fd;
      font-weight: 600;
      margin-left: 0.5rem;
    }
  }
}

.reactions {
  display: flex;
}