@import '../../assets/styles/variable';
/*Recorder Button*/
.recording-button {
  border-radius: 8px;
  text-align: center;
  height: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  margin: 0 auto;
  margin-top: 1rem;
  border: 1px solid rgba($color: $blue, $alpha: 0.5);
  &:hover {
    background-color: #f9fcff;
  }
  .recording-text {
    img {
      margin-bottom: 32px;
    }
    h6 {
      margin-bottom: 8px;
    }
  }
}

.recording-button:hover {
  cursor: pointer;
}

/* Recorder Bar */
.recorder-bar {
  background-color: #edf5fa;
  border: 1px solid #d2d2d2;
  border-radius: 100px;
  height: 60px;

  position: absolute;
  pointer-events: all;
  left: 30%;
  top: 0.5rem;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;

  // Shows bar above modals
  z-index: 10000 !important;

  /* Shadow/5.0 */
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.2);

  width: 512px;
  padding: 0 0.8rem;
  transition: width 0.5s ease-in;

  &.minimized {
    width: 15rem;
    .recorder-btn-container:not(.prevent-hide),
    .recording-options-container {
      display: none;
    }
  }
}
