@import 'assets/styles/_variable.scss';

.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem;
  min-height: 200px;

  .roleplay {
    padding: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 0.75rem;
    border-radius: 0.25rem;
    border: 1px solid rgba(160, 183, 198, 0.2);

    width: calc(100% - 2rem);

    .title {
      font-size: 1rem;
      font-weight: 600;
    }

    .titleContainer,
    .dataContainer,
    .managerContainer {
      display: flex;
      flex-wrap: wrap;
      gap: 0.75rem;
    }
    .managerContainer {
      color: $gray2;
      .name {
        font-weight: 600;
      }
    }

    .dataContainer {
      flex-direction: column;
    }

    .buttonContainer {
      display: flex;
      align-items: center;
    }
  }
}
