.tooltip {
  background: #ffffff;
  border-radius: 0.25rem;
  padding: 0.5rem;
  box-shadow: 0px 4px 9px 1px rgba(143, 146, 151, 0.19);
}

.loadingContainer {
  display: grid;
  place-content: center;
  height: 300px;
}
.emptyContainer {
  width: 100%;
  max-width: 460px;
  margin: 0 auto;
  padding: 50px 2rem;
  text-align: center;
  h1 {
    font-size: 18px;
    font-weight: 600;
    color: #193448;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }
  p {
    font-size: 15px;
    font-weight: 400;
    color: #87a0b4;
  }
}
