#webcam {
  z-index: 1002;
  object-fit: cover;
  object-position: center;
  pointer-events: all;
}

.fullWebcam {
  right: 0rem;
  bottom: 0rem;
  border-radius: 0%;
  height: 100vh;
  width: 100vw;
  cursor: default;
}

.smallWebcam {
  border-radius: 50%;
  height: 120px;
  width: 120px;
  cursor: grab;
  position: absolute;
  right: 2rem;
  bottom: 1rem;
}
