.editButton {
  margin-right: 2rem;
  font-size: 14px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  color: #0494fd;
  &:hover {
    color: #0365ac;
  }
}
.CancelDialog {
  margin: 0;
  padding-bottom: 2rem;
}
.editHeader {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 1rem;
  .cancelCloseIcon {
    &:hover {
      cursor: pointer;
    }
  }
  .titleText {
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
  }
}

.editBody {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  .bodyForm {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 90%;
    margin-top: 1rem;

    & :global(.Mui-disabled::placeholder) {
      color: #000000 !important;
    }

    .bodyInput {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }
  .bodyButtons {
    width: 90%;
    height: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    .cancel {
      color: #0494fd;
      font-size: 14px;
      font-weight: 600;
      height: 2.2rem;
      font-family: 'Open Sans', sans-serif;
      padding: 5px 1rem;
      border-radius: 6px;
      &:hover {
        color: #0586e2;
      }
    }
  }
}
