//mobile portrait view //
@media (min-width: 0px) and (max-width: 599px) {
  body {
    br {
      display: none;
    }

    .emptyHeaderdiv {
      // height: 53px;
    }

    .snackbar {
      > div {
        padding: 8px 16px;
      }
    }
  }
}
